.entry-content {
	border-bottom: 0;
}
// .media-top-flex-box {
// 	display: flex;
// 	direction: rtl;
// 	.right-column {
// 		direction: ltr;
// 	}
// 	.left-column {
// 		direction: ltr;
// 	}
// }
// .cell.right-column {
// 	width: 30%;
// 	margin: 0;
// 	border-right: 1px solid #000;
// }
.media-top {
	width: 98%;
	margin: 0 auto;
	background-color: #d3d8d1;
	padding: 20px 10px;
	.media-top-item {
		display: block;
		position: relative;
		.thumb-img {
			width: 65%;
			margin-left: 35%;
			position: relative;
			background-color: #737373;
			img {
				width: 100%;
			}
		}
		&::before {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			height: 100vh;
			width: 50%;
			background: url(../img/media-power/nums_art_01.png?v=1) no-repeat top left;
			background-size: contain;
		}
		.title {
			display: block;
			width: 65%;
			margin: 0 0 0 35%;
			p {
				color: #000;
				font-size: 18px;
				line-height: 18px;
				margin: 1rem 0;
			}
		}
	}
	.media-top-item.item2 {
		&::before {
			background: url(../img/media-power/nums_art_02.png?v=1) no-repeat top right;
			background-size: contain;
		}
	}
	.media-top-item.item3 {
		&::before {
			background: url(../img/media-power/nums_art_03.png?v=1) no-repeat top right;
			background-size: contain;
		}
	}
	.media-top-item.item4 {
		&::before {
			background: url(../img/media-power/nums_art_04.png?v=1) no-repeat top right;
			background-size: contain;
		}
	}
	.media-top-item.item5 {
		&::before {
			background: url(../img/media-power/nums_art_05.png?v=1) no-repeat top right;
			background-size: contain;
		}
	}
	.media-top-item.item6 {
		&::before {
			background: url(../img/media-power/nums_art_06.png?v=1) no-repeat top right;
			background-size: contain;
		}
	}
}
.media-top-header {
	display: block;
	width: 100%;
	background-color: #818e7a;
	color: #fff;
	padding: 10px 10px;
	margin: 0 0 20px 0;
	h1 {
		font-size: 30px;
		font-weight: bold;
	}
	img {
		height: auto;
		width: 40px;
		margin-right: 10px;
	}
}
.entry-related {
	.font-bold {
		font-weight: normal;
	}
	.category-field {
		display: none;
	}
	.section-title {
		h4 {
			font-weight: bold;
		}
	}
}
.article-item.video-popup {
	.embedCode {
		height: 0;
		width: 0;
		visibility: invisible;
		opacity: 0;
	}
}
.videoPlayerModal {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 999;
	.modalBody {
		position: fixed;
		width: 90%;
		height: auto;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		.closeBtn {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			padding: 0;
			margin: 0;
			height: auto;
			background-color: #ccc;
			z-index: 999;
			text-align: center;
			color: #000;
			font-size: 30px;
			line-height: 30px;
		}
		.modalContent {
			width: 100%;
			height: auto;
		}
	}
	&::before {
		content: "";
		position: fixed;
		width: 100vw;
		height: 100vh;
		background-color: #000;
		opacity: 0.8;
	}
}
@media only screen and (max-width: 990px) {
	.cell.right-column {
		border-right: 0;
		width: 100%;
	}
	.videoPlayerModal {
		.modalBody {
			.closeBtn {
				font-size: 20px;
				line-height: 20px;
			}
		}
	}
}
