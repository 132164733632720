@charset "UTF-8";

$font-path: "../fonts";

// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700;900&display=swap');
@font-face {
  font-family: "Isento-Medium";
  src: url('#{$font-path}/Isento-Medium2.woff2') format('woff2'),
  url('#{$font-path}/Isento-Medium2.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Isento-Bold";
  src: url('#{$font-path}/Isento-Bold2.woff2') format('woff2'),
  url('#{$font-path}/Isento-Bold2.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Isento-Book";
  src: url('#{$font-path}/Isento-Book2.woff2') format('woff2'),
  url('#{$font-path}/Isento-Book2.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Isento-ExtraBold";
  src: url('#{$font-path}/Isento-ExtraBold2.woff?sss') format('woff'),
  url('#{$font-path}/Isento-ExtraBold2.woff?vvv') format('woff');
  font-weight: normal;
  font-style: normal;
}
