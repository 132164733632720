@import "vars";
@import "mixins";

.share-this-popover {
    max-width: $share-this-border-distance * 2;
    pointer-events: none;
    z-index: 1;
    @include share-this-filter(drop-shadow(0 1px 3px rgba(0, 0, 0, .35)));
    @include share-this-animation(share-this-intro $share-this-animation-length);

    &::before {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        width: 0;
        height: 0;
        margin: $share-this-vertical-offset + $share-this-arrow-size (-$share-this-arrow-size);
        border-width: $share-this-arrow-size $share-this-arrow-size 0;
        border-style: solid;
        border-color: $share-this-background transparent;
    }

    > ul {
        pointer-events: auto;
        list-style: none;
        padding: 0;
        margin: $share-this-vertical-offset - $share-this-arrow-size 0 0;
        white-space: nowrap;
        background: $share-this-background;
        color: $share-this-foreground;
        border-radius: $share-this-border-radius;
        position: absolute;
        left: 50%;
        @include share-this-transform(translate(-50%, -100%));

        > li {
            display: inline-block;
            width: $share-this-sharer-width;
            height: $share-this-sharer-height;
            line-height: $share-this-sharer-height;
            text-align: center;

            > a {
                display: inline-block;
                width: 100%;
                height: 100%;
                color: inherit;
                box-sizing: border-box;
                padding: $share-this-sharer-padding;

                &:hover, &:focus {
                    background: $share-this-highlight-link;
                }
            }
        }
    }

    @media (pointer: coarse) {
        font-size: 150%;
        @include share-this-on-bottom();
    }
    @media (max-width: $share-this-small-devices-max) {
        left: 0 !important;
        right: 0 !important;
        width: auto !important;
        max-width: none;
        @include share-this-on-bottom();

        > ul {
            left: 0;
            width: 100%;
            transform: none;
            border-radius: 0;
            text-align: center;
        }
    }
}

@-webkit-keyframes share-this-intro {
    0% {
        -webkit-transform: translateY(-3em);
        opacity: 0;
    }
    80% {
        -webkit-transform: translateY(.5em);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(0);
    }
}

@keyframes share-this-intro {
    0% {
        transform: translateY(-3em);
        opacity: 0;
    }
    80% {
        transform: translateY(.5em);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
    }
}
