.green-initiativeBg { background-color:#cbd8ce; }
.green-initiativeBg .section-title.style-v3 span { background-color:#cbd8ce; }

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 100%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}


.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.interactiveGallery { width:85%; margin:0 auto; padding-bottom:60px;}
.interactiveGallery .slick-slide img { width:100%; }
.interactiveGallery .slider-item-caption { position:absolute; bottom:0; width:100%; background-color:#000; opacity:0.9; color:#fff; z-index:1; }
.interactiveGallery .slider-counter { position:absolute; right:4px;; bottom:0; color:#fff; z-index:2; }

@media only screen and (max-width: 500px) {

.interactiveGallery { width:100%;}

}