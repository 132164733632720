
.container-911 {
    background: url(../img/9-11/bg_911.jpg) repeat;
}
.container-911 .page-title h1 span {
    background-color: transparent;
    background: url(../img/9-11/bg_911.jpg) repeat;
}
.container-911 .block-custom-bg, .container-911 .white-smoke-opacity-bg {
    background-color: transparent;
}
.container-911-interactive-main {
    width: 100%;
    background: url(../img/9-11/bg_911.jpg) repeat;
}
// .container-911-interactive {
//     max-width: 71.5rem;
//     padding: 0;
//     margin: 0 auto;
// }
.container-911-interactive .anim-911-img {
    width: 100%;
}
.container-911-interactive .anim-911-headline {
    display: block;
    width: 82%;
    padding: 0;
    margin: 0 auto;
}
.container-911-interactive h1 { 
    font-size: 55px;
    text-align: center;
    font-weight: 900;
    letter-spacing: -0.04em;
    margin-left: 10px;
}
.container-911-interactive h1.subtitle {
    color: #f60;
    font-size: 50px;
}
.container-911-interactive h1 i {
    font-style: normal;
    font-size: 65px;
}
.container-911-interactive h2 {
    font-family: 'Isento-ExtraBold';
    font-size: 30px;
    font-weight: 900;
    letter-spacing: -0.06em;
    margin-left: 10px;
}
.container-911-interactive .interactiveTimeline {
    width: 100%;
    margin-bottom: 30px;
}
.container-911-interactive .interactiveTimeline ul {
    list-style: none;
    text-align: center;
    margin: 0 auto;
    padding: 0;
    width: 85%;
}
@media only screen and (max-width: 500px) {

  .container-911-interactive .interactiveTimeline ul {
    width: 100%;
  }
}
.container-911-interactive .interactiveTimeline ul li {
    position: relative;
    display: inline-block;
    padding: 0;
    width: 120px;
    height: 120px;
    border: 1px solid #000;
    background-color: #fff;
    border-radius: 50%;
    margin: 0 0 0 3.6%;
    text-align: center;
    line-height: 80px;
}
.container-911-interactive .interactiveTimeline ul li:first-child {
    margin-left: 0;
}
.container-911-interactive .interactiveTimeline ul li:before {
    z-index: 1;
    content: " ";
    position: absolute;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    top: -11px;
    left: -11px;
    right: -11px;
    bottom: -11px;
}
/*.container-911-interactive .interactiveTimeline ul li:hover:before {
    border: 5px solid #ffea00;
}
*/
.container-911-interactive .interactiveTimeline h2 {
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 10%;
}
.container-911-interactive .interactiveTimeline ul li.selectSlider.selected:before {
    border: 5px solid #ffea00;
}
.container-911-interactive .interactiveTimeline ul li a {
    position: relative;
    z-index: 2;
    display: block;
    font-size: 38px;
    font-family: "Isento-ExtraBold";
    letter-spacing: -0.1em;
    font-weight: bold;
    vertical-align: middle;
    line-height: 20px;
    padding: 30% 0;
    color: #000;
}
.container-911-interactive .interactiveTimeline ul li a:hover, .container-911-interactive .interactiveTimeline ul li a:active {
    color: #000;
}
.container-911-interactive .interactiveTimeline ul li a i {
    font-family: 'Isento-Bold';
    font-size: 26px;
    font-weight: 100;
    font-style: normal;
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 4px;
}
.container-911-interactive .interactiveTimeline .infoContainer {
    width: 100%;
    padding: 20px 0 0 0;
}
.container-911-interactive .interactiveTimeline .infoContainer .infoSlider {
    width: 100%;
    display: none;
}
.container-911-interactive .interactiveTimeline .infoContainer .infoSlider.showSlider {
    display: block;
    animation: fadeIn 4s;
    -webkit-animation: fadeIn 4s;
    -moz-animation: fadeIn 4s;
    -o-animation: fadeIn 4s;
    -ms-animation: fadeIn 4s;
}
.container-911-interactive .interactiveTimeline .infoContainer .infoSlider img {
    width: 100%;
}
.container-911-interactive .interactiveTimeline .infoContainer .infoSlider .details {
    display: table;
    align-items: center;
    width: 100%;
    margin: 10px 0 0;
}
.container-911-interactive .interactiveTimeline .infoContainer .infoSlider .details .col1 {
    display: table-cell;
    padding: 0;
    margin: 0;
    vertical-align: middle;
    width: 10%;
}
.container-911-interactive .interactiveTimeline .infoContainer .infoSlider .details .col2 {
    display: table-cell;
    padding: 0;
    margin: 0;
    vertical-align: middle;
}
.container-911-interactive .interactiveTimeline .infoContainer .infoSlider .details:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    clear: both;
}
.container-911-interactive .interactiveTimeline .infoContainer .infoSlider .details h3 {
    font-size: 30px;
    padding: 0;
    margin: 0;
    font-family: 'PLAYFAIR DISPLAY';
    font-weight: 600;
}
.container-911-interactive .dataImgMobile {
    display: none;
}
.container-911-interactive .dataImg {
    display: block;
    width: 100%;
}
.container-911-interactive .interactiveTimeline .roundedTimeline {
    float: left;
    position: relative;
    display: inline-block;
    padding: 0;
    width: 120px;
    height: 120px;
    border: 1px solid #000;
    background-color: #fff;
    border-radius: 50%;
    margin: 0 20px 0 5px;
    text-align: center;
    line-height: 80px;
}
.container-911-interactive .interactiveTimeline .roundedTimeline:before {
    z-index: 1;
    content: " ";
    position: absolute;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    top: -11px;
    left: -11px;
    right: -11px;
    bottom: -11px;
}
.container-911-interactive .interactiveTimeline .roundedTimeline a {
    position: relative;
    z-index: 2;
    display: block;
    font-size: 38px;
    font-family: "Isento-ExtraBold";
    letter-spacing: -0.09em;
    font-weight: bold;
    vertical-align: middle;
    line-height: 20px;
    padding: 30% 0;
    color: #000;
}
.container-911-interactive .interactiveTimeline .roundedTimeline a i {
    display: block;
    width:100%;
    margin-top: 2px;
    font-family: 'Isento-Bold';
    font-size: 26px;
    font-weight: 100;
    font-style: normal;
}
.container-911-interactive .interactiveTimeline .infoContainer .infoSlider .span {
    font-size: 30px;
    line-height: 30px;
    padding: 1% 0;
    color: #000;
    font-weight: 600;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
   }
    100% {
        opacity: 1;
   }
}
@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
   }
    100% {
        opacity: 1;
   }
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
   }
    100% {
        opacity: 1;
   }
}
@-o-keyframes fadeIn {
    0% {
        opacity: 0;
   }
    100% {
        opacity: 1;
   }
}
@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
   }
    100% {
        opacity: 1;
   }
}
@media only screen and (max-width: 1024px) {
    .container-911-interactive .interactiveTimeline ul li {
        margin: 0 0 0 2%;
   }
}
@media only screen and (max-width: 900px) {
    .container-911-interactive h1 {
        font-size: 45px;
   }
    .container-911-interactive .interactiveTimeline ul li {
        width: 90px;
        height: 90px;
        margin: 0 0 0 1%;
   }
    .container-911-interactive .interactiveTimeline ul li:before {
        width: 110px;
        height: 110px;
        padding: 28% 0;
   }
    .container-911-interactive .interactiveTimeline ul li a {
        font-size: 30px;
        letter-spacing: -0.09em;
        line-height: 20px;
   }
    .container-911-interactive .interactiveTimeline ul li a i {
        font-size: 20px;
   }
    .container-911-interactive .interactiveTimeline .roundedTimeline {
        width: 90px;
        height: 90px;
        margin: 0 5px;
   }
    .container-911-interactive .interactiveTimeline .roundedTimeline a {
        font-size: 30px;
        letter-spacing: -0.09em;
        line-height: 20px;
   }
    .container-911-interactive .interactiveTimeline .roundedTimeline a i {
        font-size: 20px;
   }
    .container-911-interactive .interactiveTimeline .infoContainer .infoSlider .details h3 {
        font-size: 20px;
   }
    .container-911-interactive .dataImgMobile {
        display: block;
        width: 100%;
   }
    .container-911-interactive .dataImg {
        display: none;
   }
}
@media only screen and (max-width: 500px) {
    .container-911-interactive h1 {
        font-size: 25px;
        line-height: 18px;
   }
    .container-911-interactive h1.subtitle {
        font-size: 20px;
   }
    .container-911-interactive h1 i {
        font-size: 32px;
   }
    .container-911-interactive h2 {
        font-size: 16px;
   }
    .container-911-interactive .interactiveTimeline ul li {
        width: 45px;
        height: 45px;
        margin: 0 0 0 1%;
   }
    .container-911-interactive .interactiveTimeline ul li:before {
        width: 55px;
        height: 55px;
        padding: 10% 0;
        top: -6px;
        left: -6px;
        right: -6px;
        bottom: -6px;
   }
    .container-911-interactive .interactiveTimeline ul li.selectSlider.selected:before {
        border: 3px solid #ffea00;
   }
    .container-911-interactive .interactiveTimeline ul li a {
        font-size: 16px;
        letter-spacing: -0.1em;
        line-height: 10px;
   }

    .container-911-interactive .interactiveTimeline ul li a i {
        font-size: 12px;
   }
    .container-911-interactive .interactiveTimeline .roundedTimeline {
        width: 50px;
        height: 50px;
        margin: 0 10px;
   }
    .container-911-interactive .interactiveTimeline .roundedTimeline a {
        font-size: 16px;
        letter-spacing: -0.1em;
        line-height: 10px;
   }
    .container-911-interactive .interactiveTimeline .roundedTimeline a i {
        font-size: 12px;
   }
    .container-911-interactive .interactiveTimeline .infoContainer .infoSlider .details h3 {
        font-size: 14px;
        line-height: 14px;
   }
}
