$mockup-path: '../img/device-mockups' !default;

.device-wrapper {
    //max-width: 360px;
    width: 100%;
}

.device {
    position: relative;
    background-size: cover;

    .screen {
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        pointer-events: none;
    }

    .device-button {
        position: absolute;
        cursor: pointer;
    }
}
