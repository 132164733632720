// .full-width {
// 	width: 100%;
// 	.padding-1 {
// 		padding: 20px 0;
// 	}
// }
.green-bg {
	background-color: #2a341f;
	.page-title {
		h1 {
			span {
				background-color: #2a341f;
				color: #fff;
			}
		}
	}
}
.media-power {
	width: 100%;
	color: #fff;
	.media-power-item {
		display: inline-block;
		padding-bottom: 10px;
		padding-top: 10px;
		padding-left: 2%;
		width: 16%;
		position: relative;
		text-align: left;
		vertical-align: top;
		.thumb-img {
			width: 60%;
			max-height: 100px;
			overflow: hidden;
			position: absolute;
			top: 10px;
			left: 30%;
			/*filter: brightness(0.5);*/

		}
		&::before {
			content: "";
			display: inline-block;
			width: 40%;
			height: 95px;
			background: url(../img/media-power/nums_01.png) no-repeat top right;
			background-size: contain;
			margin-top: 0;
		}
		&:hover {
			background-color: #b4baaa;
			.thumb-img {
				filter: brightness(1);
			}
			.title {
				color: #fff;
			}
		}
		.title {
			font-family: 'Isento-Medium';
			color: #b4baaa;
			text-align: center;
			display: block;
			margin: 0;
			padding: 0;
			font-size: 14px;
			line-height: 14px;
			width: 90%;
		}
	}
	.media-power-item.item2{
		&::before {
			background: url(../img/media-power/nums_02.png) no-repeat top right;
			background-size: contain;
		}
	}
	.media-power-item.item3{
		&::before {
			background: url(../img/media-power/nums_03.png) no-repeat top right;
			background-size: contain;
		}
	}
	.media-power-item.item4{
		&::before {
			background: url(../img/media-power/nums_04.png) no-repeat top right;
			background-size: contain;
		}
	}
	.media-power-item.item5{
		&::before {
			background: url(../img/media-power/nums_05.png) no-repeat top right;
			background-size: contain;
		}
	}
	.media-power-item.item6{
		&::before {
			background: url(../img/media-power/nums_06.png) no-repeat top right;
			background-size: contain;
		}
	}
}
.media-power-header {
	margin: 0;
	padding: 10px 0 0 0;
	font-size: 20px;
	color: #b4baaa;
	font-family: 'Isento-Medium';
}
@media only screen and (max-width: 990px) {
	.media-power {
		.media-power-item {
			padding-left: 2%;
			width: 32%;
		}
	}
	.media-power-header {
		margin-left: 10px;
	}
}
