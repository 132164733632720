@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?2rrrrbrldc') format('woff2'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?2rrrrbrldc') format('truetype'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?2rrrrbrldc') format('woff'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?2rrrrbldc##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icomoon-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-whatsapp-square {
  &:before {
    content: $icon-whatsapp-square; 
  }
}
.icon-whatsapp1 {
  &:before {
    content: $icon-whatsapp1; 
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube; 
  }
}
.icon-calendar-minus {
  &:before {
    content: $icon-calendar-minus; 
  }
}
.icon-calendar-plus {
  &:before {
    content: $icon-calendar-plus; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-whatsapp2 {
  &:before {
    content: $icon-whatsapp2; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-quotes-right {
  &:before {
    content: $icon-quotes-right; 
  }
}
.icon-quotes-left {
  &:before {
    content: $icon-quotes-left; 
  }
}
.icon-youtube2 {
  &:before {
    content: $icon-youtube2; 
  }
}
.icon-copy {
  &:before {
    content: $icon-copy; 
  }
}
.icon-audio {
  &:before {
    content: $icon-audio; 
  }
}
.icon-play-arrow {
  &:before {
    content: $icon-play-arrow; 
  }
}
.icon-soundwave {
  &:before {
    content: $icon-soundwave; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-facebook2 {
  &:before {
    content: $icon-facebook2; 
  }
}
.icon-twitter2 {
  &:before {
    content: $icon-twitter2; 
  }
}
.icon-facebook1 {
  &:before {
    content: $icon-facebook1; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp; 
  }
}
.icon-rss {
  &:before {
    content: $icon-rss; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-envelope {
  &:before {
    content: $icon-envelope; 
  }
}
.icon-printer {
  &:before {
    content: $icon-printer; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-user-small {
  &:before {
    content: $icon-user-small; 
  }
}
.icon-graphic {
  &:before {
    content: $icon-graphic; 
  }
}
.icon-views {
  &:before {
    content: $icon-views; 
  }
}
.icon-comment {
  &:before {
    content: $icon-comment; 
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark; 
  }
}
.icon-camera {
  &:before {
    content: $icon-camera; 
  }
}
.icon-photos {
  &:before {
    content: $icon-photos; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-video {
  &:before {
    content: $icon-video; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-hamburger {
  &:before {
    content: $icon-hamburger; 
  }
}
.icon-search2 {
  &:before {
    content: $icon-search2; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-arrow-down1 {
  &:before {
    content: $icon-arrow-down1; 
  }
}

