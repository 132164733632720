
.the-mayman-profile-container {
    .the-mayman-profile {
        width: 35%;
        margin: 0 auto;
        img {
            width: 100%;
        }
    }
}
.the-mayman-about-container {
    .the-mayman-bio {
        padding: 30px 0 0 0;
        .bio-img {
            position: relative;
            width: 90%;
            margin: 0 auto;
            h1 {
                text-align: center;
                margin-bottom: 20px;
            }
            p {
				width: 55%;
				float: left;
				font-size: 1.5rem;
				display: none;
				a {
					color: #d85229;
				}
				&:first-of-type {
					display: block;
				}
			}
			p.show {
				display: block;
			}
			img {
				position: absolute;
				width: 45%;
				bottom: 0;
				right: 0;
			}
        }
    }
}
// .the-mayman-videos-container {
//     .media_embed {
//         position: relative;
//         padding-bottom: 56.25%;
//         padding-top: 30px;
//         height: 0;
//         overflow: hidden;
//         iframe {
//             position: absolute;
//             top: 0;
//             left: 0;
//             width: 100%;
//             height: 100%;
//         }
//         object {
//             position: absolute;
//             top: 0;
//             left: 0;
//             width: 100%;
//             height: 100%;
//         }
//         embed {
//             position: absolute;
//             top: 0;
//             left: 0;
//             width: 100%;
//             height: 100%;
//         }
//     }
// }
.the-mayman-show {
    position: relative;
    .orange-container {
        &::before {
            content: '';
            display: block;
            position: relative;
            margin-top: -10px;
            width: 100%;
            height: 10px;
            background: url('../img/mayman/orange-wave.png') bottom repeat-x;
        }
        &::after {
            content: '';
            display: block;
            position: relative;
            margin-bottom: 10px;
            width: 100%;
            height: 12px;
            background: url('../img/mayman/orange-wave-bottom.png') top repeat-x;
        }
    }
    // .orange-bg {
    //     background: url('../img/mayman/scratch-bg.png') #ef810a repeat;
    //     background-color: #ef810a;
    //     padding: 20px 20px;
    // }
    h1 { 
        text-align: center;
        font-weight: 700;
        font-size: 2em;
    }
    .slider-counter {
		bottom: 0;
	}
}
.the-mayman-show .orange-bg {
    background: url('../img/mayman/scratch-bg.png') #ef810a repeat;
    background-color: #ef810a;
    padding: 20px 20px; 
}

.the-mayman-show .orange-container {
    &::before {
        content: '';
        display: block;
        position: relative;
        margin-top: -10px;
        width: 100%;
        height: 10px;
        background: url('../img/mayman/orange-wave.png') bottom repeat-x;
    }
    &::after {
        content: '';
        display: block;
        position: relative;
        margin-bottom: 10px;
        width: 100%;
        height: 12px;
        background: url('../img/mayman/orange-wave-bottom.png') top repeat-x;
    }
    h1 {
        color: #fff;
        margin-bottom: 20px;
    }
    .cell {
        color: #fff;
        a {
            color: #fff;
        }
        h2 {
            color: #fff;
        }
    }
}

@media only screen and (max-width: 600px) {
    .the-mayman-profile-container {
        .the-mayman-profile {
            width: 50%;
        }
    }
    .the-mayman-show {
        h1 {
            font-size: 1.2rem;
        }
    }
    .the-mayman-about-container {
        .the-mayman-bio {
            .bio-info {
                clear: both;
                width: 100%;
            }
            padding-bottom: 20px;
            .bio-img {
				position: relative;
				p {
					font-size: 1rem;
					width: 100%;
					margin: 10px 0;
					&:first-of-type {
						display: contents;
					}
					&::after {
						content: '';
						display: block;
						width: 100%;
						height: 20px;
					}
				}
				p.show {
					display: contents;
				}
				img {
					float: right;
					width: 45%;
					position: relative;
				}
			}
        }
    }
} 