/* BEGIN FIX VIDEOS SIDE BLOCK TO RENDER 100% WIDTH */
.sidebar-wrap {
	.article-item {
		max-width: 100%;
		width: 100%;
		.article-item-img {
			img {
				width: 100%;
			}
		}
	}
}
@media only screen and (max-width: 990px) {
	.sidebar-wrap {
		.article-item {
			width: 90%;
		}
	}
}


/* END FIX VIDEOS SIDE BLOCK TO RENDER 100% WIDTH */


.block-wrapper {
	margin-bottom: 10px;
}
.section-wrapper {
	margin-bottom: 10px;
}
.full-width {
	width: 100%;
	img {
		width: 100%;
	}
}
.nationalDayHeader {
	display: block;
	width: 100%;
	height: auto;
	padding: 0;
	margin: 0;
	background: url(../img/national-day-2021/saudi_national_day_2021_banner_bg.jpg?v=2) repeat-x 0 0;
	background-size: contain;
	img {
		display: block;
		margin: 0 auto;
		padding: 0;
	}
}
img.nationalDayPoster {
	margin: 20px 0;
	width: 100%;
}
.interactiveGraphics {
	.titleHeader {
		width: 100;
        text-align:center;
		padding: 20px 0 10px 0;
		border-bottom: 1px solid #000;
	}
}
.saudi-national-day-interactive-slider {
	width: 100%;
	padding: 0 0 10px 0;
	background-color: #ccd4c7;
	margin: 20px 0 0 0;
	.slick-slide {
		img {
			width: 100%;
		}
	}
	.entry-media {
		margin-bottom: 0.5rem;
	}
}
.factsHeader {
	width: 100%;
	padding: 0;
	margin: 0;
	img {
		display: block;
		width: 40%;
		margin: 0 auto;
	}
}
.interactiveSlider {
	width: 50%;
	margin: 0 auto;
}
.hiddenFirst {
	height: 0px;
	overflow: hidden;
}
.diriyahDevelopment {
	margin: 40px 0 10px 0;
	padding: 0;
}
.titleHeader {
	text-align: center;
	font-family: playfair display,serif,Helvetica,Roboto,Arial,sans-serif;
	font-weight: bold;
	font-size: 48px;
}
// .leadershipTeam {
// 	.titleHeader {
// 		margin: 20px 0;
// 	}
// 	padding: 20px 0;
// 	.leadersBio {
// 		position: relative;
// 	}
// }
.slider-nav-thumbnails-facts {
	margin-top: 10px;
	.slick-slide {
		cursor: pointer;
		outline: none;
		height: 50px;
		width: 50px;
		img {
			padding: 1px;
			background: transparent;
		}
		&:first-child {
			img {
				margin-left: 0;
			}
		}
		&:last-child {
			img {
				margin-right: 0;
			}
		}
	}
	.slick-slide.slick-current.slick-active {
		opacity: 1;
		img {
			background: #000;
			filter: saturate(2);
		}
	}
	img {
		width: 50px;
		height: 50px;
	}
}
map#image-map-interactive {
	area {
		cursor: pointer;
	}
}
// .leadersBio {
// 	.itemBio {
// 		display: inline-block;
// 		width: 20%;
// 		cursor: pointer;
// 		vertical-align: top;
// 		margin-left: 6.2%;
// 		margin-top: 20px;
// 		&:hover {
// 			img {
// 				filter: contrast(120%);
// 			}
// 		}
// 		.bioInfo {
// 			position: relative;
// 			display: block;
// 			filter: alpha(opacity=0);
// 			-moz-opacity: 0;
// 			-khtml-opacity: 0;
// 			opacity: 0;
// 			max-height: 0px;
// 			overflow: hidden;
// 			-moz-transition: 0.3s;
// 			-ms-transition: 0.3s;
// 			-o-transition: 0.3s;
// 			-webkit-transition: 0.3s;
// 			transition: 0.3s;
// 			width: 500%;
// 			background-color: #fff;
// 			padding: 10px 10px;
// 			margin: 25px 0 0 0;
// 		}
// 		&:first-child {
// 			margin-left: 0;
// 		}
// 		&:nth-child(4n+1) {
// 			margin-left: 0;
// 		}
// 		&:nth-child(4n+2) {
// 			.bioInfo {
// 				left: -133%;
// 			}
// 		}
// 		&:nth-child(4n+3) {
// 			.bioInfo {
// 				left: -266%;
// 			}
// 		}
// 		&:nth-child(4n+4) {
// 			.bioInfo {
// 				left: -400%;
// 			}
// 		}
// 		img {
// 			width: 100%;
// 		}
// 		.bioSummary {
// 			width: 100%;
// 			text-align: center;
// 			padding: 0 4px;
// 			h3 {
// 				font-size: 1rem;
// 				font-weight: bold;
// 				line-height: 1rem;
// 			}
// 			h4 {
// 				font-size: 1rem;
// 				line-height: 1rem;
// 			}
// 		}
// 	}
// 	.itemBio.active {
// 		img {
// 			filter: contrast(120%);
// 		}
// 		background-color: #fff;
// 		.bioInfo {
// 			display: block;
// 			visibility: visible;
// 			max-height: 10000px;
// 			filter: alpha(opacity=100);
// 			-moz-opacity: 1;
// 			-khtml-opacity: 1;
// 			opacity: 1;
// 		}
// 	}
// }
.slider-counter {
	bottom: 0;
}



/* ANIMATION */
@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  /* END ANIMATION */
   
  @media only screen and (max-width: 990px) {
    .leadersBio {  }
    .leadersBio .itemBio { width:46%;}
    /*.leadersBio .itemBio:nth-child(2n+1) {  clear:both;  }
    .leadersBio .itemBio:nth-child(2n+2) {  float:right;}*/


    .leadersBio .itemBio:nth-child(4n+3) .bioInfo { left:0; }
    .leadersBio .itemBio:nth-child(4n+4) .bioInfo { left:0; }

    .leadersBio .itemBio:nth-child(2n+1) { margin-left:0; }
    .leadersBio .itemBio:nth-child(2n+2) .bioInfo {left:-116%;}

    .leadersBio .itemBio .bioInfo { width: 216%; }

    .titleHeader { font-size:1.5rem;}
}

@media only screen and (max-width: 500px) {
	.interactiveSlider {
		width: 80%;
	}
	// .leadersBio {
	// 	.itemBio {
	// 		.bioSummary {
	// 			h3 {
	// 				font-size: 1rem;
	// 				line-height: 1rem;
	// 				margin-bottom: .2rem;
	// 			}
	// 			h4 {
	// 				font-size: 0.9rem;
	// 				line-height: 0.9rem;
	// 			}
	// 		}
	// 	}
	// }
}
