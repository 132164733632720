
.expo-large-screen-only { display:block; }
.expo-small-screen-only { display:none;}
.expo-from-medium-screen-down { display:none; }


// .full-width { width:100%; }
// .full-width img { width:100%; height:auto; }
.interactiveGrahics { background-color:#fff; /*background:url('../images/blue-bg.jpg?v=2') repeat-x bottom #fff; background-size: 1px 24.2%;*/}
.interactiveGrahics .interactiveMap { position:relative; width:100%;}
.interactiveGrahics .interactiveMap img { cursor:pointer; width:100%;}
.interactiveGrahics .interactiveMap-mobile { display:none; }

.interactiveGrahics .interactiveMap .imap-popup { display:block; position:absolute; top:0; left:0; width:100%; height:100%; background-color:#ccc;}





.interactiveMapBottom { width:100%; background-color:#173141; }
.interactiveMapBottom img { width:100%;
}
.expo2020Header { padding:20px 0;}
.expo2020Header img {  width:100%; }
.expoContent { background-color:#ccdadb; padding-top:20px; }

.greenGradientBg {
    background: rgb(74,117,126);
    background: linear-gradient(176deg, rgba(74,117,126,1) 0%, rgba(60,98,109,1) 37%, rgba(26,49,65,1) 100%);
 }

.eventsCalendar { padding:0 0 20px 0; }
.eventsCalendar .eventsCalendarHead { padding-top:10px; }
.eventsCalendar .eventsCalendarData { overflow:hidden; }
.eventsCalendar .eventsCalendarData .ePage { display:none;  }
.eventsCalendar .eventsCalendarData .ePage.active {
    display:block;

}
.eventsCalendar .eventsCalendarData .ePage.slideDown {
	animation-name: slideDown;
	-webkit-animation-name: slideDown;
	animation-duration: 1s;
	-webkit-animation-duration: 1s;
	animation-timing-function: ease;
	-webkit-animation-timing-function: ease;
	visibility: visible !important;
}

.eventsCalendar .eventsCalendarData .ePage.slideUp{
	animation-name: slideDown;
	-webkit-animation-name: slideUp;
	animation-duration: 1s;
	-webkit-animation-duration: 1s;
	animation-timing-function: ease;
	-webkit-animation-timing-function: ease;
	visibility: visible !important;
}

.eventsCalendar .eventsCalendarData .ePage .ePageRow { position: relative; display: table;  padding:0; margin:10px 0; border:2px solid #f4bc5d; width:100%;}

.eventsCalendar .eventsCalendarData .ePage .ePageRow .ePageCol { position:relative; display:table-cell; vertical-align: middle; padding:0; margin:0; }

.eventsCalendar .eventsCalendarData .ePage .ePageRow .ePageCol h4 { font-size:16px; line-height:16px; padding:0; margin: 4px 4px; color:#fff; font-family: 'Isento-Medium'; }
.eventsCalendar .eventsCalendarData .ePage .ePageRow .ePageCol1 { width:10%; }
.eventsCalendar .eventsCalendarData .ePage .ePageRow .ePageCol1 h4 { font-size:22px; line-height:22px; }
.eventsCalendar .eventsCalendarData .ePage .ePageRow .ePageCol1 h4 strong { color:#55868d;  }
.eventsCalendar .eventsCalendarData .ePage .ePageRow .ePageCol2 { width:50%; background-color:#f4bc5d; }
.eventsCalendar .eventsCalendarData .ePage .ePageRow .ePageCol3 { width:25%; border-right: 2px solid #f4bc5d; }
.eventsCalendar .eventsCalendarData .ePage .ePageRow .ePageCol3 h4 { font-size:14px; line-height: 14px; }
.eventsCalendar .eventsCalendarData .ePage .ePageRow .ePageCol4 { width:10%; }
.eventsCalendar .eventsCalendarData .ePage .ePageRow .ePageCol4 h4 { font-size:14px; line-height: 14px; }

.eventsCalendar .eventsCalendarData .ePage .ePageRow:nth-child(even) { border-color: #55868d; }
.eventsCalendar .eventsCalendarData .ePage .ePageRow:nth-child(even) .ePageCol2 { background-color: #55868d; }
.eventsCalendar .eventsCalendarData .ePage .ePageRow:nth-child(even) .ePageCol1 h4 strong { color:#f4bc5d; }
.eventsCalendar .eventsCalendarData .ePage .ePageRow:nth-child(even) .ePageCol3 { width:25%; border-right: 2px solid #55868d; }


.eventsCalendar .ePageScroll { color:#fff; display:block; text-align:center; }

.expoGallery { }
.tabsGallery { margin:0; padding:10px 0;}
.tabs-wrapper { width:100%; font-family:'Isento-Medium'; display:block;  }
.tabs-wrapper .tab-menu-top { width:100%; display:table; border-collapse:separate;border-spacing:5px; }
.tabs-wrapper .tab-menu-top .tab-head { display:table-cell; text-align:center; vertical-align:middle; background:#f4bc5d; color:#fff; margin:4px 4px; font-size:16px; line-height: 16px; padding: 10px 4px;  }
.tabs-wrapper .tab-menu-top .tab-head:hover, .tabs-wrapper .tab-menu-top .tab-head.active { background:#1a3141;}


.tabs-wrapper .tabs-body-wrapper .tab-body { display:None; min-height:200px; padding:20px 0; }
.tabs-wrapper .tab-body.active { display:block; overflow:hidden; }

.tabs-wrapper .tabs-body-wrapper .tab-head { display:none; }

.tabs-wrapper .interactiveGallerySlider { width:80%; margin:0 auto; }

.interactiveGallerySlider .slider-item { position:relative; }
.interactiveGallerySlider .slider-item-caption { position: absolute; bottom: 0; background-color:#000; color:#fff; opacity:0.8; }

/*.interactiveGallerySlider .slick-arrow { background:#f4bc5d; }*/
.interactiveGallerySlider .slick-arrow:hover { background-color:#f4bc5d; }
.interactiveGallerySlider .slick-next:focus, .interactiveGallerySlider .slick-next:hover, .interactiveGallerySlider .slick-prev:focus, .interactiveGallerySlider .slick-prev:hover,
.slider-controls-mobile .slick-next:focus, .slider-controls-mobile .slick-next:hover, .slider-controls-mobile .slick-prev:focus, .slider-controls-mobile .slick-prev:hover
{  background: #f4bc5d; color: transparent; }
.slider-controls-mobile .slick-next, .slider-controls-mobile .slick-prev { height:95%;}

    .tabs-wrapper .tabs-body-wrapper .tab-body.active {
            /*animation-name: slideDown;
            -webkit-animation-name: slideDown;
            animation-duration: 1s;
            -webkit-animation-duration: 1s;
            animation-timing-function: ease;
            -webkit-animation-timing-function: ease;
            visibility: visible !important;*/
            animation: fadeIn 2s;
          -webkit-animation: fadeIn 2s;
          -moz-animation: fadeIn 2s;
          -o-animation: fadeIn 2s;
          -ms-animation: fadeIn 2s;
	 }

.slider-nav-thumbnails-gallery .slick-slide {
  cursor: pointer;
  outline: none;
  height:50px;
   width: 50px;
}
.slider-nav-thumbnails-gallery .slick-slide.slick-current.slick-active {
  opacity: 1;

}

.slider-nav-thumbnails-gallery .slick-slide img {
  padding: 1px;
  background: transparent;

}
.slider-nav-thumbnails-gallery .slick-slide.slick-current.slick-active img {
  background: #000;
}
.slider-nav-thumbnails-gallery img {
  width: 50px;
  height: 50px;
  /*margin: 0 5px;*/
}
.slider-nav-thumbnails-gallery .slick-slide.slick-current.slick-active img { filter: saturate(2);}
.slider-nav-thumbnails-gallery .slick-slide:first-child img {
  margin-left: 0;
}
.slider-nav-thumbnails-gallery .slick-slide:last-child img {
  margin-right: 0;
}



.expoEat { background-color:#fff;  padding: 0 0 30px 0; }
.expoEat .slider-wrapper { padding:40px 10%; }



.expoEntertainment { background-color:#3f8191; }

.interactiveEntertainment { position:relative; }
.interactiveEntertainment .animhover { position:absolute; top:0; left:0;  }
.interactiveEntertainment .plusIcon { position:absolute; color:#fff; font-size:40px; }
.interactiveEntertainment .plusIcon.active { color:#f4bc5d; opacity:0; }
.interactiveEntertainment .celebrity-info { display:none;  width: 30%; position:absolute; color:#fff; padding:10px 10px;  font-size:16px; line-height:18px; }
.interactiveEntertainment .celebrity-info::before { content:""; position:absolute; width:100%; height:100%; background-color:#04324c; opacity:0.7; top: 0; left: 0; z-index:1; border-radius: 10px 10px;}

.interactiveEntertainment .celebrity-info .closeBtn { position:absolute; color:#fff; z-index:3; top:10px; right:10px; font-family: 'Isento-Bold'; }

.interactiveEntertainment .celebrity-info p {  font-size:inherit; position:relative; color:#fff; z-index:2; margin: 10px 0; }
.interactiveEntertainment .celebrity-info h4 { font-size:inherit; position:relative; z-index:3; color:#000; background-color: #f4bc5d; display:inline; padding: 0 2px; margin:0 0 10px 0;}
.interactiveEntertainment .celebrity-info.active {
    display:block;
    animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
}

/* ANDREA*/
.interactiveEntertainment .plusIcon1 { z-index:1; top: 12%; left: 42%; }
.interactiveEntertainment .celebrity-info.celebrity1 { width:50%; top:0%; left:15%; z-index:2; }
/* ELLIE */
.interactiveEntertainment .plusIcon2 { z-index:1; top: 10%; right: 30%; }
.interactiveEntertainment .celebrity-info.celebrity2 { top:7%; right:0; z-index:2; }
/* LATIFA */
.interactiveEntertainment .plusIcon3 { z-index:1; top: 20%; right: 26%; }
.interactiveEntertainment .celebrity-info.celebrity3 { top:19%; right:0; z-index:2; }
/* TERRA */
.interactiveEntertainment .plusIcon4 { z-index:1; top: 31%; left: 20%; }
.interactiveEntertainment .celebrity-info.celebrity4 { top: 27%; left: 0; z-index:2; }
/* OPTI */
.interactiveEntertainment .plusIcon5 { z-index:1;  top: 69%; right: 37% }
.interactiveEntertainment .celebrity-info.celebrity5 { bottom: 30%; right: 2%; z-index:2; }
/* ALIF */
.interactiveEntertainment .plusIcon6 { z-index:1;  top: 69%; left: 25% }
.interactiveEntertainment .celebrity-info.celebrity6 { bottom: 30%; left: 0; z-index:2; }
/* RASHID */
.interactiveEntertainment .plusIcon7 { z-index:1;  top: 18%; left: 23% }
.interactiveEntertainment .celebrity-info.celebrity7 { top: 10%; left: 0; z-index:2; }

.interactiveEntertainment .robot1 { position:absolute; bottom:20%; left:40%; width:18%; height:auto;
animation: zoom-in-zoom-out 5s infinite;
}

.interactiveEntertainment .robot2 { position:absolute;  bottom: 25%; left: 25%; width:15%; height:auto;
animation: spin 10s linear infinite;
}

.interactiveEntertainment .robot3 { position:absolute; bottom: 40%; left: 44%;  width:15%; height:auto;
animation: rockupdown 3s infinite;
}



/* MODAL */
.customModal {
    position:absolute;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    /*left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    */
    z-index:999;
    }

.customModal .modalBody {
    position:absolute;
    width:90%;
    height:auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.customModal .modalBody .closeBtn { display:block; position:absolute; top:0; right:0; padding:0;margin:0; height:auto; background-color:#fff; z-index:999; text-align:center; color:#000; font-size:30px; line-height:30px; }
.customModal .modalBody .modalContent { width:100%; height:auto; }

.customModal::before { content:""; position:fixed; width:100vw; height:100vh; background-color:#000; opacity:0.8}
/* END MODAL */



/* BEGIN ANIMATIONS */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(startYposition);
    }
    100% {
        opacity: 1;
        transform: translateY(endYposition);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translateY(startYposition);
    }
    100% {
        opacity: 0;
        transform: translateY(endYposition);
    }
}

@keyframes rockupdown {
    0% { transform: translateY(0); }
     50% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
}

@keyframes spin {
    0% {
        transform:rotate(0deg) scale(1, 1)
    }
    50% {
        transform: rotate(90deg) scale(1.2, 1.2);
    }
    100% {
        transform:rotate(0deg) scale(1, 1);
    }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  70% { transform: rotate(-15deg); }

  100% {
    transform: translate(-50%, -50%) scale(1, 1);
  }
}


@keyframes jump {
    0% { transform: translate(-50%, -50%) scale(1.25, 0.75); }
    50% { transform: translate(-50%, -120%) scale(1, 1); }
    55% { transform: translate(-50%, -120%) rotate(5deg); }
    60% { transform: translate(-50%, -120%) rotate(-5deg); }
    65% { transform: translate(-50%, -120%) rotate(5deg); }
    70% { transform: translate(-50%, -120%) rotate(-5deg); }
    100% { transform: translate(-50%, -50%) scale(1.25, 0.75); }
}


@keyframes slideDown {
	0% {
		transform: translateY(-40%);
	}
	50%{
		transform: translateY(0%);
	}
	100% {
		transform: translateY(0%);
	}
}

@-webkit-keyframes slideDown {
	0% {
		-webkit-transform: translateY(-40%);
	}
	50%{
		-webkit-transform: translateY(0%);
	}
	100% {
		-webkit-transform: translateY(0%);
	}
}


@keyframes slideUp {
	0% {
		transform: translateY(40%);
	}
	50%{
		transform: translateY(0%);
	}
	100% {
		transform: translateY(0%);
	}
}

@-webkit-keyframes slideUp {
	0% {
		-webkit-transform: translateY(40%);
	}
	50%{
		-webkit-transform: translateY(0%);
	}
	100% {
		-webkit-transform: translateY(0%);
	}
}


/* END ANIMATIONS */


@media only screen and (max-width: 990px) {
    .expo-from-medium-screen-down { display:block; }
    .eventsCalendar .eventsCalendarData .ePage .ePageRow .ePageCol1 { width: 15%; }
    .eventsCalendar .eventsCalendarData .ePage .ePageRow .ePageCol2 { width: 45%; }
    .eventsCalendar .eventsCalendarData .ePage .ePageRow .ePageCol3 { width: 25%; }
    .eventsCalendar .eventsCalendarData .ePage .ePageRow .ePageCol4 { width: 10%; }
    .eventsCalendar .eventsCalendarData .ePage .ePageRow .ePageCol1 h4 { text-align:center; font-size:20px; line-height:20px;  }
    .eventsCalendar .eventsCalendarData .ePage .ePageRow .ePageCol2 h4 { font-size:14px; line-height:14px; }
    .eventsCalendar .eventsCalendarData .ePage .ePageRow .ePageCol3 h4 { font-size:14px; line-height:14px; }
    .eventsCalendar .eventsCalendarData .ePage .ePageRow .ePageCol4 h4 { font-size:14px; line-height:14px; }

    .tabs-wrapper .tab-menu-top { display:none; }
    .tabs-wrapper .tabs-body-wrapper .tab-head { display:block; background-color:#1a3141; color:#fff; padding:10px 10px; margin:2px 0; }
    .tabs-wrapper .tabs-body-wrapper .tab-head.active { background-color:#f4bc5d; }

    .tabs-wrapper .tabs-body-wrapper .interactiveGallerySlider { width:100%; margin:0; }



	 .expoEat .slider-wrapper { padding:20px 2%; }
	 /*
    .expoEat .tabs-wrapper .tabs-body-wrapper .tab-head {background-color:#ecf0e7; color:#000; }
    .expoEat .tabs-wrapper .tabs-body-wrapper .tab-head.active { background-color:#f4bc5d; color:#fff; }
    */
	 .interactiveEntertainment .plusIcon { font-size:27px; }
	 .interactiveEntertainment .celebrity-info h4 { font-size:16px; line-height:18px;  }
    .interactiveEntertainment .celebrity-info p { font-size:16px; line-height:18px; }

    .slider-controls-mobile { }
    .slider-controls-mobile .media-slider-gallery { }
    .slider-controls-mobile .media-slider-gallery .slider-item { padding:0 2px; }

.slider-controls-mobile .slick-list {
	margin: 0 50px;
}

}

@media only screen and (max-width: 500px) {
        .expo-large-screen-only { display:none;}
        .expo-small-screen-only { display:block;}

       .interactive-item-content { display:none; position:absolute; top:0; left:0;  width:100%; padding:10px 10px; background-color:#fff; color:#000; font-size:16px; font-family:'Isento-Medium'  }
       .interactive-item-content.active { display:block; }

       .interactive-item-content .closeBtn { display: block; padding: 10px; position: absolute; right: 0; background-color: #fff; font-weight: bold;}
       .interactive-item-content h1 { font-size:16px; line-height:16px; font-family:inherit; color:#f4be5e; padding:0; margin:6px 0px; font-weight:bold; }
       .interactive-item-content .img-content { width:100%;  }
        .interactive-item-content p { margin:0; padding:0;}
        .interactive-item-content h2 { font-size:20px; line-height:20px; font-family:inherit; color:#53858c; padding:0; margin:0; font-weight:bold;  }
        .interactive-item-content .info-nums p { }

        .interactive-item-content .rowItem { display:block; padding:0; margin:10px 0 0 0; text-align: center;}
        .interactive-item-content .colItem { padding:2% 2%; text-align:center; display: inline-block; width: 48%; vertical-align: top; }
        .interactive-item-content .btns-area { margin-top:10px; }


     .interactiveEntertainment .celebrity-info { /*left:0!important; top:0!important; width: 100%!important;*/ }
	 .interactiveEntertainment .celebrity-info h4 { font-size:16px; line-height:18px;  }
    .interactiveEntertainment .celebrity-info p { font-size:16px; line-height:18px; }
    .interactiveEntertainment .celebrity-info::before  { opacity:0.8;}

    .interactiveEntertainment .celebrity-info .closeBtn { position: absolute; z-index:999!important ; color:#fff!important;  }


    /* ANDREA*/
    .interactiveEntertainment .plusIcon1 { z-index:1; top: 6%; left: 35%; }
    .interactiveEntertainment .celebrity-info.celebrity1 { width:100%; top:0%; left:0; z-index:2; }
    /* ELLIE */
    .interactiveEntertainment .plusIcon2 { z-index:1; top: 10%; right: 13%; }
    .interactiveEntertainment .celebrity-info.celebrity2 { width:100%; top:7%; right:0; z-index:2; }
    /* LATIFA */
    .interactiveEntertainment .plusIcon3 { z-index:1; top: 20%; right: 2%; }
    .interactiveEntertainment .celebrity-info.celebrity3 { width:100%; top:7%; right:0; z-index:2; }
    /* TERRA */
    .interactiveEntertainment .plusIcon4 { z-index:1; top: 22%; left: 2%; }
    .interactiveEntertainment .celebrity-info.celebrity4 { width:100%; top: 27%; left: 0; z-index:2; }
    /* OPTI */
    .interactiveEntertainment .plusIcon5 { z-index:1;  top: 69%; right: 37% }
    .interactiveEntertainment .celebrity-info.celebrity5 { width:100%; bottom: 30%; right: 2%; z-index:2; }
    /* ALIF */
    .interactiveEntertainment .plusIcon6 { z-index:1;  top: 69%; left: 25% }
    .interactiveEntertainment .celebrity-info.celebrity6 { width:100%; bottom: 30%; left: 0; z-index:2; }
    /* RASHID */
    .interactiveEntertainment .plusIcon7 { z-index:1;  top: 10%; left: 13%; }
    .interactiveEntertainment .celebrity-info.celebrity7 { width:100%; top: 10%; left: 0; z-index:2; }


    .interactiveEntertainment .robot1 { bottom: 47%; left: 40%; width: 30%;}
    .interactiveEntertainment .robot2 { bottom: 50%; left: 4%; width: 34%;}
    .interactiveEntertainment .robot3 { bottom: 58%; left: 38%; width: 25%;}

}