@media only screen {
    .no-spacer {
        margin: 0 !important;
    }
    .no-top-spacer {
        margin-top: 0 !important;
        padding-top: 0 !important;
    }
    .no-bottom-spacer {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }
    .no-left-spacer {
        margin-left: 0 !important;
        padding-left: 0 !important;
    }
    .no-right-spacer {
        margin-right: 0 !important;
        padding-right: 0 !important;
    }
    .top-spacer--xl {
        margin-top: 3rem !important;
    }
    .top-spacer--l {
        margin-top: 2.5rem !important;
    }
    .top-spacer--m {
        margin-top: 2rem !important;
    }
    .top-spacer {
        margin-top: 1.5rem !important;
    }
    .top-spacer--s {
        margin-top: 1rem !important;
    }
    .top-spacer--xs {
        margin-top: 0.5rem !important;
    }
    .bottom-spacer--xl {
        margin-bottom: 3rem !important;
    }
    .bottom-spacer--l {
        margin-bottom: 2.5rem !important;
    }
    .bottom-spacer--m {
        margin-bottom: 2rem !important;
    }
    .bottom-spacer {
        margin-bottom: 1.5rem !important;
    }
    .bottom-spacer--s {
        margin-bottom: 1rem !important;
    }
    .bottom-spacer--xs {
        margin-bottom: 0.5rem !important;
    }
}

.text-transform-uppercase {
    text-transform: uppercase;
}

.text-transform-capitalize {
    text-transform: capitalize;
}

/* Drupal admin styles ----- START -----*/

.password-strength,
div.password-confirm {
    display: none;
    @include breakpoint(medium) {
        display: block;
    }
}

.drupal-tabs ul.tabs {
    list-style: none;
    margin-right: 0;

    li {
        background-color: #f8f8f8;
        border-radius: 5px;
        display: inline-block;
        margin: 0 5px 8px;
        padding: 5px 15px;

        &:hover,
        &.active {
            background: $primary-color;

            a {
                color: white;
            }
        }
    }
}

.page-taxonomy-term .article-teaser .article-category {
    display: none;
}

.load-more ul li {
    list-style: none;
}

.pager--infinite-scroll {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    text-align: center;

    li a {
        @include button(false, $gray, $primary-color, $secondary-color, hollow);
        @include button-style($gray, transparent, $secondary-color);
        font-family: $font-secondary;
        text-transform: uppercase;
        width: 30%;
    }
}

.double-border-bottom {
    margin-bottom: 1rem;
    border-bottom: 4px double rgba(#a1a194, 0.4);
}

.width-auto {
    img {
        width: auto !important;
    }
}

/* Drupal admin styles ----- END -----*/

.form-item-terms-of-use {
    a {
        color: $primary-color;
    }
}

@include -zf-each-breakpoint {
    @for $i from 1 through $block-grid-max {
        .#{$-zf-size}-up-#{$i} {
            .column:last-child,
            .columns:last-child {
                @include grid-column-end;
            }
        }
    }
}

@include breakpoint(large) {
    [dir="rtl"] {
        .large-uncentered,
        .large-push-0,
        .large-pull-0 {
            float: right;
            margin-left: 0;
            margin-right: 0;
            position: static;
        }
    }
}

.vertical-center {
    @include vertical-center();
}

.center-element {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.round {
    border-radius: 1000px;
}

.use-loader {
    height: 360px;
    overflow: hidden;
    background: url(../img/ajax-loader.gif) no-repeat center center transparent;

    > div {
        opacity: 0;
        visibility: hidden;
        height: 400px;
    }
}

.use-loader.slick-initialized.slick-loaded {
    background: none;
    height: auto;
    overflow: visible;

    > div {
        height: auto;
    }

    &.slider-nav {
        background: $black;
    }
}

.use-loader.slick-initialized.slick-loaded .slick-list,
.use-loader.slick-initialized.slick-loaded button {
    opacity: 1;
    visibility: visible;
    transition: all 0.25s ease-in;
}

.round {
    border-radius: 1000px;
    overflow: hidden;
}

/*
.input-group.with-radius > *:first-child {
  border-radius: 0 5px 5px 0;
}

.input-group.with-radius > *:last-child > * {
  border-radius: 5px 0 0 5px;
}
*/

.show-for-printable-area {
    display: none;
}

input {
    &.radius,
    .input-group > &:first-child {
        border-radius: 4px;
    }

    .search-form & {
        &.input-group-field {
            border-radius: 4px !important;
        }
    }
}

textarea {
    height: 10rem;
}

.arial-heading {
    font-family: Arial, sans-serif;
}

.font-primary {
    font-family: $font-primary;
}

.font-secondary {
    font-family: $font-secondary;
}

.font-secondary-bold {
    font-family: $font-secondary-bold;
}

a {
    transition:
        color 0.25s ease 0s,
        background-color 0.25s ease 0s,
        opacity 0.25s ease 0s;

    &.hovered {
        color: $nevada;
    }
}

.off-canvas {
    height: 100%;
}

.js-off-canvas-exit {
    z-index: 10;
}

[dir="rtl"] .close-button {
    right: auto;
    left: 1rem;
}

/*.placeholder {
  font-size: 0.875rem;
  color: #aaa;
}*/

.subpanel-search input::placeholder {
    text-align: center;
    font-family: "icomoon";
}

p {
    font-size: 1rem;
}

figure {
    margin: 0 0 0.5rem;

    &.round {
        overflow: hidden;
    }
}

.leaderboard-ad {
    font-size: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
}

button,
.button {
    font-family: $font-secondary;

    &.gray.hollow {
        color: #5d686c;

        &:hover {
            color: $primary-color;
            border-color: $primary-color;
        }
    }

    &.radius {
        border-radius: 4px;
    }
}

h1 {
    line-height: 1;
}

h2 {
    line-height: 1.1;
}

.gutter-20 {
    @include breakpoint(large) {
        &.row {
            margin-left: rem-calc(-10);
            margin-right: rem-calc(-10);
        }
        .column {
            @include grid-column-gutter(20px);
        }
    }
}

.gutter-10 {
    @include breakpoint(large) {
        &.row {
            margin-left: rem-calc(-5);
            margin-right: rem-calc(-5);
        }
        .column {
            @include grid-column-gutter(10px);
        }
    }
}

.lightBlue-bg {
    background-color: #c6d4e6;
}

.block-custom-bg-2 {
    background-color: #dae1ea;
    color: $black;
}

.white-smoke-opacity-bg {
    background-color: #d1dbe8;
}

/* ------ navigation, panel and sticky header ------ START ------ */

.nav-container {
    border-top: 1px solid $limed-spruce;
    border-bottom: 1px solid $gray;
    margin-bottom: 0.5rem;
    font-family: $font-secondary-bold;

    > ul {
        > li {
            padding: 0 0.625rem;
            @include breakpoint(1060) {
                padding: 0 0.85rem;
            }
            @include breakpoint(1150) {
                padding: 0 0.8rem;
                .business-wrap & {
                    padding: 0 0.8rem;
                }
            }

            &.menu-24071 {
                .submenu {
                    text-align: right;
                }
            }
            &.menu-27456 {
                .submenu {
                    text-align: right;
                }
            }

            &.road-to-2030,
            &.menu-19336 {
                a {
                    text-indent: -9999px;
                    width: 89px;
                    background: transparent url("../img/logo-roadto2030-small.png?zzz") no-repeat center center;
                }
            }

            > a {
                padding: 1.125rem 0;
                text-transform: uppercase;
                color: $secondary-color;
                display: block;
                font-size: rem-calc(14);

                &:hover {
                    color: $primary-color;
                }
            }

            &:hover {
                > a {
                    color: $primary-color;
                }

                > .submenu {
                    visibility: visible;
                    opacity: 1;
                }
            }

            &.active {
                > a {
                    color: $secondary-color;
                    background-color: transparent;
                    position: relative;

                    &:after {
                        display: block;
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        border-bottom: 6px solid $primary-color;
                    }
                }
            }
        }
    }

    .submenu {
        margin: 0 auto;
        background-color: $green-white;
        width: auto;
        position: absolute;
        z-index: 4;
        visibility: hidden;
        opacity: 0;
        border: 0;
        top: 100%;
        left: 0;
        right: 0;
        font-size: 0;
        transition: opacity 0.3s ease-in-out;

        .submenu-list {
            border-top: 1px solid $gray;
            list-style: none;
            margin: 0 auto;
        }

        #sticky-header &,
        .domain-www-arabnews-pk & {
            @include breakpoint(large) {
                left: -100%;
                right: -100%;
            }

            .submenu-list {
                @include breakpoint(large) {
                    width: 1080px;
                    margin: 0 auto;
                }
            }
        }

        li {
            width: auto;
            display: inline-block;
            vertical-align: middle;

            &.active > a {
                background: transparent;
                color: $primary-color;
            }

            a {
                display: block;
                text-transform: uppercase;
                position: relative;
                color: rgba(#1a1a1a, 0.8);
                padding: 14px 20px;
                font-size: 12px;

                &:hover {
                    //background-color: transparent;
                    color: $primary-color;
                }
            }
        }

        .menu-19336 {
            a {
                text-indent: -9999px;
                width: 89px;
                background: transparent url("../img/logo-roadto2030-small.png?zzz") no-repeat center center;
            }
        }
    }
}

.main-header-after .show-submenu {
    margin-bottom: 3rem;

    li.active {
        .submenu {
            opacity: 1;
            visibility: visible;
            border-bottom: 1px solid rgba($dusty-gray, 0.2);
        }
    }
}

.menu-panel {
    font-family: $font-secondary-bold;
    font-size: rem-calc(14);

    li {
        position: relative;
        border-bottom: 1px solid #d6d6d6;

        &.road-to-2030,
        &.menu-19361 {
            span {
                > a {
                    height: 100%;
                    text-indent: -9999px;
                    background: url("../img/logo-roadto2030-small.png") no-repeat left center;
                }
            }

            &.active {
                border-bottom: 3px solid $primary-color;
            }
        }

        &:last-child {
            border-bottom: 3px double #d6d6d6;
        }

        &.active {
            > a,
            span a {
                background-color: transparent;
                color: $primary-color;
            }

            &.is-accordion-submenu-parent[aria-expanded="true"] > a {
                color: $primary-color;
            }
        }

        span {
            display: block;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }

        a {
            text-transform: uppercase;
            display: block;
            padding: 0.85rem 1.25rem 0.85rem 0;
            line-height: 1;
            color: $nevada;

            &:hover {
                color: $primary-color;
            }

            img {
                margin-left: 0;
                margin-right: 0.625rem;
            }
        }

        &.menu-1201 {
            > a {
                background: transparent url("../img/icons/tv-icon.png") no-repeat right 90px center;
            }
        }

        &.menu-19361 {
            > a {
                height: 100%;
                text-indent: -9999px;
                background: url("../img/logo-roadto2030-small.png") no-repeat left center;
            }
        }
    }

    ul {
        font-size: rem-calc(14);
        padding: 0.5rem 0 1rem;

        &.nested {
            margin-left: 2rem;
        }

        li {
            border-bottom: 0 !important;

            &.active {
                position: relative;

                &:before {
                    display: block;
                    content: "";
                    position: absolute;
                    left: -80px;
                    top: 50%;
                    margin-top: -7px;
                    width: 50px;
                    height: 14px;
                    background-color: $primary-color;
                    z-index: 1;
                }

                > a,
                span a {
                    background-color: transparent;
                    color: $black;
                }

                &.is-accordion-submenu-parent[aria-expanded="true"] > a {
                    color: $black;
                }
            }

            a {
                text-transform: capitalize;
                padding: 0.75rem 0;
            }
        }
    }
}

.menu-panel .is-accordion-submenu-parent {
    > a:after {
        display: none;
    }

    > a {
        &:hover {
            &:before {
                color: $primary-color;
            }
        }

        &:before {
            border-right: 0;
            content: "\e918";
            font-family: "icomoon";
            font-size: rem-calc(14);
            font-weight: normal;
            color: #898f93;
            height: 100%;
            right: 0;
            line-height: 38px;
            margin-top: 0;
            position: absolute;
            text-align: center;
            top: 0;
            width: 50px;
            z-index: 1;
        }
    }

    &[aria-expanded="true"] {
        > a {
            color: $primary-color;

            &:before {
                content: "\e91b";
            }
        }
    }
}

#sticky-header {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: -100px;
    width: 100%;
    z-index: 100;
    transition: all 0.4s ease 0s;
    //min-height: 50px;
    padding: 0.65rem 10px;

    @include breakpoint(large) {
        padding: 0 0.75rem 0;
    }
    @include breakpoint(1150) {
        padding: 0 1.25rem 0;
    }

    &.stuck-header {
        visibility: visible;
        opacity: 1;
        top: 0;
        display: block;
        background: $green-white;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
        box-shadow: 0 1px 10px 0 $gray;

        ~ .canvas-panel {
            top: 55px;
        }
    }

    &.stuck-header-ad {
        position: relative;
        visibility: visible;
        opacity: 1;
        top: 0;
    }

    .nav-container {
        border: 0;
        margin-bottom: 0;
        z-index: 1;
        position: relative;
        @media screen and #{breakpoint(large)} and #{breakpoint(1024 down)} {
            margin-left: -0.5rem;
            margin-right: -0.5rem;
        }

        ul {
            li {
                padding: 0 0.3rem;
                @media screen and #{breakpoint(1120)} and #{breakpoint(1220 down)} {
                    padding: 0 0.65rem;
                }
                @media screen and #{breakpoint(1220)} and #{breakpoint(1270 down)} {
                    padding: 0 0.9rem;
                }
                @media screen and #{breakpoint(1270)} and #{breakpoint(1370 down)} {
                    padding: 0 0.65rem;
                }
                @include breakpoint(1370) {
                    padding: 0 0.8rem;
                }
                @include breakpoint(1430) {
                    padding: 0 0.8rem;
                }

                a {
                    font-size: rem-calc(13);
                    padding: 1rem 0;
                    /*@media screen and #{breakpoint(large)} and #{breakpoint(1100 down)} {
            padding: 1rem 0.625rem;
          }
          @media screen and #{breakpoint(1100)} and #{breakpoint(1300 down)} {
            padding: 1rem 1rem;
          }
          @include breakpoint(1300) {
            padding: 1rem 1.125rem;
          }*/
                }
            }
        }

        .submenu {
            box-shadow: 0 0 4px 0 $gray;

            li {
                a {
                    padding: 1rem 0;
                }
            }
        }
    }

    .subheader-block {
        @media screen and #{breakpoint(1270)} {
            //min-width: 160px;
        }
        @media screen and #{breakpoint(large)} and #{breakpoint(1270 down)} {
            .socials-area {
                display: none;
            }
        }
    }
}

.sticky-header-spacer {
    display: none;
    height: auto;
    visibility: hidden;
}

#area-to-stick {
    position: relative;
    z-index: 9999999;
    padding: 0.65rem 0.75rem;
    background-color: $green-white;
    @include breakpoint(medium) {
        display: none !important;
    }
}

.toggle-nav-panel {
    position: relative;
    z-index: 1;
    //vertical-align: middle;
    font-size: 1.3rem;
    cursor: pointer;
    color: $nevada;

    &:hover {
        color: $primary-color;
    }

    &.is-active {
        font-size: 1rem;
    }
}

.canvas-panel {
    padding: 2rem 1.5rem;
    bottom: 0;
    height: auto;
    /*
  bottom: 0;
  height: auto;*/
    top: 55px;
    box-shadow: -10px 0px 10px -5px $light-gray;
    @include breakpoint(medium) {
        padding: 2rem 3rem;
        top: 114px;
    }
    @include breakpoint(large) {
        visibility: hidden;
        opacity: 0;
    }
}

.close-panel {
    height: 4rem;

    span {
        padding: 0 1rem;
        cursor: pointer;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 2.25rem;

        &:hover {
            color: $primary-color;
        }
    }
}

.print-edition-area {
    font-family: $font-secondary;
    font-size: rem-calc(12);
    padding: 2rem 0;
    border-bottom: 3px double #d6d6d6;

    h6 {
        text-transform: uppercase;
        font-family: $font-secondary-bold;
        font-size: rem-calc(14);
        margin-bottom: 1.25rem;
    }

    span {
        display: block;

        a {
            color: #464646;

            &:hover {
                color: $primary-color;
            }
        }
    }

    a {
        display: block;
        text-transform: capitalize;
        color: #ababab;
        margin-top: 0.5rem;

        &:last-child {
        }

        &:hover {
            color: $primary-color;
        }
    }
}

/* ------ navigation, panel and sticky header ------ END ------ */

/* Search Area Styles ----- START ----- */

.search-field {
    input[type="submit"] {
        border: 0;
        background: transparent;
        font-family: "icomoon";
        color: $white;
        cursor: pointer;
        padding: 0.4rem;

        &:hover {
            color: $primary-color;
        }
    }
}

.search-area {
    display: inline-block;
    position: absolute;
    right: 0;
    //z-index: 0;
    width: 320px;
    @include breakpoint(medium) {
        width: 400px;
    }
}

.searchbox {
    position: relative;
    width: 0;
    float: right;
    overflow: hidden;
    transition: width 0.25s;
    right: auto;
    min-width: 20px;
    height: 20px;

    &.searchbox-open {
        overflow: visible;
    }
}

.searchbox-input {
    right: 0;
    border: 0;
    outline: 0;
    background: $smoke;
    width: 100%;
    margin: 0;
    padding: 0 20px 0 20px;
    font-size: 1rem;
    color: $black;
    height: 40px;
    position: absolute;
    top: calc(100% + 0.25rem);
    z-index: 1;
}

.searchbox-icon,
.searchbox-submit {
    font-family: "icomoon";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    border: 0;
    outline: 0;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    background: $green-white;
    padding: 0;
    color: #898f93;
    font-size: 1.125rem;
    width: 20px;
    height: 20px;
    line-height: 20px;

    &:hover,
    &:focus {
        color: $primary-color;
    }

    .searchbox-open & {
        background: $green-white;
        color: $primary-color;
    }

    #sticky-header & {
        background-color: $green-white;
    }

    @include breakpoint(medium) {
        font-size: 1rem;
    }
}

.searchbox-open {
    width: 100%;
}

.byline {
    position: absolute;
    top: 150px;
    right: 30%;
    text-align: center;
    font-size: 18px;
}

.byline a {
    text-decoration: none;
    color: #d74b4b;
}

.search-form-wrapper {
    margin-bottom: 2.5rem;
}

.search-form {
    input.input-group-field {
        background-color: #fdfdfc;
    }

    .input-group-button {
        padding-left: 3rem;
    }

    .button {
        font-size: rem-calc(13);
        background-color: $nevada;
        width: 160px;

        &:hover {
            background-color: $primary-color;
        }
    }
}

.search-form-small {
    input.input-group-field {
        background-color: #fdfdfc;
    }

    .input-group-button {
        padding-left: 0;
    }

    .button {
        font-family: "icomoon";
        font-size: 1.125rem;
        background-color: $spring-wood;
        width: 45px;
        padding: 0;
        color: $nevada;

        &:hover {
            color: $primary-color;
        }
    }
}

.search-meta {
    font-family: $font-secondary;
    font-size: 13px;

    .search-term {
        color: $nevada;

        span {
            font-family: $font-secondary-bold;
        }
    }

    .typo {
        font-family: $font-secondary-bold;
        color: $black;

        span {
            color: $primary-color;
            text-transform: uppercase;
        }
    }
}

/* Search Area Styles ----- END ----- */

.container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow-x: hidden;
}

main,
header,
footer {
    position: relative;
}

main {
    //background: transparent url("../img/texture1.png") no-repeat center bottom;
    //padding-top: 1rem;
    margin: 0 auto;
    @include breakpoint(medium only) {
        max-width: 700px;
    }
}

.sidebar-wrap,
.static-column-wrap {
    @include breakpoint(large) {
        width: 346px;
        height: 100%;
    }
}

header#main-header {
    @include breakpoint(small only) {
        box-shadow: 0 1px 2px 2px rgba($black, 0.2);
        margin-bottom: 1.5rem;
        .front &,
        .years-node &,
        .football-takeover &,
        .page-anniversary & {
            margin-bottom: 0;
        }
    }
}

.main-header-before {
    margin-bottom: 0;
    line-height: 16px;
    padding: 0.5rem 0 0.7rem;
    background-color: $green-white;
    position: relative;
    //z-index: 1;

    .front &,
    .years-node &,
    .football-takeover &,
    .page-anniversary & {
        padding-bottom: 1rem;
    }

    @include breakpoint(medium only) {
        z-index: 99;
    }
    @include breakpoint(large) {
        padding: 0.5rem 0 0;
        background-color: transparent;
    }
}

.logo {
    /*position: relative;
  z-index: 1;*/
    margin-bottom: 1rem;
    text-align: center;
    line-height: 1;
    position: relative;
    //z-index: 99;
    .main-header-inner & {
        @include breakpoint(large) {
            //margin-top: -1rem;
        }
        /*@include breakpoint(medium only) {
      padding-top: 1rem;
    }*/
    }

    .front &,
    .years-node &,
    .football-takeover &,
    .page-anniversary &,
    .frankly-speaking &,
    .page-abuhilalain & {
        z-index: 0;
        padding-top: 0;
        margin-top: 0;

        img {
            max-width: 100%;
            margin-top: 0;
        }
    }

    img {
        max-width: 240px;
        margin-top: -1rem;
        @include breakpoint(medium down) {
            margin-top: 0;
        }
    }

    #sticky-header &,
    #area-to-stick & {
        margin-bottom: 0;
        position: relative;
        z-index: 1;

        img {
            max-width: 190px;
            @include breakpoint(medium) {
                //max-width: 160px;
            }
        }
    }
}

.subheader-block {
    line-height: 1;
}

.holder-area {
    position: relative;

    header &,
    #sticky-header & {
        width: 20px;
        height: 20px;
        //line-height: 20px;
        display: inline-block;
        vertical-align: middle;
    }
}

.edition-select {
    display: inline-block;
}

.current-date {
    display: inline-block;
    vertical-align: middle;
    font-family: $font-secondary;
    font-size: 11px;
    color: $secondary-color;
    font-weight: 400;
    line-height: 20px;
}

.dropdown-wrapper {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    //z-index: 2;
    //float: right;
    .button {
        margin-bottom: 0;
        background-color: transparent;
        padding: 0;
        border: 0;
        min-width: 95px;
        height: auto;
        text-align: left;
        font-family: $font-secondary;
        font-size: 11px;
        color: #898f93;
        font-weight: 400;
        vertical-align: top;
        line-height: 20px;

        &:after {
            display: none;
        }

        i {
            font-size: rem-calc(14);
            margin-right: 0.5rem;
        }
    }

    .dropdown-menu {
        opacity: 0;
        visibility: hidden;
        padding: 0.25rem 0;
        position: absolute;
        top: calc(100% + 0.25rem);
        left: 0;
        background-color: $light-gray;
        z-index: 2;
        min-width: 160px;

        &.expand {
            opacity: 1;
            visibility: visible;
        }

        a {
            color: $secondary-color;

            &:hover {
                color: $primary-color;
            }
        }
    }
}

.banner {
    font-size: 14px;
    color: #1a1a1a;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    margin-bottom: 1rem;
    display: none;

    .front &,
    .domain-www-arabnews-pk &,
    .years-node &,
    .football-takeover &,
    .page-anniversary & {
        display: block;
    }
}

#main-footer {
    padding: 1.25rem 0;
    border-top: 3px double $nevada;
    border-bottom: 3px solid $nevada;
    margin-bottom: 0.25rem;
    @include breakpoint(large) {
        padding: 2.5rem 0;
    }
}

.main-footer-before {
    margin-bottom: 1.5rem;
}

.main-footer-inner,
.main-footer-after {
    padding: 0 2.5rem 0;
    @include breakpoint(medium) {
        padding: 0;
    }
}

.logo-footer {
    margin-bottom: 1.5rem;
    @include breakpoint(medium) {
        margin-bottom: 0;
    }

    img {
        vertical-align: top;
        max-width: 190px;
    }
}

.copyright {
    opacity: 0.8;
    font-family: $font-secondary;
    font-size: 10px;
    color: $nevada;

    .canvas-panel & {
        padding: 0 1.5rem 2rem;
    }
}

.footer-menu {
    ul {
        margin-bottom: 1.5rem;

        li {
            font-family: $font-secondary-bold;
            font-size: rem-calc(14);
            text-transform: uppercase;

            a {
                color: $secondary-color;
                padding: 0.5rem 0;

                &:hover {
                    color: $primary-color;
                }
            }
        }
    }
}

ul.extra-links {
    li {
        text-transform: capitalize;
        font-size: rem-calc(12);

        a {
            padding: 0.4rem 0;
            color: #3b3737;

            &:hover {
                color: $jelly-bean;
            }
        }
    }

    .canvas-panel & {
        margin-bottom: 2rem;

        li {
            font-family: $font-secondary-bold;
        }
    }
}

.block-wrapper {
    margin-bottom: 4rem;
    @include breakpoint(large) {
        &#sticky-block {
            margin-bottom: 0;
        }
    }

    &.ad-block-header {
        margin: 0;
        @include breakpoint(small only) {
            margin-top: 15px; 
        }
    }
}

.block-custom-v2 {
}

.section-wrapper {
    margin-bottom: 4rem;
    &.section-style-v2 {
        background-color: #f6f5ef;
    }
}

.block-title,
.section-title,
.page-title {
    margin-bottom: 1.5rem;
    color: #303638;
    text-transform: capitalize;
    /*
    @include breakpoint(small only) {
      text-align: left;
    }
    */
    &.title-italic a {
        font-style: italic;
    }

    h1,
    h2,
    h3,
    h4 {
        margin-bottom: 0;
        color: inherit;
        font-weight: bold;
        @include breakpoint(small only) {
            font-size: rem-calc(30);
        }

        a {
            color: inherit;

            &:hover {
                color: $nevada;
            }
        }
    }

    &.separator-center-above {
        &:before {
            display: table;
            content: " ";
            -webkit-flex-basis: 0;
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -webkit-box-ordinal-group: 2;
            -webkit-order: 1;
            -ms-flex-order: 1;
            order: 1;
            position: relative !important;
            top: -10px;
            width: 5rem;
            border-bottom: 0.125rem solid $black;
            margin: 1rem auto 0;
        }
    }

    &.separator-left-above {
        &:before {
            display: table;
            content: " ";
            -webkit-flex-basis: 0;
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -webkit-box-ordinal-group: 2;
            -webkit-order: 1;
            -ms-flex-order: 1;
            order: 1;
            position: relative !important;
            top: -10px;
            width: 8rem;
            border-bottom: 1px solid $black;
            margin: 1rem 0 0;
        }
    }

    &.style-v2 {
        h1,
        h2,
        h3,
        h4 {
            text-transform: uppercase;
            font-weight: normal;

            span.chars-style {
                color: $primary-color;
            }
        }
    }

    &.style-v3 {
        position: relative;
        text-align: center;
        text-transform: lowercase;
        &.text-left {
            text-align: left;
        }
        .subheader {
            text-align: center;
            text-transform: capitalize;
            margin-top: 0.3rem;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: $font-secondary;
        }

        &:after {
            display: block;
            content: "";
            position: absolute;
            width: 100%;
            top: 50%;
            height: 1px;
            background-color: rgba(#b3b3b3, 0.5);
        }

        &.text-left {
            span {
                padding-left: 0;
            }
        }
        span {
            position: relative;
            z-index: 1;
            display: inline-block;
            background-color: $green-white;
            padding: 0 1.5rem;
            text-transform: capitalize;
            .block-custom-bg & {
                background-color: #f6f5ee;
            }
        }

        @include breakpoint(small only) {
            span {
                padding: 0;
            }
        }
    }

    &.style-v4 {
        h1,
        h2,
        h3,
        h4 {
            font-weight: normal;
            .section-style-v2 & {
                font-weight: bold;
                font-style: italic;
            }
        }

        span {
            padding-bottom: 0.85rem;
            display: inline-block;
            border-bottom: 1px solid $primary-color;
            letter-spacing: -0.05em;
        }

        .subheader {
            font-weight: bold;
            font-family: $font-secondary;
            margin: 1rem 0;
            text-transform: uppercase;
            color: $dark-gray;
        }
    }
    &.style-v5 {
        span {
            padding-bottom: 0.85rem;
            display: inline-block;
            border-bottom: 1px solid $medium-gray;
        }
    }

    &.style-v6 {
        border-bottom: 2px solid #4d9396;
        a {
            color: #4d9396;
        }
        date,
        span {
            font-family: "Isento-Medium";
            font-size: rem-calc(12);
            color: #8a8f92;
        }
        h5 {
            text-transform: uppercase;
            font-family: "Isento-Bold";
            font-size: rem-calc(14);
            font-weight: bold;
        }
    }
}

.section-title,
.page-title {
    &.style-v3 {
        &:after {
            margin-top: 2px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: $font-primary;
            font-weight: bold;
        }

        @include breakpoint(small only) {
            //text-align: left;
            &:after {
                display: none;
            }
            /*span {
        padding: 0;
      }*/
        }
    }
}

.section-title {
    &.hc-sticky {
        z-index: 2;
        background: #ecf0e7;
        padding: 0.5rem 0;
        margin-bottom: 0;
        box-shadow: 0px 4px 2px -2px $gray;
    }

    &.vision-2030 {
        text-align: center;

        img {
            display: block;
            max-width: 170px;
        }
    }
}

.page-title {
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 2rem;
        @include breakpoint(small only) {
            font-size: 1.5rem;
            padding: 10px 5px;
            color: #fff;
            background: #cd2c24;
            display: inline;
            span {
                background-color: transparent !important;
            }
        }
    }
    .subheader {
        font-size: rem-calc(15);
        color: $secondary-color;
    }

    &.vision-2030 {
        text-align: center;
        @include breakpoint(small only) {
            img {
                max-width: 250px;
            }
        }
    }
    &.research-studies {
        text-align: center;
        img {
            max-width: 650px;
        }
        @include breakpoint(small only) {
            img {
                max-width: 250px;
            }
        }
    }
}

.first-letter-style {
    h1:first-letter,
    h2:first-letter,
    h3:first-letter,
    h4:first-letter,
    h5:first-letter,
    h1 span:first-letter,
    h2 span:first-letter,
    h3 span:first-letter,
    h4 span:first-letter,
    h5 span:first-letter {
        color: $nevada;
    }
}

.objects-list {
    margin-bottom: 2rem;

    .block-wrapper & {
        margin-bottom: 0;
        /*.object-item:last-child {
      .media-object {
        margin-bottom: 0;
      }
    }*/
    }

    &.padding-horizontal-1 {
        .media-object-section:first-child {
            padding-right: rem-calc(10);
        }
    }

    &.has-separator {
        .media-object {
            margin-bottom: 0;
        }

        .object-item {
            padding: 1rem 0;

            &.increased-space {
                padding: 1.5rem 0;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #d1d1d1;
            }

            &:first-child {
                //padding-top: 0;
            }

            &.increased-space {
                h3 {
                    margin-bottom: 0.75rem;
                }
            }
        }
    }
}

.items-area-list {
    &.has-separator {
        .media-object {
            margin-bottom: 0;
        }

        .item-area {
            padding: 1rem 0;

            &.increased-space {
                padding: 1.5rem 0;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #d1d1d1;
            }

            &:first-child {
                padding-top: 0;
            }

            &.increased-space {
                h3 {
                    margin-bottom: 0.75rem;
                }
            }
        }
    }
}

.author-name,
.article-episode {
    margin-bottom: 0.25rem;

    .object-item.increased-space & {
        margin-bottom: 0.75rem;
    }

    a {
        font-family: $font-secondary;
        font-size: rem-calc(14);
        color: $primary-color;

        .block-wrapper-v2 & {
            color: #5e9096;
        }

        &:hover {
            color: $secondary-color;
        }
    }
}

.category-field {
    margin-bottom: 0.25rem;

    a {
        font-family: $font-secondary;
        font-size: rem-calc(11);
        color: $jelly-bean;

        &:hover {
            color: $secondary-color;
        }
    }
}

.meta-area {
    line-height: 1;
    font-size: rem-calc(12);
}

.link-holder {
    font-family: $font-secondary;
    font-size: rem-calc(11);
    margin: 0 0 0.5rem;

    a {
        color: $limed-spruce !important;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.print-edition-cover {
    margin-bottom: 0.5rem;
}

.print-edition-footer {
    max-width: 110px;
    margin: 0 auto;
    padding: 0.75rem 0;
    color: #464646;
    font-family: $font-secondary;
    font-size: rem-calc(12);

    a {
        color: inherit;

        &:hover {
            color: $primary-color;
        }
    }

    hr {
        margin: 0.5rem auto;
        border-bottom: 1px solid rgba(#647678, 0.3);
    }

    span {
        a {
            color: #ababab;
        }
    }
}

.grid-items-wrapper {
    margin-bottom: 1rem;
}

.article-item,
.event-item {
    position: relative;
    margin-bottom: 2.5rem;

    .grid-items & {
        max-width: 360px;
        margin-left: auto;
        margin-right: auto;
    }

    .hero-grid-items & {
        max-width: 360px;
        margin-left: auto;
        margin-right: auto;
    }

    .articles-list &,
    .events-list & {
        margin-bottom: 1.5rem;
        @include breakpoint(small only) {
            max-width: 360px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.article-item-img,
.event-item-img {
    position: relative;
    margin-bottom: 0.5rem;

    .equalize-columns & {
        margin-bottom: 0.75rem;
    }

    > a {
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: transparent;
            transition:
                color 0.25s ease 0s,
                background-color 0.25s ease 0s,
                opacity 0.25s ease 0s;
        }

        &:hover:after,
        &.hovered-img:after {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    .sign-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100px;
        margin-right: -0px;
        margin-bottom: -20px;
    }
}

.lightgallery-element {
    position: relative;
    //margin-bottom: 0.5rem;
    .entry-content & {
        display: inline-block;
    }

    > a {
        .article-item-img:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: transparent;
            transition:
                color 0.25s ease 0s,
                background-color 0.25s ease 0s,
                opacity 0.25s ease 0s;
        }

        &:hover .article-item-img:after,
        &.hovered-img .article-item-img:after,
        &:hover .magnifier-icon {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}

.article-item-title {
    margin-bottom: 0.75rem;
    .mainContainer45 & {
        min-height: 25px;
    }

    a {
        &:hover,
        &.hovered {
            color: $nevada;
        }
    }
    &.style-v2 {
        text-transform: uppercase;
        margin-bottom: 0.25rem;
        h6 {
            margin-bottom: 0;
            font-family: $font-secondary;
            font-size: rem-calc(13);
        }
        span {
            color: $primary-color;
        }
    }

    @include breakpoint(small only) {
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: bold;
        }
        h5,
        h6 {
            line-height: 1.4;
        }
        h2,
        h3,
        h4 {
            font-size: 1.625rem;
        }
    }
}

.article-item-highlight {
    ul {
        //list-style-position: inside;
        margin-left: 0;
        margin-bottom: 1rem;

        li {
            margin-bottom: 0.5rem;
            font-family: $font-secondary;
            font-size: rem-calc(14);
            color: $emperor;
        }
    }

    .article-item-footer & {
        ul {
            li {
                color: $white;
            }
        }
    }

    .articles-list & {
        ul {
            margin-bottom: 1rem;
        }
    }
}

.the-face-section {
    .article-item-info .article-item-highlight {
        padding: 0 2rem;
    }

    .article-item-highlight {
        ul {
            list-style: none;

            li {
                font-size: rem-calc(15);

                &:before {
                    content: "•";
                    color: #d85229;
                    display: inline-block;
                    width: 1em;
                }
            }
        }
    }
}

.sponsored-content {
    background-color: #d85229;
    color: $white;
    font-family: $font-secondary-bold;
    font-weight: 500;
}

.article-item-meta {
    font-family: $font-secondary;
    font-size: rem-calc(12);
    color: rgba($secondary-color, 0.8);
    line-height: 1.2;
    margin-bottom: 0.25rem;

    a {
        color: rgba($nevada, 0.8);

        &:hover {
            color: $primary-color;
        }
    }
}

.meta-author {
    text-transform: uppercase;
}

.article-item-overbox {
    @include breakpoint(large) {
        padding: 2rem;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        color: $white;
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 1%, rgba(31, 31, 31, 1) 101%); /* FF3.6-15 */
        background: -webkit-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 1%,
            rgba(31, 31, 31, 1) 101%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 1%,
            rgba(31, 31, 31, 1) 101%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#1f1f1f', GradientType=0); /* IE6-9 */
        h1 {
            font-size: 3.25rem;
        }
        a {
            color: $white;

            &:hover {
                text-decoration: underline;
            }
        }
        > a {
            display: block;
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 0;
            left: 0;
            top: 0;
        }
        a.lightgallery-trigger {
            z-index: 1;
        }
        .article-item-highlight {
            ul {
                li {
                    color: $white;
                }
            }
        }
        .article-item-meta {
            color: rgba($white, 0.8);
        }
    }

    .article-item-title {
        margin-bottom: 0;
    }
}

.article-item-overbox-inner {
    @include breakpoint(large) {
        z-index: 0;
        max-width: 76%;
    }
}

.hero-area {
}

.views-count-placeholder {
    display: none;

    &.showviews {
        display: inline-block;
    }
}

.views-count {
    font-family: $font-secondary;
    font-size: rem-calc(10);
    color: #898f93;
    line-height: 2;
    display: inline-block;

    &.views-count-placeholder {
        display: none;

        &.showviews {
            display: inline-block;
        }
    }

    i {
        font-size: 1rem;
        vertical-align: middle;
    }
}

.views-count.abs-el {
    position: absolute;
    bottom: 5px;
    right: 10px;
    color: $white;
    z-index: 1;
}

.media-label {
    padding: 0.5rem;
    font-family: $font-secondary-bold;
    font-size: rem-calc(10);
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $white;
    background-color: rgba($limed-spruce, 0.8);
    line-height: 1;
    display: inline-block;

    i {
        font-size: 1.5rem;
        vertical-align: middle;
        margin-right: 0.35rem;
    }

    &:hover {
        background-color: $primary-color;
    }

    .article-item-meta & {
        margin-right: 1rem;
    }
}

.media-label.abs-el {
    position: absolute;
    bottom: 0.75rem;
    left: 0.75rem;
    color: $white;

    .grid-items &,
    .object-item & {
        bottom: 0.6rem;
        left: 0.6rem;
    }

    .object-item & {
        padding: 0.25rem;

        i {
            font-size: 1rem;
        }
    }

    .article-item-overbox-inner & {
        bottom: 1.25rem;
        left: 2rem;
    }
}

.btns-area a.hollow.gray {
    min-width: 150px;
}

.gutters-1x {
    @include xy-gutters(20, "margin", $negative: true);

    > .cell {
        @include xy-gutters(20, "padding", $negative: false);
    }
}

.gutters--1x {
    @include xy-gutters(5, "margin", $negative: true);

    > .cell {
        @include xy-gutters(5, "padding", $negative: false);
    }
}

.gutters-margin-1x {
    @include xy-gutters(20, "margin", $negative: true);

    .cell {
        @include xy-gutters(20, "margin", $negative: false);
    }
}

.html-entity {
    color: $secondary-color;
}

.play-icon,
.abs-over-icon {
    position: absolute;
    font-size: 4.25rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    z-index: 1;
    line-height: 1;

    i:not(.icon-play-arrow) {
        color: $white;
        background: $black;
        border-radius: 1000px;
        overflow: hidden;
    }
    i.icon-play-arrow {
        font-size: 8rem;
        color: rgba($white, 0.8);
        .articles-list.has-separator & {
            font-size: 3.5rem;
        }
        .grid-items & {
            font-size: 3.5rem;
        }
    }

    @include breakpoint(large) {
        font-size: 4.1rem;
    }

    .grid-items & {
        font-size: 2.5rem;
        top: auto;
        left: 0.75rem;
        bottom: 0.75rem;
        -webkit-transform: none;
        transform: none;
    }
}

.el-holder {
    position: relative;
}

.info-holder {
    position: relative;
    height: 100%;
    padding: 1.5rem;
    background-color: $limed-spruce;
    color: $white;

    a {
        color: inherit;

        &:hover {
            color: $primary-color;
        }
    }

    .article-item-meta {
        color: $gray;

        a {
            color: inherit;

            &:hover {
                color: $primary-color;
            }
        }
    }
}

.classified-links {
    list-style: none;
    margin: 0;

    li {
        color: #4d4d4d;
        margin-bottom: 1rem;
        font-size: rem-calc(13);

        &:after {
            border-color: $silver;
        }

        a {
            color: $dusty-gray;

            &:hover {
                color: $primary-color;
            }
        }
    }
}

.classified-link-inner {
    text-align: left;
}

.article-item-footer {
    background-color: $limed-spruce;
    padding: 0.75rem 1.5rem;

    .article-item-meta {
        color: rgba($gray, 0.8);

        a {
            color: inherit;

            &:hover {
                color: $primary-color;
            }
        }
    }
}

.nested-tabs-wrapper {
    .objects-list {
        margin-bottom: 0;
    }

    ul.tabs {
        border: 0;
        font-family: $font-secondary;
        text-align: center;
        margin-bottom: 2rem;
        font-size: 0;

        li {
            float: none;
            display: inline-block;
            margin: 0 3px;

            > a {
                outline: none;
                border-bottom: 2px solid #c8c8c8;
                text-transform: uppercase;

                &:hover {
                    color: $primary-color;
                }

                &:focus,
                &[aria-selected="true"] {
                    border-bottom: 2px solid $black;
                }
            }
        }
    }

    .tabs-content {
        border: 0;

        ul.tabs {
            li {
                margin: 0;
                border-right: 1px solid #999999;

                > a {
                    border-bottom: 0;
                    padding: 0 1rem;

                    &:focus,
                    &[aria-selected="true"] {
                        color: $primary-color;
                    }
                }

                &:last-child {
                    border-right: 0;
                }
            }
        }
    }
}

.media-object-img {
    position: relative;
}

.media-object-for-small {
    @include breakpoint(small only) {
        margin-left: 0;
        margin-right: 0;
        .article-item {
            @include media-object-container;
            margin-bottom: 1.5rem;

            .article-item-img {
                @include media-object-section(0);
                @include flex-align-self(top);
                margin-right: 0.75rem;

                img {
                    max-width: 150px;
                }
            }

            .article-item-info {
                padding-left: 1rem;
                @include media-object-section(0);
            }
        }
    }
}

.load-more {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 3rem;

    a.hollow.gray {
        min-width: 190px;
    }
}

.media-slider {
    margin: 0 0 1rem;
    -webkit-user-select: auto;
    -khtml-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;

    .limelight-player {
        margin-bottom: 0;
    }

    .responsive-embed {
        margin: 0;
        vertical-align: top;
    }

    .slider-item-img {
        text-align: center;

        .sign-icon {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100px;
        }
    }

    img {
        display: inline-block;
    }

    .slick-prev,
    .slick-next {
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        margin-top: -25px;
        transition: all 250ms ease-in-out;
    }
}

.slider-counter {
    padding: 1rem 0;
    text-align: center;
    font-size: 14px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #141414;
    line-height: 1;

    span {
        color: $primary-color;
    }
}

.slider-item-caption,
figcaption {
    padding: 0.5rem;
    padding-right: 60px;
    font-size: 12px;
    color: rgba(#464646, 0.7);
    border-bottom: 2px solid rgba(#8a8a8a, 0.3);
    font-family: $font-secondary;
}
.slider-item-caption {
    h2 {
        padding: 1.1rem 0;
        a {
            color: #ffffff;
        }
    }
}
.entry-article-inner {
    @include xy-gutters(40, "margin", $negative: false);
    @include breakpoint(large) {
        @include xy-gutters(40, "margin", $negative: true);
        > .cell {
            @include xy-gutters(40, "padding", $negative: false);
        }
    }
}

.entry-media {
    margin-bottom: 2.5rem;
}

.entry-title {
    margin-bottom: 2.5rem;

    h1 {
        font-size: rem-calc(46);
    }

    @include breakpoint(small only) {
        //padding: 0 1rem;
        margin-bottom: 1.5rem;
        h1 {
            font-weight: bold;
            font-size: 1.75rem;
        }
    }
}

.classified-article {
    .entry-title {
        margin-bottom: 0;

        h4 {
            font-family: $font-secondary;
            color: $primary-color;
            text-transform: uppercase;
        }
    }

    .entry-related {
        .section-title {
            h4 {
                span {
                    color: #5d686c;
                }
            }
        }
    }
}

.entry-highlight {
    ul {
        //list-style-position: inside;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1rem;

        li {
            margin-bottom: 0.75rem;
            font-family: $font-secondary;
            font-size: rem-calc(14);
            color: $emperor;
        }

        @include breakpoint(large) {
            margin-left: 2rem;
            margin-right: 2rem;
            margin-bottom: 2rem;
            li {
                font-size: 1rem;
            }
        }
    }
}

.entry-content {
    margin-bottom: 1rem;
    padding-bottom: 2rem;
    font-size: rem-calc(18);
    border-bottom: 4px double rgba(#a1a194, 0.4);

    p {
        font-family: inherit;
        font-size: inherit;
    }

    br {
        margin: 0 0 1rem;
        display: block;
        content: "";
    }

    p br {
        margin: 0 0 1rem;
        display: block;
        content: "";
    }

    a {
        color: $primary-color;
    }

    blockquote {
        font-size: rem-calc(18);
        font-style: italic;
        color: $emperor;
        background: $spring-wood;
        margin-bottom: 2rem;
        padding: 2.25rem 3.5rem;
        position: relative;

        &:before {
            display: block;
            content: '"';
            position: absolute;
            top: 10px;
            left: 5px;
            color: $primary-color;
            font-size: 5rem;
            line-height: normal;
        }

        &:after {
            display: block;
            content: '"';
            position: absolute;
            bottom: 10px;
            right: 35px;
            color: $primary-color;
            font-size: 5rem;
            line-height: normal;
        }

        p {
            font-size: 1rem;
            margin-bottom: 1rem;

            &:last-child {
                font-family: $font-secondary;
                margin-bottom: 0;
                font-size: rem-calc(14);
                color: inherit;
                font-style: normal;
            }

            @include breakpoint(medium) {
                font-size: rem-calc(18);
            }
        }
    }
}
.entry-content {
    figure.image {
        br {
            display: none;
            margin: 0;
        }
        figcaption {
            padding: 0.5rem 0;
        }
    }
}
.injected-block {
    @include breakpoint(large) {
        max-width: 80%;
    }
}

.comments-load {
    i {
        margin-left: 0.5rem;
    }
}

button.comments-holder-opened,
button.comments-holder-opened:focus {
    border-color: $primary-color !important;
    color: $primary-color !important;

    &:hover {
        background-color: $primary-color !important;
        color: $white !important;
    }
}

.entry-comments {
    margin-bottom: 2rem;
}

.entry-related,
.entry-comments {
    .section-title h4 {
        font-weight: normal;
    }
}

.entry-tags {
    margin: 0 0 2.5rem;
    padding: 1rem 0 0.5rem;
    border-top: 1px solid rgba(#a1a194, 0.4);
    border-bottom: 1px solid rgba(#a1a194, 0.4);

    span {
        font-family: $font-secondary;
        color: $nevada;
        font-size: 0.75rem;
        display: inline-block;
        vertical-align: middle;
        //margin-bottom: 0.5rem;
    }

    .button {
        margin: 0 0 0.5rem 0.5rem;
        text-transform: uppercase;
        vertical-align: initial;
    }
}

.entry-shares-wrapper {
    margin: 0 0 1rem;

    a {
        text-align: left;
        margin-left: 0.5rem;

        &:hover {
            color: $black;
        }

        i {
            font-size: 1.25rem;
        }
    }

    .entry-meta-footer & {
        a {
            text-align: left;
            margin-left: 0.75rem;
            color: #898f93;

            &:hover {
                color: $black;
            }
        }
    }
}

.entry-meta-footer {
    .entry-meta & {
        padding: 0.75rem 0;
    }
}

.entry-share {
    display: inline-block;
    vertical-align: middle;

    a {
        text-align: left;
        margin-left: 0.5rem;

        &:first-child {
            margin-left: 0;
        }

        i {
            font-size: 1.5rem;
        }
    }

    @include breakpoint(large) {
        a {
            margin-left: 1.25rem;
        }
    }
}

.entry-otpions {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.25rem;
    @include breakpoint(large) {
        margin-left: 2.5rem;
    }

    a {
        color: #898f93;

        &:first-child {
            margin-left: 0;
        }

        i {
            font-size: 1.25rem;
        }
    }
}

.entry-aside-info {
    font-family: $font-secondary;
    font-size: rem-calc(10);
    text-align: center;
    @include breakpoint(large) {
        width: 100px;
    }
}

.updated-ago {
    color: $primary-color;
    border-bottom: 2px solid $black;
    padding-bottom: 0.5rem;

    .entry-meta & {
        padding: 0;
        border: 0;
    }
}

.entry-author {
    padding: 1rem 0;
    font-size: rem-calc(12);
    border-bottom: 3px double rgba(#8a8a8a, 0.3);
    text-transform: uppercase;

    a {
        color: $primary-color;

        &:hover {
            color: #464646;
        }
    }

    .entry-meta & {
        padding: 0;
        border: 0;
    }
}

.entry-author-img {
    padding-bottom: 1rem;
    border-bottom: 1px solid $black;
}

.entry-date {
    padding: 1rem 0;
    border-bottom: 1px solid rgba(#8a8a8a, 0.3);

    span {
        font-family: $font-secondary-bold;
        display: block;
    }

    .entry-meta & {
        padding: 0 0 0.25rem;
        color: #999999;

        span {
            display: inline;
        }
    }
}

.entry-views {
    padding: 1rem 0;
    color: #898f93;
    line-height: 1.5;

    span {
        display: block;
        margin-bottom: 0.25rem;

        i {
            font-size: rem-calc(18);
        }
    }

    .entry-meta & {
        padding: 0;
        border: 0;

        span {
            display: inline;
            margin-bottom: 0;
        }
    }
}

.entry-aside-shares {
    padding-top: 1rem;
}

.entry-share-row {
    text-align: center;
    margin-bottom: 1.25rem;

    a {
        color: #898f93;

        &:hover {
            color: $black;
        }
    }

    i {
        font-size: 1.25rem;
    }
}

.entry-meta {
    padding: 0.25rem;
    font-family: $font-secondary;
    font-size: rem-calc(10);
    border-top: 1px solid #8a8a8a;
}

.author-meta-area {
    margin-bottom: 0;
    border-bottom: 1px solid $black;
    padding: 0 0 0.5rem;

    img {
        max-width: 50px;
    }
}

.area-title {
    a {
        &:hover {
            color: $nevada;
        }
    }
}

.injected-block {
    .block-title {
        margin-bottom: 1rem;
    }

    .item-area-title {
        margin-bottom: 0.25rem;
    }

    h2.item-area-title {
        font-size: 4rem;
        font-family: $font-secondary;
    }

    p {
        font-size: rem-calc(14);
        font-family: $font-secondary;
        color: $dark-gray;
        margin-bottom: 0.5rem;
    }

    ul {
        color: $dark-gray;
        font-family: $font-secondary;
        font-size: rem-calc(15);
        margin-bottom: 0;

        li {
            margin-bottom: 1rem;
        }
    }
}

.with-separator {
    p {
        position: relative;
        padding-bottom: 1rem;

        &:after {
            display: block;
            content: "";
            position: absolute;
            bottom: 0;
            width: 30%;
            height: 1px;
            background-color: $dark-gray;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

.item-area {
    p {
        font-size: rem-calc(15);
        margin-bottom: 1rem;
        padding: 0 0.5rem;
    }
}

.item-area-img {
    margin-bottom: 1rem;

    button {
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }
}

.item-area-title {
    font-family: $font-secondary-bold;

    span {
        color: $primary-color;
    }

    a {
        color: $black;

        &:hover {
            color: $primary-color;
        }
    }
}

.item-area-meta {
    margin-bottom: 0.5rem;

    time {
        display: inline-block;
        vertical-align: middle;
        font-family: $font-secondary-bold;
        font-size: rem-calc(14);
        color: $nevada;

        span {
            color: $primary-color;
        }

        i {
            margin-right: 0.25rem;
        }
    }
}

#news-ticker {
    margin-bottom: 0.75rem;
}

.breadcrumbs-wrapper {
    .breadcrumbs {
        padding-top: 1rem;
        margin-bottom: 1rem;
        border-top: 1px solid $gray;

        li {
            margin: 0.25rem 0;
            @include breakpoint(medium) {
                margin: 0;
            }

            .profile & {
                color: $white;
            }
        }
    }
}

.items-carousel {
    .callout & {
        padding: 1rem 3rem;

        .slick-prev,
        .slick-next {
            background-color: transparent;

            &:hover {
                &:before {
                    color: $primary-color;
                }
            }

            &:before {
                font-size: 1.75rem;
                color: $black;
            }
        }

        .slick-next {
            right: -1rem;
        }

        .slick-prev {
            left: -1rem;
        }
    }
}

.items-carousel-c3,
.items-carousel-c4,
.items-carousel-c5 {
    .callout & {
        padding: 1rem;

        .slick-prev,
        .slick-next {
            background-color: transparent;

            &:hover {
                &:before {
                    color: $primary-color;
                }
            }

            &:before {
                font-size: 1.75rem;
                color: $black;
            }
        }

        .slick-next {
            right: -1rem;
        }

        .slick-prev {
            left: -1rem;
        }
    }
}
.items-carousel-c5 {
    .carousel-item {
        .author-name {
            color: $primary-color;
        }
    }
}
.carousel-item {
    padding: 0 1rem;
}

.columnist-block {
    .author-img {
        margin-bottom: 1rem;
        padding: 0 0.5rem;
        @include breakpoint(medium) {
            padding: 0 0.5rem;
        }
        @include breakpoint(large) {
            padding: 0 1rem;
        }
    }

    .author-name {
        font-family: $font-secondary;
        font-size: rem-calc(13);

        a {
            color: $primary-color;

            &:hover {
                color: $black;
            }
        }
    }
}

.social-field {
    i {
        padding-right: 10px;
    }
}

.date-field {
    font-size: rem-calc(13);
    font-family: $font-secondary;
    color: #898f93;

    &.style-v2 {
        line-height: 1.2;
        font-family: $font-secondary-bold;
        font-size: rem-calc(14);
        color: $emperor;

        span {
            display: block;
        }
    }
}

.about-arabnews {
    color: $emperor;
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 2rem;
    padding-bottom: 1rem;

    .about-arabnews-logo {
        max-width: 150px;
        margin-bottom: 1rem;
    }

    p {
        font-size: rem-calc(18);
    }
}

.pagination-wrapper {
    margin-bottom: 3rem;
    position: relative;

    > span {
        position: absolute;
        top: 50%;
        margin-top: -11px;
        font-family: $font-secondary;
        font-size: rem-calc(14);
        color: #303638;
    }

    .pagination {
        font-family: $font-secondary;
        font-size: 0;

        li {
            border: 1px solid #898f93;
            border-left: 0;
            width: 44px;

            &:first-child {
                border-left: 1px solid #898f93;
                border-radius: 3px 0 0 3px;
            }

            &:last-child {
                border-radius: 0 3px 3px 0;
            }

            &.pager-current {
                @include pagination-item-current();
            }

            &.ellipsis {
                padding: 0.85rem 0.9rem;
                font-family: Arial;

                &:after {
                    padding: 0;
                }
            }

            a {
            }
        }
    }
}

.facets-sort {
    margin-bottom: 2rem;
    border-bottom: 2px solid $white-smoke;
    padding-bottom: 0.5rem;
    @include flex();
    @include flex-align(justify, middle);

    > span {
        @include xy-cell-base(shrink);
        font-family: $font-secondary;
        font-size: rem-calc(11);
        color: $nevada;
        display: inline-block;
        vertical-align: middle;
        margin-right: 1rem;
    }

    > .item-list {
        @include xy-cell-base(auto);
        display: inline-block;
    }

    ul {
        margin-bottom: 0;

        li {
            position: relative;

            img {
                position: absolute;
                right: 8px;
                top: 50%;
                margin-top: -8px;
                z-index: -1;
            }

            a {
                @include button(false, #898f93, $primary-color, $nevada, hollow);
                text-transform: uppercase;
                font-family: $font-secondary;
                font-size: rem-calc(10);
                padding: 0.9em 2.5em;
                margin: 0;
                min-width: 100px;
                color: $nevada;

                &:hover,
                &.active {
                    border-color: $primary-color;
                    color: $primary-color;
                }
            }
        }
    }
}

a.clear-filter {
    @include xy-cell-base(shrink);
    @include button(false, #898f93, $primary-color, $nevada, hollow);
    text-transform: uppercase;
    font-family: $font-secondary;
    font-size: rem-calc(10);
    padding: 0.9em 2.5em;
    margin: 0;
    color: $nevada;
}

.customized-dropdown {
    padding-top: 1.5rem;
    position: relative;

    ul.facetapi-facetapi-links.pagination {
        margin-left: 0;
        list-style: none;

        li {
            &:before {
                display: none;
            }
        }
    }

    .button {
        font-size: 10px;
        color: $nevada;
        text-transform: uppercase;
        text-align: left;
        padding-top: 0.85rem;
        padding-bottom: 0.85rem;

        &:hover,
        &:focus {
            border-color: $primary-color !important;
        }

        &[aria-expanded="true"] {
            border-color: $primary-color;
            border-bottom: 0;

            i {
                color: $primary-color;
            }
        }

        i {
            float: right;
            color: $nevada;
        }
    }

    .dropdown-pane {
        width: 100%;
        border-top: 0;
        //margin-top: -2px;
        padding-top: 0;

        &.is-open {
            border-color: $primary-color;
        }

        .pagination:not(.facetapi-facetapi-links) {
            margin-bottom: 0;

            li {
                display: block;
                overflow: hidden;

                img {
                    display: inline-block;
                    margin-left: 0.5rem;
                }

                a {
                    font-family: $font-secondary;
                    font-size: 10px;
                    color: $nevada;
                    text-transform: uppercase;
                    text-align: left;
                    padding: 0.5rem 0;
                    display: inline-block;
                    float: left;

                    &:hover {
                        background-color: transparent;
                        color: $primary-color;
                    }

                    &.active {
                        color: $primary-color;
                    }
                }
            }
        }
    }
}

ul.pagination.facetapi-facet-im-field-author-name {
}

.area-title + .item-list {
    margin-bottom: 3rem;
}

.item-list {
    ul.facetapi-facetapi-links.pagination {
        margin-bottom: 1.5rem;
        margin-left: 1rem;

        &.facetapi-facetapi-links .facetapi-active {
            margin: 0 0.25rem;
            padding: 0.4rem 0.4rem;
        }

        li {
            color: $primary-color;
            display: block;
            font-family: $font-secondary;
            font-size: rem-calc(12);

            &:before {
                content: "• ";
                color: $nevada;
            }

            a {
                display: inline-block;
                padding: 0.4rem 0.9rem;
                color: $nevada;
            }
        }
    }
}

.item-list .facetapi-limit-link {
    @include button(false, $nevada, $primary-color, $nevada, hollow);
    display: block;
    width: 150px;
    margin: 0 auto;

    &:hover {
        border-color: $primary-color;
        color: $primary-color;
    }
}

.prover-wrapper {
    text-align: center;
}

.prover-arabic-version {
    margin-bottom: 1.5rem;
    direction: rtl;

    h4 {
        font-family: Tahoma;
        font-weight: bold;
    }
}

.prover-english-version {
    margin-bottom: 0.5rem;

    h5 {
        color: $nevada;
    }
}

.daily-prover {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    color: $nevada;

    span {
        display: block;
        text-transform: uppercase;
        color: $primary-color;
    }
}

.label {
    a {
        color: white;
    }
    &.abs-el {
        background-color: #cd2c24;
        text-align: center;
        min-width: 80px;
        font-family: $font-secondary-bold;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
    }
    &.label-bottom {
        bottom: 0;
        right: auto;
        left: 0;
        top: auto;
    }

    &.live {
        //background-color: #CD2C24;
    }

    &.breaking-news {
        //background-color: #CD2C24;
    }

    &.updated {
        background-color: #ffffff;
        color: #d85229;
    }

    &.exclusive {
        background-color: #1a1a1a;
    }

    &.special {
        background-color: #664270;
    }

    &.developing {
        background-color: #ffffff;
        color: #1a1a1a;
    }

    &.analysis {
        background-color: #249598;
    }

    &.business {
        background-color: #c65b37;
    }
}

.circle {
    height: 6px;
    width: 6px;
    background-color: $white;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.3rem;
}

.blink {
    animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.classified-item {
    border: 1px solid $light-gray;
    padding: 2.25rem 2rem;
    background-color: $spring-wood;
    margin-bottom: 2rem;

    a:hover {
        color: $nevada;
    }

    h6 {
        margin-bottom: 1.25rem;
        font-family: $font-secondary;
        color: $primary-color;
        text-transform: uppercase;
    }

    p {
        //color: $black;
        margin: 0;
    }
}

.contact-info {
    border-top: 1px solid #d1d1d1;
    padding-top: 1.5rem;
    font-size: rem-calc(18);
    color: $emperor;

    h6 {
        font-size: inherit;
        margin-bottom: 1rem;
        text-transform: capitalize;
    }

    div {
        span {
            text-transform: capitalize;
        }
    }
}

.showing-results {
    font-family: $font-secondary;
    font-size: rem-calc(14);
    color: #303638;
}

.sort-by-wrapper {
    border-bottom: 1px solid rgba(179, 179, 179, 0.5);
    margin-bottom: 2rem;
    padding-bottom: 0.5rem;
}

.sort-by-fields {
    span {
        /*-webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;*/
        font-family: $font-secondary;
        font-size: 0.6875rem;
        color: $nevada;
        display: inline-block;
        vertical-align: middle;
        margin-right: 1rem;
    }
}

.spelling-suggestions {
    display: none;
}

.vertical-menu-list {
    font-family: $font-secondary;

    li {
        margin-bottom: 1rem;

        a {
            line-height: 1.4;
        }

        &.active {
            a {
                border-radius: 5px;
                background-color: $primary-color;
                color: $white;
            }
        }
    }
}

.menu-list-dropdown-wrapper {
    margin-bottom: 2rem;
    font-family: $font-secondary;
    position: relative;

    .button {
    }

    .dropdown-pane {
        width: 100%;

        ul {
            li {
                a {
                }
            }
        }
    }
}

.select_domain.menu-list-dropdown-wrapper {
    border-top: 4px double #d6d6d6;
    padding-top: 1rem;
    font-size: 1rem;

    .button {
        background-color: $white;
        color: $nevada;
        border: none;
        border-radius: 5px;
        font-size: 1rem;

        &:after {
            border-top-color: $nevada;
            margin-left: 1rem;
        }
    }

    .dropdown-pane {
        background-color: $white;
        padding: 0;

        a {
            color: $nevada;

            &:hover {
                color: $primary-color;
            }
        }

        li.active > a {
            background-color: transparent;
            color: $primary-color;
        }
    }
}

.breaking-news-area,
.special-news-area {
    padding: 0.75rem 0 0.75rem 0;
    margin-bottom: 0;
    @include breakpoint(large) {
        margin-bottom: 0.5rem;
    }
    @include breakpoint(medium only) {
        margin-bottom: 1rem;
    }

    .close-button {
        top: 0;
        bottom: 0;
        color: $white;

        &:hover {
            opacity: 0.6;
        }
    }

    h6 {
        padding-right: 2rem;
        font-size: rem-calc(14);

        a {
            color: $white;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .live {
        background: none;
        font-size: 14px;
        margin-right: 1rem;
        padding: 0;

        i {
            height: 11px;
            width: 11px;
        }

        @include breakpoint(small only) {
            display: block;
            margin-bottom: 0.5rem;
        }
    }
}

.special-news-area {
    background-color: $spring-wood;
    //color: $white;
    .theme1 {
        background-color: transparent;
    }
    .smarticker-news a {
        color: $primary-color;
        font-size: rem-calc(14);
        font-weight: bold;
        padding: 0 0.5rem;

        &:hover {
            color: $black;
        }
    }

    .smarticker-category a {
        padding: 0 0.5rem;
    }
}

#infographic-modal {
    @include breakpoint(small only) {
        max-width: 95%;
        margin: 0 auto;
        padding-top: 3rem;
    }

    img {
        max-height: 100%;
    }
}

.world-cup-wrapper {
}

.dpa_header {
    height: 44px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 1rem;
}

.backgroundColorHighlight {
    color: #fff;
    fill: #303638;
    background-color: #d85229;
}

.dpa_header .dpa_header-logo {
    position: absolute;
    text-align: center;
    background-color: #ecf0e7;
    background-size: 40px 40px;
    -webkit-border-top-right-radius: 100%;
    -webkit-border-bottom-right-radius: 100%;
    -moz-border-radius-topright: 100%;
    -moz-border-radius-bottomright: 100%;
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
    height: 66px;
    transform: translate(0, -11px);
    width: 54px;
}

.dpa_header .dpa_header-logo.dpa_logo-format-png img {
    height: 38px;
    width: auto;
    margin-top: 14px;
}

.dpa_header .dpa_header-title {
    line-height: 44px;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: bold;
}

.page-title .dpa_header .dpa_header-title {
    padding-left: 60px;

    h1 {
        font-size: 1.2rem;
        line-height: 43px;
    }
}

.pacman-area,
.pacman-area *,
.pacman-area:before,
.pacman-area:after {
    box-sizing: initial;
}

.pacman-area {
    margin-bottom: 2rem;

    #pacman-modal & {
        padding-top: 2rem;
    }
}

.device .screen {
}

.wallpaper-item {
    position: relative;
    margin-bottom: 4rem;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint(medium) {
        max-width: none;
    }
}

.device-wrapper {
    padding-bottom: 1.5rem;

    &.mobile {
        max-width: 300px;
        @include breakpoint(medium) {
            max-width: 200px;
        }
    }

    &.mobile {
        @include breakpoint(medium) {
            position: absolute;
            top: 60px;
            right: 0;
        }
    }
}

.follow-field {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.5rem;
    line-height: 1;
    @include breakpoint(large) {
        margin-left: 2.5rem;
    }

    a.twitter-x-button {
        background-color: #1d9bf9;
        border-radius: 20px;
        padding: 2px 10px;
        font-size: 14px;
        color: #fff;
        font-family: isento-medium;
    }
}

.current-print-edition {
    p {
        font-family: $font-secondary;
        font-size: rem-calc(11);
        color: $nevada;
    }
}

.current-print-edition-inner {
    max-width: 250px;
    margin: 0 auto;
    text-align: center;
    padding-top: 2rem;
}

.archive-section {
    .filter-label {
        font-family: $font-secondary;
        color: $nevada;
        margin-bottom: 1rem;
    }

    .filter-date {
        display: inline-block;
        width: auto;
    }
}

.archive-item {
    @include breakpoint(small only) {
        margin-bottom: 1.5rem;
    }
}

.filter-area {
    margin-bottom: 1.5rem;
}

table.responsive th {
    font-weight: bold;
}

table.responsive td,
table.responsive th {
    padding: 9px 10px;
    text-align: left;
}

table.responsive {
    margin-bottom: 0;
}

.pinned {
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    width: 50px;
    overflow: hidden;
    overflow-x: scroll;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
}

.pinned table {
    border-right: none;
    border-left: none;
    width: 100%;
}

.pinned table th,
.pinned table td {
    white-space: normal;
}

.pinned td:last-child {
    border-bottom: 0;
}

div.table-wrapper {
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
    border-right: 1px solid #ccc;
}

div.table-wrapper div.scrollable {
    margin-left: 50px;
}

div.table-wrapper div.scrollable {
    overflow: scroll;
    overflow-y: hidden;
}

table.responsive td,
table.responsive th {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
}

table.responsive tbody td {
    white-space: normal;
}

table.responsive th:first-child,
table.responsive td:first-child,
table.responsive td:first-child,
table.responsive.pinned td {
    display: none;
}

.profile-info-wrapper {
    margin-bottom: 2rem;
}

.profile-person-info-field {
    margin-bottom: 0.75rem;
    font-size: 1.25rem;

    span {
        font-weight: bold;
        margin-right: 0.1rem;
    }
}

.profile-sections-menu {
    padding: 0.5rem;
    margin-bottom: 2rem;
    background-color: $primary-color;

    .button {
        margin: 0.5rem;
        @include breakpoint(large) {
            margin: 0 0.5rem;
        }
    }
}

body.profile {
    .label {
        background-color: #ff0001 !important;
    }

    .smarticker .smarticker-news ul li a {
        color: $white;

        &:hover {
            text-decoration: underline;
        }
    }

    main {
        background-color: $black;
        padding: 1rem 0;
    }

    .profile-info-wrapper {
        position: relative;
    }

    .profile-person-info {
        padding: 1rem;
        color: $white;
        @include breakpoint(medium) {
            height: 70%;
            position: absolute;
            bottom: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.5);
            max-width: 270px;
        }

        .profile-person-info-field {
            margin-bottom: 0.5rem;
            font-size: 1rem;
        }
    }

    .profile-person-name {
        padding: 1rem;
        background: #f10000;
        max-width: 300px;
        color: $white;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
        line-height: 1.2;

        span {
            display: inline-block;
            padding-bottom: 8px;
            word-break: keep-all;
        }

        a {
            color: inherit;

            &:hover {
                color: $black;
            }
        }

        @include breakpoint(medium) {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }
        @include breakpoint(large) {
            font-size: 2.5rem;
        }
    }

    .profile-sections {
        color: $white;

        .section-title {
            color: $white;

            &:after {
                background-color: $white;
            }

            span {
                background-color: $black;
            }
        }
    }

    .profile-sections-menu {
        background-color: transparent;
        padding: 0;

        li {
            margin-right: 0.5rem;

            &:last-child {
                margin-right: 0;
            }

            a.button {
                display: block;
                width: 100%;
                margin: 0;
                background-color: #ff0001;
                line-height: normal;
                margin-bottom: 0.5rem;

                &:hover {
                    background-color: $primary-color;
                }

                @include breakpoint(small only) {
                }
            }
        }
    }

    .section-wrapper .callout {
        background-color: transparent;
        border: 1px solid $white;
        color: $white;

        a {
            color: $white;

            &:hover {
                color: #ff0001;
            }
        }

        .area-title {
            margin-top: -38px;
            text-align: center;

            h3 {
                border-bottom: none;
            }

            span {
                background-color: $black;
                padding: 0 1rem;
            }

            @include breakpoint(small only) {
                text-align: left;
            }
        }

        .play-icon i {
            background-color: #ff0001;
        }

        .abs-over-icon {
            background-color: #ff0001;
            border: 0;
            border-radius: 50%;
            font-size: 0;
            width: 65px;
            height: 65px;
            line-height: 65px;

            i {
                font-size: 2rem;
                line-height: 1;
                vertical-align: middle;
                display: inline-block;
                border-radius: 0;
                background-color: transparent;

                &.icon-soundwave {
                    font-size: 3rem;
                }
            }
        }

        .slick-prev,
        .slick-next {
            &:before {
                font-size: 1.75rem;
                color: #ff0001;
            }
        }
    }

    .profiles-list {
        .profile-person-img {
            img {
                filter: sepia(100%) hue-rotate(-50deg) saturate(300%);
            }
        }

        .profile-person-name {
            bottom: -2rem;
            left: 2rem;
            right: auto;
            top: auto;
        }
    }

    .profile-person-bio {
        padding: 1rem 0 1.5rem;
        color: $white;
        border-bottom: solid 1px rgba($white, 0.7);
        font-size: rem-calc(18);
        margin-bottom: 2rem;
    }
}

.internal-banner {
}

.internal-banner-txt {
    padding: 0.5rem;
    background-color: #f10000;
    @include breakpoint(medium) {
        min-height: 150px;
    }

    p {
        text-align: left;
        margin: 0;
    }
}

.internal-banner-img {
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 100%;
    min-height: 150px;
}

.jw-lightbox-wrapper {
    .slbContentOuter {
        height: 100%;
        width: 99%;
        @include breakpoint(medium) {
            width: 80%;
        }
        @include breakpoint(large) {
            width: 70%;
        }
    }
}

.jw-trigger,
.jw-player-element {
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.magnifier-icon {
    display: inline-block;
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: #ebeae4;
    color: #757575;
    border-radius: 50%;
    line-height: 1;
    padding: 0.85rem;
}

.thesix-list {
    list-style: none;
    margin-left: 0;
    counter-reset: thesix-counter;

    li {
        counter-increment: thesix-counter;

        &:before {
            //content: counter(thesix-counter) ". ";
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 40px;
            height: 47px;
            color: $primary-color;
            font-size: 2.25rem;
            font-weight: bold;
            margin-bottom: 0.75rem;
            margin-right: 0.5rem;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &:nth-child(1):before {
            background-image: url("../img/numbers/one.png");
        }

        &:nth-child(2):before {
            background-image: url("../img/numbers/two.png");
        }

        &:nth-child(3):before {
            background-image: url("../img/numbers/three.png");
        }

        &:nth-child(4):before {
            background-image: url("../img/numbers/four.png");
        }

        &:nth-child(5):before {
            background-image: url("../img/numbers/five.png");
        }

        &:nth-child(6):before {
            background-image: url("../img/numbers/six.png");
        }
    }

    h5 {
        display: inline-block;
        vertical-align: middle;
    }

    p {
        font-size: rem-calc(18);
    }

    .list-item {
        position: relative;
        margin-bottom: 2rem;
    }
}

.events-list-wrapper {
    .section-title {
        h4 {
            font-size: 1.5rem;
        }

        span {
            font-family: $font-primary;
            font-style: italic;
            color: $emperor;
            margin-right: 0.5rem;
            margin-left: 1.125rem;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

.events-list .event-item {
    margin-bottom: 3rem;
}

.event-item-info {
    a {
        &:hover {
            color: inherit;
            opacity: 0.7;
        }
    }
}

.event-location {
    font-family: $font-secondary-bold;
    font-size: 1.125rem;
    color: $primary-color;
}

.event-title {
    h4 {
        font-weight: 700;
        margin-bottom: 0.1rem;
    }
}

.event-description {
    font-size: 1.125rem;
    line-height: 1.4;
}

.event-date {
    font-family: $font-secondary-bold;
    margin-bottom: 0.75rem;

    &:hover {
        cursor: pointer;
        opacity: 0.8;

        i {
            color: $primary-color;
        }
    }

    time {
        color: $nevada;

        span {
            color: $primary-color;

            i {
                color: $black;
                font-size: 21px;
                vertical-align: top;
                margin-right: 0.5rem;
            }
        }
    }
}

.events-calendar-filter {
    font-family: $font-secondary;

    .static-txt {
        color: $nevada;
        @include breakpoint(small only) {
            margin-bottom: 0.5rem;
        }

        span {
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
            line-height: 1.2;
        }

        i {
            display: inline-block;
            vertical-align: middle;
            color: $black;
            font-size: 1.75rem;
            margin-right: 0.5rem;
        }
    }
}

.select-field {
    select {
        margin: 0;
        min-width: 250px;
        background-color: transparent;
        border: 1px solid #898f93;
        color: $dark-gray;
        text-transform: capitalize;
        font-size: 14px;
    }
}

.dates-filter {
    border: 1px solid #d1d1d1;
    padding: 0.75rem 0.5rem;
    @include breakpoint(medium only) {
        margin-top: 1rem;
    }

    .container-inline-date > .form-item {
        display: block;
        margin: 0;
        vertical-align: middle;
        width: 100%;
    }

    #views-exposed-form-ph3-article-teaser-page-1 {
        .views-exposed-widgets.clearfix {
            justify-content: center;
        }
    }

    .views-exposed-widgets.clearfix {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;

        &:before,
        &:after {
            display: none;
        }

        > .views-exposed-widget {
            margin: 1rem 0.5rem;
            width: 100%;
            @include breakpoint(medium) {
                width: 110px;
                margin: 0 1.5rem;
                flex: 0 0 auto;
            }
        }

        > #edit-field-date-value2-wrapper {
            &:after {
                content: "-";
                position: absolute;
                left: 50%;
                top: 50%;
                line-height: 1;
                transform: translate(-50%, -50%);
                margin-left: 0;
            }
        }
    }

    .views-exposed-widget.views-submit-button {
        display: none;
    }

    label {
        line-height: 1.2;
    }

    [type="text"] {
        font-size: 0.75rem;
        padding: 0.25rem 0;
        height: 2rem;
        background-color: transparent;
        border: 0;
        box-shadow: 0 0 0 0;
        border-bottom: 1px solid #898f93;
        margin: 0 !important;
        width: 100% !important;
        display: block !important;
    }

    .description {
        display: none;
    }
}

.v-controls {
    text-align: center;
    margin-bottom: 1rem;

    .v-next,
    .v-prev {
        font-size: 1.125rem;
        cursor: pointer;
        padding: 0.75rem;
        margin: 0 0.25rem;
        display: inline-block;
        border-radius: 50%;
        line-height: 1;
        background-color: $green-white;
        color: $primary-color;

        .block-wrapper-v2 & {
            color: #5e9096;
        }

        &:hover:not([aria-disabled="true"]) {
            background-color: $black;
        }
    }

    .slick-disabled,
    [aria-disabled="true"] {
        background-color: $green-white;
        opacity: 0.6;
        cursor: not-allowed;
    }
}

.copyUrl {
    position: relative;

    &.copied {
        &:hover,
        &:active,
        &:focus {
            &:before,
            &:after {
                display: inline-block;
                text-decoration: none;
            }
        }

        &:before {
            position: absolute;
            z-index: 1000001;
            display: none;
            width: 0;
            height: 0;
            color: rgba(255, 255, 255, 0.8);
            pointer-events: none;
            content: "";
            border: 5px solid transparent;
            top: auto;
            right: 50%;
            bottom: -5px;
            margin-right: -5px;
            border-bottom-color: rgba(255, 255, 255, 0.8);
        }

        &:after {
            position: absolute;
            z-index: 1000000;
            display: none;
            padding: 5px 8px;
            font:
                normal normal 11px/1.5 Helvetica,
                arial,
                nimbussansl,
                liberationsans,
                freesans,
                clean,
                sans-serif,
                "Segoe UI Emoji",
                "Segoe UI Symbol";
            color: #fff;
            text-align: center;
            text-decoration: none;
            text-shadow: none;
            text-transform: none;
            letter-spacing: normal;
            word-wrap: break-word;
            white-space: pre;
            pointer-events: none;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 3px;
            -webkit-font-smoothing: subpixel-antialiased;
            content: "Copied!";
            top: 100%;
            right: 50%;
            margin-top: 5px;
            transform: translateX(50%);
        }
    }
}

.stuck-jw-player .slick-slider .slick-list,
.stuck-jw-player .slick-slider .slick-track {
    transform: none;
}

.short-url-wrapper {
    padding: 0 1.25rem;
    margin-bottom: 1.5rem;
    @include breakpoint(medium) {
        padding: 0;
    }
}

.short-url {
    .short-url-link.copied {
        position: relative;
        //margin-bottom: 1rem;
        &:hover,
        &:active,
        &:focus {
            &:before,
            &:after {
                display: inline-block;
                text-decoration: none;
            }
        }

        &:before {
            position: absolute;
            z-index: 1000001;
            display: none;
            width: 0;
            height: 0;
            color: rgba(255, 255, 255, 0.8);
            pointer-events: none;
            content: "";
            border: 5px solid transparent;
            top: auto;
            right: 50%;
            bottom: -5px;
            margin-right: -5px;
            border-bottom-color: rgba(0, 0, 0, 0.8);
        }

        &:after {
            position: absolute;
            z-index: 1000000;
            display: none;
            padding: 5px 8px;
            font:
                normal normal 11px/1.5 Helvetica,
                arial,
                nimbussansl,
                liberationsans,
                freesans,
                clean,
                sans-serif,
                "Segoe UI Emoji",
                "Segoe UI Symbol";
            color: #fff;
            text-align: center;
            text-decoration: none;
            text-shadow: none;
            text-transform: none;
            letter-spacing: normal;
            word-wrap: break-word;
            white-space: pre;
            pointer-events: none;
            background: rgba(0, 0, 0, 0.8);
            border-radius: 3px;
            -webkit-font-smoothing: subpixel-antialiased;
            content: "Copied!";
            top: 100%;
            right: 50%;
            margin-top: 5px;
            transform: translateX(50%);
        }
    }

    span {
        font-weight: bold;
    }

    p {
        padding: 0.46153846em 0.76923077em;
        background: #ffffff;
        border: 0.07692308em solid #aaa;
        box-shadow: inset 0 1px 5px 0px #aaaaaa;
        display: inline-block;
        color: #000;
        margin: 0;
    }
}

.alphabets-filter {
    border-top: 1px solid #808080;
    border-bottom: 1px solid #808080;
    margin-bottom: 1.5rem;
    color: #87999a;
    font-family: $font-secondary;
    font-size: 1.25rem;
    padding: 1rem 0;
    @include breakpoint(small only) {
        padding: 1rem 0.5rem;
        a {
            width: 7%;
        }
    }
    a {
        text-align: center;
        color: #87999a;
        &:hover,
        &.active {
            color: $primary-color;
        }
    }
}

.quotes-icon {
    padding: 0.75rem 0.75rem 0 0.25rem;
    background: $green-white;
    line-height: 1;
    &.abs-el {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
    .grid-items & {
        padding: 0.5rem 0.5rem 0 0;
        img {
            width: 40px;
        }
    }
}

.article-item-info {
    .the-space-style & {
        .article-item-img {
            float: right;
            img {
                max-width: 60px;
                margin: 0;
            }
        }
    }
    .media-object h3 {
        @include breakpoint(small only) {
            font-size: 1.25rem;
        }
    }
    .media-object-img img {
        @include breakpoint(small only) {
            max-width: 70px;
        }
    }
}

.entry-topper-object {
    .author-name.font-bold {
        margin-bottom: 0.75rem;
        line-height: 1;
        a {
            display: block;
            font-size: 2rem;
            color: $black;
            line-height: 1;
            &:hover {
                color: $primary-color;
            }
        }
    }
    .author-bio {
        font-family: $font-secondary;
        font-size: 1.25rem;
        line-height: 1.2;
        margin-bottom: 0.75rem;
        max-width: 600px;
    }
    .twitter-account {
        font-family: $font-secondary;
    }
    &.media-object.stack-for-small .media-object-section {
        @include breakpoint(small only) {
            text-align: center;
            img {
                max-width: 100%;
                width: auto;
            }
            h1 {
                //text-align: justify;
                font-size: 1.75rem;
                line-height: 1.2;
                img {
                    max-width: 40px;
                }
            }
            .media-object-img img {
                max-width: 90px;
            }
        }
    }
}

.author-bio {
}

.twitter-account {
    a {
        color: $primary-color;
        &:hover {
            color: $black;
        }
    }
}

.years-headline {
    width: 100%;
    padding: 16px 0;
    border-top: 1px solid #1d1d1c;
    border-bottom: 1px solid #1d1d1c;
    margin: 0 0 3rem;
    h2 {
        padding: 0;
        margin: 0 100px;
        font-size: 38px;
        text-align: center;
        font-weight: bolder;
    }
    @include breakpoint(small only) {
        margin: 10px 0;
        padding: 10px 0;
        h2 {
            margin: 0;
            font-size: 16px;
        }
    }
}

.img-45 img {
    width: 210px;
    max-width: 100%;
}

.empty-element-45 {
    width: 210px;
    max-width: 100%;
}

.articles-list {
    &.has-separator {
        .article-item {
            border-bottom: 1px solid $black;
            padding-bottom: 1.5rem;
        }
    }
}

.big-mars-img {
    background-color: $black;
    position: relative;
    //padding: 10rem 0 2rem;
    text-align: center;
    height: 600px;
}

.headline-over {
    position: absolute;
    left: 1rem;
    top: 1rem;
    font-size: rem-calc(70);
    color: $white;
    line-height: 1;
    max-width: 350px;
    font-weight: 900;
    text-align: left;
    @include breakpoint(small only) {
        font-size: 3rem;
        line-height: 3rem;
    }
}

.subheadline-over {
    position: absolute;
    right: 1rem;
    bottom: 10%;
    font-size: rem-calc(23);
    color: $white;
    line-height: 1;
    font-weight: 700;
    max-width: 300px;
    text-align: left;
    @include breakpoint(small only) {
        line-height: 1.6rem;
    }
}

.round-items-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    margin-top: 3rem;
}

.round-item {
    background-color: rgba($white, 0.1);
    border-radius: 50%;
    width: 290px;
    height: 290px;
    text-align: center;
    font-family: $font-secondary;
    font-size: 1.5rem;
    line-height: 1.2;
    position: relative;
    @include breakpoint(1100) {
        &:nth-child(2) {
            margin: 0 -1rem;
        }
        &:nth-child(4) {
            margin: -3rem 0 0;
        }
    }
    span {
        padding: 1.5rem;
        font-size: 90%;
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin: auto;
    }
}

body.to-mars {
    main {
        max-width: 100%;
        color: $white;
        background-color: #637075;
    }
    .news-ticker-area,
    .breadcrumbs-wrapper {
        background-color: $green-white;
    }
    .entry-highlight li,
    .entry-views,
    .entry-share-row,
    .updated-ago,
    .entry-author {
        color: inherit;
        a {
            color: inherit;
            &:hover {
                color: $black;
            }
        }
    }
    .entry-content {
        border: 0;
        padding-bottom: 1rem;
    }
    .section-wrapper {
        //margin-bottom: 2rem;
    }
    .section-title {
        padding-left: 1rem;
        padding-right: 1rem;
        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6 {
            line-height: 1.2;
            color: $white;
            font-family: $font-secondary-bold;
        }
        h3 {
            text-transform: uppercase;
            line-height: 1.5rem;
            font-size: 1.4375rem;
            color: #fff;
            line-height: 1;
            font-weight: 700;
            letter-spacing: -0.05em;
            @include breakpoint(medium) {
                font-size: 1.4375rem;
                line-height: 2rem;
                letter-spacing: -0.05em;
            }
        }
        .subheader {
            font-family: $font-alt;
            font-size: 1.2rem;
            line-height: 1.2rem;
            margin: 0;
            @include breakpoint(medium) {
                font-size: 1.75rem;
                /*max-width: 420px;*/
                line-height: 1.5rem;
            }
        }
    }
}

.abs-txt-content {
    position: absolute;
    z-index: 1;
    top: 30%;
    background-color: rgba($white, 0.1);
    color: $black;
    padding: 1rem;
    padding-left: 160px;
    font-family: $font-secondary;
    font-size: 1rem;
    max-width: 510px;
    line-height: 1.2;
    text-align: left;
    font-weight: normal;
    left: 28%;
    @include breakpoint(medium) {
        left: 39%;
    }
    @include breakpoint(large) {
        left: 44%;
        font-size: 1.5rem;
    }
}

.missile-area {
    position: relative;
    text-align: center;
    //margin-bottom: 3rem;
    align-items: center;
    display: flex;
    justify-content: center;
}

.navy-section-wrapper {
}

.planets-section {
    position: relative;
    margin-bottom: 0 !important;
    .section-title {
        //position: absolute;
        //top: 28%;
    }
    .img-area img {
        width: 100%;
    }
}

.navy-section-inner {
    //background-color: #152332;
}

.satellite-section {
    position: relative;
    margin-bottom: 0 !important;
    .section-title {
        //position: absolute;
        //top: 10%;
    }
    .img-area img {
        width: 100%;
    }
}

.full-section {
    .img-area img {
        width: 100%;
    }
}

.card-items {
    @include breakpoint(medium down) {
        > .cell {
            padding: 0.25rem;
        }
    }

    @include breakpoint(large) {
        > .cell {
            padding: 0.625rem;
        }
    }

    // > .cell {
    //   padding-bottom: 0.625rem;
    //   padding-top: 0.625rem;
    // }

    .card-item {
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        background-color: #000000;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        -webkit-box-shadow: 0 0 5px #000;
        box-shadow: 0 0 5px #000;
        a {
            color: #ffffff;
            font-family: $font-secondary;
        }
    }

    .card-info {
        width: 100%;
        height: 300px;
        background: rgba(0, 0, 0, 0.8);
        opacity: 0;
        transition: all 0.5s;
        padding: 0 1rem;

        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        vertical-align: middle;

        @include breakpoint(medium) {
            height: 350px;
        }
        @include breakpoint(large) {
            height: 400px;
        }

        .name {
            font-size: 30px;
            transform: translateY(40px);
            transition: all 0.7s;
            font-weight: bold;
        }
        .user-details {
            margin-top: 1rem;
            opacity: 0;
            transform: translateY(40px);
            transition: all 0.7s;
            margin-left: 0;
            li {
                list-style: none;
                line-height: 1.5;
            }
        }

        .button.read-more {
            background: transparent;
            border: 1px solid #ffffff;
            padding: 0.5rem 1rem;
            font-size: 12px;

            opacity: 0;
            transform: translateY(40px);
            transition:
                transform 0.7s ease 0s,
                background-color 0s ease 0s,
                color 0s ease 0s,
                opacity 0.25s ease 0s;
            // transition: all 0.7s;
            line-height: 1.5;
            &:hover {
                background-color: #ffffff;
                color: #000000;
            }
        }

        .user-description {
            opacity: 0;
            transform: translateY(40px);
            transition: all 0.7s;
            line-height: 1.5;
        }
    }

    .card-item:hover {
        .card-info {
            opacity: 1;
        }
        .name {
            transform: translateY(0px);
        }
        .user-details {
            transform: translateY(0px);
            transition-delay: 0.3s;
            opacity: 1;
        }

        .user-description,
        .button.read-more {
            transform: translateY(0px);
            transition-delay: 0.6s;
            opacity: 1;
        }
    }
}

.profileimage-listing {
    position: relative;
}

.profileimage-img {
    line-height: 0;
    overflow: hidden;

    img {
        filter: blur(0px);
        transition: filter 0.3s ease-in;
        /*transform: scale(1.1);*/
        width: 100%;
    }
    a {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
    }
}

.profileimage-title {
    z-index: 1;
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s;
    background: rgba(10, 33, 18, 0.8);
    color: white;
    padding: 0;
    margin: 0;

    @include breakpoint(medium down) {
        opacity: 1;
        visibility: hidden;
        /* width: 87%; */
        background: rgba(10, 33, 18, 0.4);
    }

    .info {
        font-family: "Isento-Bold";
        width: 100%;
        font-size: 18px;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        h2 {
            font-family: "Isento-Bold";
            font-size: 24px;
            font-weight: bold;
            text-decoration: none;
            text-align: center;
            letter-spacing: -0.03em;

            @include breakpoint(medium down) {
                font-size: 10px;
                padding: 0;
                margin: 2px 0 2px 0;
            }
        }

        p {
            font-family: "Isento-Medium";
            text-decoration: none;
            text-align: center;
            margin: 0 8px;

            @include breakpoint(medium down) {
                font-size: 8px;
                line-height: 8px;
                margin: 0 4px;
            }
        }
    }

    &:hover {
        color: white;
    }
}

.profileimage-title .info p span {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
}

.profileimage-listing:hover .profileimage-title {
    opacity: 1;
}

.profileimage-listing:hover .profileimage-img img {
    filter: blur(2px);
}

@include breakpoint(medium down) {
    .profileimage-title .info .btns-area {
        padding: 0;
        line-height: 12px;
    }
}

.profileimage-title .info .btns-area button {
    margin: 8px 0 0 0;
    padding: 8px 8px;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    font-family: "Isento-Medium";

    -webkit-appearance: none;
    border: 1px solid #fff;
    border-radius: 0;
    font-size: 0.9rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    /*background-color: #d85229;*/
    color: #fff;

    @include breakpoint(medium down) {
        margin: 4px 0 0 0;
        padding: 2px 4px;
        font-size: 8px;
    }
}
.profileimage-title .info .btns-area .button:hover {
    color: #fff;
}

.national-day-img {
    position: relative;
    .virus {
        width: 50px;
        height: 50px;
        background-position: center;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../img/saudi-national-day/virus.png);
        background-repeat: no-repeat;
        visibility: hidden;
    }
    .virus-1 {
        top: 60%;
        left: 18%;
        width: 80px;
        height: 80px;
        visibility: visible;
        animation: heartbeat 3s infinite;
        animation-delay: 5s;
    }
    .virus-2 {
        top: 80%;
        left: 10%;
        width: 50px;
        height: 50px;
        visibility: visible;
        animation: heartbeat 2s infinite;
        animation-delay: 5s;
    }
    .virus-3 {
        top: 20%;
        left: 10%;
        width: 60px;
        height: 60px;
        visibility: visible;
        animation: heartbeat 2s infinite;
        animation-delay: 5s;
    }
    .virus-4 {
        top: 47%;
        left: 24%;
        width: 60px;
        height: 60px;
        visibility: visible;
        animation: heartbeat 2s infinite;
        animation-delay: 5s;
    }
}

.big-header-hightlight {
    padding: 20px 0;
    @include breakpoint(medium down) {
        padding: 5px 0;
    }
    h1 {
        font-weight: bold;
        @include breakpoint(medium down) {
            // margin: 1rem 0;
            font-size: 1.625rem;
            font-family: "Playfair Display";
            font-weight: bold;
            width: 98%;
            margin: 0 auto;
            line-height: 1.1;
        }
        @include breakpoint(large) {
            // font-size: 90px;
            // width: 95%;
            font-size: 4em;
            font-family: "Playfair Display";
            font-weight: bold;
            width: 70%;
            margin: 0 auto;
            line-height: 1;
        }
    }
    p {
        margin-top: 10px;
        margin-bottom: 0;
        @include breakpoint(small only) {
            font-size: 1rem;
            line-height: 1.2;
            margin-top: 20px;
            margin: 4px 0 10px 0;
        }
        @include breakpoint(large) {
            // font-size: 30px;
            // margin-top: 20px;
            font-size: 1.5rem;
            margin-top: 20px;
            line-height: 30px;
            margin: 10px 0 10px 0;
        }
    }
}

// .covid-figures{
//   position: relative;
//   padding: 0;
//   margin: 0;
// }

.covid19Figures {
    position: relative;
    padding: 0;
    margin: 0;
    img {
        position: relative;
        z-index: 0;
    }
}

.nationalDayBanner {
    padding: 10px 0 20px 0;
}
.rippleAnimation {
    width: 200px;
    height: 200px;
    position: absolute;
    z-index: 99;
    top: 29%;
    left: 36%;
    .ripple {
        background-color: #fff;
        opacity: 70%;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        position: relative;
        animation: ripple 3s linear infinite;
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 50%;
            animation: ripple 3s linear infinite 1s;
        }

        &:after {
            animation: ripple 3s linear infinite 2s;
        }
    }
}
/* BEGIN ANIMATION */
@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0.7rem rgba(255, 255, 255, 0.2);
    }
    100% {
        box-shadow: 0 0 0 8rem rgba(255, 255, 255, 0);
    }
}

@keyframes heartbeat {
    0% {
        transform: scale(0.75);
    }
    20% {
        transform: scale(1);
    }
    40% {
        transform: scale(0.75);
    }
    60% {
        transform: scale(1);
    }
    80% {
        transform: scale(0.75);
    }
    100% {
        transform: scale(0.75);
    }
}

/* END ANIMATION */

.text-highlight {
    p {
        padding: 1rem;
        margin-bottom: 0;
        font-size: 1.1rem;
        @include breakpoint(large) {
            border-left: 1px solid #cdcfcc;
        }
    }
}

.yougov-logo {
    .sidebar-wrap & {
        @include breakpoint(medium down) {
            width: 350px;
            margin: 2rem auto;
        }
    }
}

blockquote.quote-v1 {
    background-color: transparent;
    text-align: center;
    padding: 2rem;
    &:before,
    &:after {
        content: "";
    }
    p {
        position: relative;

        font-size: 1.125rem;
        font-style: italic;
        color: #555555;
        margin-bottom: 2rem;
        padding: 1rem;
        @include breakpoint(medium) {
            font-size: rem-calc(18);
        }

        &:before {
            left: -1rem;
            top: 0;
            font-family: "icomoon" !important;
            font-size: 2rem;
            position: absolute;
            content: "\e925";
        }
        &:after {
            right: 0;
            bottom: 0;
            font-family: "icomoon" !important;
            font-size: 2rem;
            position: absolute;
            content: "\e924";
        }
    }

    .author {
        display: flex;
        justify-content: center;
        align-items: center;

        h5 {
            display: inline-block;
            font-weight: bold;
            font-style: italic;
            span {
                display: block;
                font-weight: normal;
            }
        }
        .author-img {
            display: inline-block;
            border-radius: 50%;
            width: 100px;
            height: 100px;
            overflow: hidden;
            margin-left: 1rem;
        }
    }
}

.researchers-block.slick-loading-area {
    height: 150px;
}

.custom-tooltip {
    border-bottom: 0;
}
.print-edition-grid {
    .print-title {
        flex: 1;
        @include breakpoint(medium) {
            font-size: rem-calc(14);
        }
    }
    > .cell {
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
        &:last-child {
            margin-right: 0;
        }
    }
}

.slick-arrow-v1 {
    .slick-arrow {
        cursor: pointer;
        padding: 0.75rem;
        margin: 0 0.25rem;
        display: inline-block;
        border-radius: 50%;
        line-height: 1;
        background-color: #ecf0e7;
        color: #d85229;
        &::before {
            color: #d85229;
        }
    }
}

.infographics-slider {
    .article-item-img {
        border: 5px solid #f7f9f4;
    }
    a {
        &:focus {
            outline: 0;
        }
    }
    .slick-arrow {
        border: 1px solid #bbbdb8;
        &::before {
            color: #bbbdb8;
        }
    }
}

.slick-list {
    .opinion-slider &,
    .infographics-slider &,
    .carousel-custom-width & {
        width: 85%;
        margin: 0 auto;
    }
}
.opinion-slider {
    .media-object {
        margin-bottom: 0;
    }
    .media-object-img {
        margin-right: 2rem;
    }
}

// .hero-grid-items{
//   img{
//     height: 500px;
//   }
// }
.yougov-slider {
    position: relative;
    .slick-list {
        @include breakpoint(medium up) {
            overflow: visible;
        }
    }
    .article-item,
    .article-item-img {
        margin-bottom: 0;
        text-align: center;
    }
    .article-item-img {
        > a {
            &:before {
                background-color: rgba(44, 10, 111, 0.55);
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
            }
        }
        &:hover {
            > a:before {
                background-color: transparent;
            }
        }
    }
    .slick-arrow {
        background: rgba(0, 0, 0, 0.4);
        bottom: 0;
        height: 100%;
    }
    .slick-next {
        right: -5px;
    }
    .slick-disabled {
        visibility: hidden;
    }
    .slick-slide[aria-hidden="true"] {
        position: relative;
        &::before {
            content: "";
            background: rgba(0, 0, 0, 0.65);
            z-index: 99;
            position: absolute;
            height: 100%;
            width: 100%;
        }
    }
}

.deepdive-slider {
    .cell {
        padding-left: 10px;
        padding-right: 10px;
        .article-item {
            min-height: 280px;
            height: 50%;
            overflow: hidden;
            @include breakpoint(small only) {
                min-height: 180px;
                height: 50%;
                overflow: hidden;
            }
            .article-item-title {
                margin: 10px 0 0 0;
                h5 {
                    font-size: 1.8rem;
                    font-family: $font-secondary-bold;
                    text-transform: uppercase;
                    line-height: 1.6rem;
                    letter-spacing: -0.6px;
                    a {
                        color: #fff;
                    }
                    @include breakpoint(small only) {
                        font-size: 14px;
                        line-height: 14px;
                    }
                }
            }
            .article-item-meta {
                display: none;
            }
        }
    }
}

.research-papers-carousel {
    .slick-list {
        .slider-item {
            .slider-item-img {
                width: 80%;
                margin: 10% auto 0 auto;
            }
            padding: 0;
            opacity: 0.6;
            .slider-item-caption {
                padding: 5px 0 0 0;
                margin: 0 auto;
                border: none;
                width: 80%;
                h2 {
                    font-size: 16px;
                    line-height: 14px;
                    letter-spacing: -0.6px;
                    padding: 0;
                    margin: 0;
                    font-family: $font-secondary-bold;
                    text-transform: uppercase;
                    @include breakpoint(small only) {
                        font-size: 14px;
                    }
                    a {
                        font-size: 16px;
                        color: #000000;
                        @include breakpoint(small only) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .slick-center {
            .slider-item {
                opacity: 1;
                transform: scale(1.1);
                padding-top: 10px;
                padding-bottom: 20px;
                @include breakpoint(small only) {
                    transform: scale(1);
                }
            }
            .slider-item-img {
                width: 100%;
                margin-top: 0;
            }
            .slider-item-caption {
                width: 100%;
            }
        }
    }
    .slick-next {
        margin-right: -60px;
        @include breakpoint(small only) {
            margin-right: 0;
        }
    }
    .slick-prev {
        margin-left: -60px;
        @include breakpoint(small only) {
            margin-left: 0;
        }
    }
}

.custom-bg-v1 {
    background-color: #483f5c;
}
.custom-bg-v2 {
    background-color: #707a7c;
}

.slick-custom-v1 {
    position: relative;
    .slider-item-caption {
        background: rgba(89, 120, 104, 0.95);
        color: #ffffff;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-right: 0;
        text-align: center;
        h1 {
            font-family: $font-primary;
            font-weight: bold;
            padding: 1.1rem 0;
            @include breakpoint(medium only) {
                font-size: 22px;
                padding: 0.5rem 0;
            }
            @include breakpoint(small only) {
                font-size: 16px;
                padding: 0;
            }
        }
    }
    &.media-slider {
        margin-bottom: 0;
    }
    .slick-arrow {
        border-radius: 50%;
        border: 2px solid #ffffff;
        background: transparent;
        bottom: 2rem;
        top: inherit;
        position: absolute;
        @include breakpoint(small only) {
            width: 30px;
            height: 30px;

            &:before,
            &:after {
                font-size: 1rem;
            }
        }
    }
    @include breakpoint(large only) {
        .slick-next {
            right: 15rem;
        }
        .slick-prev {
            left: 15rem;
        }
    }
}

.frankly-speaking,
.page-abuhilalain {
    background-color: #000000;
    color: #ffffff;
    .block-title.style-v3 span,
    .section-title.style-v3 span,
    .page-title.style-v3 span {
        background-color: #000000;
    }
    .page-title a,
    a,
    .dropdown-wrapper .button,
    .current-date {
        color: #ffffff;
    }

    .nav-container {
        border-top: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        > ul > li > a {
            color: #ffffff;
        }
    }

    .searchbox-icon {
        background: #000000;
        color: #ffffff;
    }

    .meta-category a {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: #39a5d6;
        font-family: $font-secondary-bold;
        &:hover {
            color: #39a4d6;
        }
    }
    .middle-icon {
        background-color: rgba(0, 0, 0, 0.72);
        text-align: center;
        border: 0;
        border-radius: 50%;
        font-size: 0;
        width: 50px;
        height: 50px;
        position: absolute;
        font-size: 2.8rem;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        margin: 0 auto;
        z-index: 1;
        line-height: 1;
        i {
            margin-left: 6px;
            font-size: 1.5rem;
            line-height: 1;
            vertical-align: middle;
            display: inline-block;
            border-radius: 0;
            background-color: transparent;
            color: #ffffff;
        }
    }
    .article-item-title {
        h4,
        h5 {
            font-weight: bold;
        }
    }
}

.frankly-speaking {
    .franklyspeaking-description {
        font-size: 1.5rem;
        text-align: center;
    }
    h5.emperor-font-color {
        color: #ccc;
    }
    .block-title {
        color: #ccc;
    }
    #sticky-header {
        &.stuck-header {
            background: #000;
            box-shadow: 0 1px 10px 0 #3f3d3d;
        }
    }
    #area-to-stick {
        background: #000;
    }
    .main-header-before {
        background: #000;
    }
    #sticky-header .logo img,
    #area-to-stick .logo img {
        filter: brightness(0) invert(1);
    }
    #sticky-header .searchbox-icon,
    #sticky-header .searchbox-submit {
        background: #000;
    }
}
/*****BUSINESS******/

body.business-body {
    background: #e7ede2;
}
#sticky-header,
#area-to-sticky {
    .logo-business {
        img {
            margin-top: 0;
            @include breakpoint(large) {
                max-width: 130px;
            }
        }
    }
}

.business-header {
    .logo-business {
        // padding-top: 3rem;
        padding: 1rem 0;
        @include breakpoint(large) {
            border-top: 1px solid #3c494c;
        }
        img {
            max-width: 300px;
            @include breakpoint(medium) {
                max-width: 400px;
            }
        }
    }
}

.business-indicator {
    z-index: 2;
    background: #ecf0e7;
    padding: 0.5rem 0;
    margin-bottom: 0;
    box-shadow: 0px 4px 2px -2px #cccccc;
}

.business-wrap {
    .business-side-logo {
        margin-bottom: 1rem;
    }
    .sidebar-wrap {
        .block-title {
            h3 {
                font-style: italic;
            }
        }
    }
    #sticky-header .nav-container ul li a {
        @include breakpoint(large) {
            font-size: rem-calc(12);
        }
    }
    #sticky-header .social-btn {
        @include breakpoint(large) {
            font-size: rem-calc(14);
            margin: 0 0 0 0.4rem;
        }
    }
}
/*overwrite business.css*/
div.tickerContainer div.calendarIcon a {
    margin: 5px !important;
    text-align: left !important;
}

.tickerContainer {
    .calendarIcon {
        width: 7rem !important;
        @include breakpoint(medium) {
            width: 7.5rem !important;
        }
        @include breakpoint(large) {
            width: 6rem !important;
        }

        span {
            text-transform: uppercase;
            font-family: "Isento-Medium";
            background-color: #eb6f45;
            color: #ffffff;
            padding: 2px 2px 2px 5px;
            font-size: 12px;
            position: relative;
            &:after {
                content: "";
                width: 0;
                height: 0;
                border-top: 9px solid transparent;
                border-bottom: 9px solid transparent;
                border-left: 10px solid #eb6f45;
                position: absolute;
                right: -10px;
                top: 0px;
            }
        }
    }

    .arrowsNav {
        width: 3rem !important;

        @include breakpoint(medium) {
            width: 3rem !important;
        }
        @include breakpoint(large) {
            width: 2.5rem !important;
        }
    }
}

.searchBox {
    // max-width: 40rem;
    margin: 2rem auto 0;
    .form-submit {
        padding: 0.7rem 2rem;
    }
}

div.modalBusiness {
    height: auto;
}

div.eventCalendar .aplusholdBM,
div.eventCalendar .aplusholdBM h2,
div.eventCalendar .aplusholdBM .table .row {
    @include breakpoint(small only) {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

/*end overwrite business.css*/

.block-custom-business {
    @include breakpoint(small only) {
        padding: 0 0.5rem;
    }
    .block-title {
        color: #4d9396;
        border-bottom: 2px solid #4d9396;
        date {
            font-family: "Isento-Medium";
            font-size: rem-calc(12);
            color: #8a8f92;
        }
        h5 {
            text-transform: uppercase;
            font-family: "Isento-Bold";
            font-size: rem-calc(14);
            font-weight: bold;
        }
    }
}

.custom-tabs {
    .head-tabs {
        .tabs {
            border-bottom: 1px solid #c65b37;
            text-transform: uppercase;
            margin-bottom: 1rem;
            li {
                &.is-active,
                &:hover {
                    a {
                        background: #c65b37;
                        color: #ffffff;
                    }
                }
            }
        }
    }
    ul {
        &.tabs {
            border: 0;
            font-family: "Isento-Medium";
            font-size: 0;
            a {
                outline: none;
            }
        }
    }
    .tabs-content {
        border: none;
        .sidebar-wrap & {
            //background-color: #ffffff;
        }
        .tabs {
            li {
                a {
                    padding: 0.3rem;
                    font-size: 12px;
                }
                &.is-active,
                &:hover {
                    a {
                        color: #c65b37;
                    }
                }
            }
        }
    }
    .custom-buttons {
        .btn-date {
            background-color: #cedfdf;
            color: #418b90;
            padding: 0.4rem 0.2rem;
            font-size: 14px;
            font-family: "Isento-Medium";
            &.btn-active,
            &:hover {
                background-color: #c55a37;
                color: #ffffff;
            }
        }
        .btn-more {
            float: right;
            font-family: "Isento-Medium";
            font-size: rem-calc(12);
            color: #418b90;
            text-transform: uppercase;
            i {
                background-color: #418b90;
                border-radius: 50%;
                padding: 0.3rem;
                color: #ffffff;
                font-size: 11px;
            }
        }
    }
}

.market-block {
    font-family: "Isento-Medium";
    font-size: rem-calc(12);
    thead {
        border-bottom: 1px solid #52999d;
        .sidebar-wrap & {
            background-color: #f2f3f0;
        }
        th {
            text-transform: uppercase;
            &:last-child {
                text-align: center;
            }
        }
    }
    tbody {
        background-color: #f2f3f0;
        tr {
            border-bottom: 1px solid #52999d;
            td {
                text-transform: uppercase;
            }
            td:nth-child(odd) {
                color: #52999d;
            }
            td:last-child {
                text-align: center;
            }
            &:nth-child(even) {
                background-color: transparent;
                border-bottom: 1px solid #52999d;
            }
        }
        .font-red {
            color: #a71c10;
        }
    }
}

.editor-item {
    margin-bottom: 1rem;
    img {
        margin-bottom: 0.5rem;
    }
    .editor-name {
        font-size: rem-calc(16);
        font-weight: bold;
    }
    .editor-position,
    .editor-company {
        font-size: rem-calc(14);
    }
}

/* BEGIN STYLING FOR INDICES, FUTURE, INDICATORS BLOCK (from email) */
.indicatorsContainer {
    margin: 0 0 20px 0;
}
.indicatorsContainer div {
    padding: 0;
    margin: 0;
}
.indicatorsContainer .futures-data-ticker {
    margin: 0 auto 0 auto;
}
.indicatorsContainer .futures-data-ticker > .holder {
    background: #fff;
    font-family: Isento-Medium;
    //modified
    //height: 44px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.indicatorsContainer .futures-data-ticker .holder .head {
    background: #4c9398;
    color: #fff;
    font-weight: bold;
    float: left;
    font-size: 14px;
    //height: 44px;
    //line-height: 41px;
    position: relative;
    padding: 0 30px 0 10px;
    /*max-width: 90px;*/
    //width: 10%; //mofied
    overflow: hidden;
    text-align: center;

    align-self: stretch;
    display: flex;
    align-items: center;
}

.indicatorsContainer .futures-data-ticker .holder .head:after {
    width: 0;
    height: 100%; //modified
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    content: "";
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
    border-right: 22px solid #fff;
    border-right-width: 10px;
    //added
    bottom: 0;
}

.indicatorsContainer .futures-data-ticker ul,
.futures-data-ticker li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
// added
.indicatorsContainer .futures-data-ticker ul {
    display: flex;
    flex: 1;
    justify-content: space-between;
}
//end added
.indicatorsContainer .futures-data-ticker li {
    //float: left;
    padding: 7px 7px 0 6px;
    width: 100%;
    //height: 38px;
    text-align: center;
    line-height: normal;
    font-size: 12px;
}

.indicatorsContainer .futures-data-ticker li + li {
    border-left: 1px solid #4c9398;
}
.indicatorsContainer .futures-data-ticker li .name-value {
    display: inline-block;
}

.indicatorsContainer .futures-data-ticker li .holder {
    /* margin-top: -3px;*/
}

.indicatorsContainer .futures-data-ticker li .green {
    color: #178817;
}
.futures-data-ticker li .red {
    color: #f20b0b;
}
.indicatorsContainer .futures-data-ticker li .green:before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #178817;
    width: 0;
    height: 0;
    margin: 0 3px 0 0;
    position: relative;
    top: -2px;
}
.indicatorsContainer .futures-data-ticker li .red:before {
    content: "";
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #f20b0b;
    width: 0;
    height: 0;
    margin: 0 3px 0 0;
    position: relative;
    top: -2px;
}
.indicatorsContainer .FuturesDataClose {
    background: #4c9398;
    float: right;
    font-size: 14px;
    //height: 44px;
    //width: 5%;
    //line-height: 48px;
    position: relative;
    padding: 0 5px 0 15px;

    align-self: stretch;
    display: flex;
    align-items: center;
}

.indicatorsContainer .FuturesDataClose:after {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    content: "";
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 22px solid #fff;
    border-left-width: 10px;
}
.indicatorsContainer .FuturesDataClose .close {
    color: #fff;
}

@media only screen and (max-width: 990px) {
    .indicatorsContainer .futures-data-ticker li {
        font-size: 10px;
        padding-top: 2px;
    }
    .indicatorsContainer .futures-data-ticker .holder .head {
        padding-left: 2px;
    }
    .indicatorsContainer .FuturesDataClose {
        padding-right: 3px;
    }
}

@media only screen and (max-width: 500px) {
    .indicatorsContainer .futures-data-ticker li {
        font-size: 9px;
        padding-top: 1px;
    }
    .indicatorsContainer .futures-data-ticker .holder .head {
        font-size: 10px;
    }
    .indicatorsContainer .FuturesDataClose .close {
        font-size: 18px;
    }
    .indicatorsContainer .futures-data-ticker li .red:before {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #f20b0b;
    }
    .indicatorsContainer .futures-data-ticker li .green:before {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid #178817;
    }
}
@media only screen and (max-width: 450px) {
    .indicatorsContainer .futures-data-ticker .holder .head {
        font-size: 8px; /*width:12%;*/
    }
    .indicatorsContainer .futures-data-ticker li {
        font-size: 8px;
        padding: 0 2px; /*width:16%;*/
    }
    .indicatorsContainer .FuturesDataClose {
        /* width:6%; */
    }
}

/* END STYLING FOR INDICES, FUTURE, INDICATORS BLOCK */
.ray-header,
.full-header {
    position: relative;
    //padding: 10rem 0 2rem;
    text-align: center;
    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: center;
    }
}

.ray-content {
    h4 {
        color: #555555;
    }
}
.ray-content-highlight {
    h3 {
        color: #030303;
        margin-left: 1.25rem;
    }
    border-top: 1px solid #1f211e;
    border-bottom: 1px solid #1f211e;
    padding-top: 1rem;
    margin-bottom: 1rem;
    .ray-highlight {
        margin: 2rem auto;
        li {
            background-color: #f8fbf4;
            color: #555555;
            font-weight: bold;
            font-family: $font-secondary-bold;
            margin: 0.4rem 0;
            padding: 0.5rem 0 0 0.5rem;
            list-style-position: inside;
        }
    }
}
.podcast-iframe {
    margin: 1rem 0;
}
.podcast-list-channel {
    margin-top: -2rem;
    @include breakpoint(large) {
        margin-top: -4rem;
    }
    ul {
        margin-left: 0;
        display: flex;
        justify-content: space-between;
        @include breakpoint(large) {
            width: 75%;
            float: right;
        }

        li {
            list-style: none;
            display: inline-block;

            img {
                width: 150px;
                @include breakpoint(medium) {
                    width: 100px;
                }
                @include breakpoint(large) {
                    width: 200px;
                }
            }
        }
    }
}

.podcast-wrap {
    display: inline-block;
    color: $white;
    font-family: $font-secondary;
    width: 100%;
    h5 {
        font-family: $font-secondary;
        font-size: rem-calc(14);
    }

    a {
        color: $white;
    }
    .episode-number {
        font-size: rem-calc(12);
    }
}
.podcast-artist {
    padding-bottom: 1rem;
}
.btn-latest-episode {
    padding: 2px 4px 2px 10px;
    background-color: #6d7574;
}
.podcast-details {
    margin: 0.5rem 0;
}
.podcast-episodes {
    background-color: #3c494b;
    height: 100%;
    padding: 1rem;
    ul {
        margin-left: 0;
        li {
            list-style: none;
            border-bottom: 1px solid #7b877d;
            padding-bottom: 0.5rem;
            margin-bottom: 0.5rem;
            .sked {
                width: 8rem;
                display: inline-block;
            }
        }
    }
}
.radio-show-styled {
    position: relative;
    background-color: #253032;
    .radio-show-heading {
        top: 0;
        left: 0;
        right: 0;
        padding: 1rem;

        width: 100%;
        height: 30rem;
        @include breakpoint(medium) {
            height: 30rem;
        }
        @include breakpoint(large) {
            height: 50rem;
        }
        background-position: left;
        background-size: cover;
        background-repeat: no-repeat;
    }
    h1 {
        font-weight: bold;
        font-size: rem-calc(20);
        @include breakpoint(large) {
            font-size: rem-calc(30);
            line-height: 1.5;
        }
    }
}

.grid-podcast {
    background-color: #a4ab98;
}

.podcast-content {
    .podcastItem {
        background-color: #edefe7;
    }
}

.podcast-channels {
    margin-left: 0;
    li {
        width: 9rem;
        list-style: none;
        display: inline-block;
        margin-right: 0.5rem;
        @include breakpoint(medium down) {
            margin-bottom: 0.5rem;
        }
    }
}

.lifestyle-grid {
    .article-item {
        flex-flow: column;
        height: 100%;
    }
    .btn-wrap {
        margin-bottom: 2.5rem;
    }
}

div.loading {
    clear: both;
    width: 100%;
}
div.loading img {
    margin: 0 auto;
    display: block;
    width: 60px;
}

body.tokyo-olympics {
    .olympics2021-full {
        width: 100%;
        max-width: 100%;
        background-color: #1a7a32;
    }
    .olympics2021 {
        background-color: #1a7a32;
        color: #fff;
        position: relative;
        max-width: 71.5rem;
        margin: 0 auto;
        .olympics-content {
            padding: 0 20px;
            width: 100%;
        }
        .olypics-torch {
            position: relative;
            .stick {
                transform: translatex(100px) translatey(360px);
            }
            .flame {
                mix-blend-mode: screen;
            }
        }

        .olypics-torch svg {
            z-index: 10;
            height: 100%;
        }
        h1 {
            text-align: center;
            font-family:
                playfair display,
                serif,
                Helvetica,
                Roboto,
                Arial,
                sans-serif;
            font-size: 4.375rem;
            font-weight: 900;
            margin-bottom: 25px;
        }
        p {
            font-size: 18px;
            font-weight: bold;
        }
        .olympics-interactive {
            position: relative;
        }
        .athlete-card {
            position: absolute;
            width: 35%;
            top: 30%;
            left: 35%;
            visibility: hidden;
            .closeBtn {
                padding: 2px 4px;
                margin: 0;
                display: block;
                position: absolute;
                top: 6px;
                right: 10px;
                color: #000;
                background-color: #fff;
                font-family: "arial";
            }
        }
        .athlete-card-info {
            position: relative;
            .athlete-card-details {
                position: absolute;
                height: 100%;
                top: 0;
                left: 0;
                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: "";
                    width: 100%;
                    height: 100%;
                    background-color: #000;
                    opacity: 0.7;
                    z-index: 1;
                }
            }
            .athlete-card-details img {
                position: relative;
                height: 100%;
                z-index: 2;
            }

            .torchContainer {
                position: absolute;
                top: 10%;
                width: 300px;
                height: 700px;
                left: 50%;
                margin-left: -180px;
                overflow: hidden;
            }
        }
        .athlete-card-info a:hover img {
            filter: brightness(0.7);
        }
        .athlete-card-info .plusIcon {
            position: absolute;
            top: 1%;
            right: 2%;
            z-index: 3;
            color: #fff;
            font-size: 50px;
        }
        .torchContainer .olypics-torch .anim-torch {
            width: 300px;
            height: 700px;
        }
        .olypics-torch .torch-top {
            position: absolute;
            top: 0;
            left: 0;
            top: 35%;
            z-index: 9;
        }

        .social-icons {
            position: relative;
            width: 100%;
            text-align: center;
        }
        .social-icons ul {
            position: relative;
            padding: 0;
            margin: 0;
            list-style: none;
        }
        .social-icons ul li {
            display: inline-block;
        }
        .social-icons ul li a {
            font-size: 25px;
            padding: 2px 6px;
            color: #fff;
        }
        .social-icons ul li a:hover {
            color: #ccc;
        }
        #bttBtn {
            display: none;
            /* Hidden by default */
            position: fixed;
            /* Fixed/sticky position */
            bottom: 75px;
            right: 20px;
            z-index: 99;
            /* Make sure it does not overlap */
            border: none;
            /* Remove borders */
            outline: none;
            /* Remove outline */
            background-color: #ccc;
            /* Set a background color */
            color: #000;
            /* Text color */
            cursor: pointer;
            /* Add a mouse pointer on hover */
            padding: 8px;
            /* Some padding */
            border-radius: 2px;
            /* Rounded corners */
            font-size: 18px;
            /* Increase font size */
        }
    }

    @media only screen and (max-width: 1000px) {
        .olympics2021 .torchContainer {
            position: absolute;
            top: -5%;
            width: 300px;
            height: 400px;
            left: 50%;
            margin-left: -180px;
            overflow: hidden;
        }
    }
    @media only screen and (max-width: 600px) {
        .olympics2021 .torchContainer {
            position: absolute;
            top: -5%;
            width: 170px;
            height: 380px;
            left: 50%;
            margin-left: -125px;
            overflow: hidden;
        }
        .olympics2021 .athlete-card {
            width: 90%;
            top: 40px;
            left: 5%;
        }
        .olympics2021 h1 {
            font-size: 2.375rem;
        }
    }
    .entry-content {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }
}

.order-swap {
    > div {
        &:first-child {
            order: 1;
            @include breakpoint(large) {
                order: 2;
            }
        }
        &:nth-child(2) {
            order: 2;
            @include breakpoint(large) {
                order: 1;
            }
        }
    }
}

/********* GRANDPRIX 2023 START *************/

.grandprix-wrap {
    .media-slider-thumb .slider-item {
        padding: 0 2px;
    }
}

/********* GRANDPRIX 2023 END *************/

// ============== <> =============== Add your custom styles before this line. ============== <> ===============
.ratio-container {
    position: relative;

    overflow: hidden; // added bec of zoomin
    &:after {
        content: "";
        display: block;
        height: 0;
        width: 100%;
        /* 16:9 = 56.25% = calc(9 / 16 * 100%) */
        padding-bottom: 56.25%;

        .grid-items & {
            padding-bottom: 56.25%;
        }

        .lifestyle-grid & {
            padding-bottom: 85%;
        }
    }

    &.entry-media-hero {
        &:after {
            padding-bottom: 94.25%;
        }
    }

    > a {
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 1;
        top: 0;
        border: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: center;
    }
}

.page-taxonomy-term-51,
.page-taxonomy-term-8,
.page-taxonomy-term-52,
.page-taxonomy-term-50,
.page-taxonomy-term-49,
.page-taxonomy-term-48,
.page-taxonomy-term-53,
.page-taxonomy-term-1351,
.page-taxonomy-term-27301,
.page-thesix,
.node-type-the-six {
    .theme2 .smarticker-cats ul a,
    .theme2 .smarticker-cats::before,
    .updated-ago,
    .primary-font-color,
    .breadcrumbs a,
    button.comments-holder-opened,
    button.comments-holder-opened:focus,
    a:focus,
    a:hover,
    .nested-tabs-wrapper .tabs-content ul.tabs li > a:focus,
    .nested-tabs-wrapper .tabs-content ul.tabs li > a[aria-selected="true"],
    .nested-tabs-wrapper ul.tabs li > a:hover,
    .nav-container .submenu li.active > a,
    .nav-container .submenu li a:hover,
    .searchbox-icon:focus,
    .searchbox-icon:hover,
    .searchbox-submit:focus,
    .searchbox-submit:hover,
    .nav-container > ul > li:hover > a {
        color: #63406d;
    }

    .nav-container > ul > li.active > a::after {
        border: 3px solid #63406d;
    }

    .block-title.style-v4 span,
    .page-title.style-v4 span,
    .section-title.style-v4 span {
        border-bottom: 1px solid #63406d;
    }

    .button.gray.hollow:hover,
    button.gray.hollow:hover {
        border-color: #63406d !important;
        color: #ffffff !important;
    }

    button.comments-holder-opened,
    button.comments-holder-opened:focus {
        border-color: #63406d !important;
        color: #63406d !important;

        &:hover {
            background-color: #63406d !important;
            color: $white !important;
        }
    }
}

.domain-www-arabnews-pk {
    background-color: $white;

    .banner {
        display: none;
    }

    .logo {
        margin-bottom: 1rem;
    }

    #area-to-stick,
    .main-header-before,
    .section-title.hc-sticky,
    .block-title.style-v3 span,
    .page-title.style-v3 span,
    .section-title.style-v3 span {
        background-color: $white;
    }

    .select_domain.menu-list-dropdown-wrapper {
        .dropdown-pane {
            a {
                &:hover {
                    //color: $sushi;
                }
            }

            li.active > a {
                //color: $sushi;
            }
        }
    }

    .banner {
        font-style: normal;
        font-weight: normal;
        font-size: rem-calc(12);
        font-family: $font-secondary-bold;
        //color: $white;
        margin-left: auto;
        margin-right: auto;
        padding-top: rem-calc(4);
        margin-bottom: 0;
        width: 100%;
        @include breakpoint(medium) {
            margin-bottom: 0.5rem;
        }
    }

    &.front,
    &.years-node,
    &.football-takeover {
        .logo {
            margin-bottom: 1rem;
        }

        .banner {
            display: none;
            @include breakpoint(medium) {
                width: 48%;
                border-top: 1px solid $spring-wood;
                margin-bottom: 1rem;
                font-size: rem-calc(18);
            }
        }
    }

    #sticky-header {
        background-color: $white;

        .logo {
            padding-top: 0.2rem;
        }

        &.stuck-header {
            //background-color: $sushi;
        }

        .social-btn {
            //color: $spring-wood;

            &:hover {
                //color: $maize;
            }
        }

        .banner {
            display: none;
            font-size: rem-calc(10);
            font-family: $font-secondary;
            padding-top: 2px;
            margin-bottom: 0;
            width: 100%;
            border-top: 0;
        }
    }

    .nav-container {
        //border-color: $spring-wood;
        border-bottom: 0;
        margin-bottom: 0;

        &.show-submenu {
            margin-bottom: 2.75rem;
        }

        > ul > li {
            &:hover {
                > a {
                    //color: $maize;
                }
            }
        }

        li {
            a {
                //color: $spring-wood;

                &:hover {
                    //color: $maize;
                }
            }

            &.active > a {
                //color: $spring-wood;

                &:after {
                    //border-color: $spring-wood;
                }
            }
        }

        .submenu {
            //background-color: $sushi;

            li.active > a {
                //color: $maize;
            }
        }
    }

    .main-header-before .social-btn {
        //color: $spring-wood;
    }

    .toggle-nav-panel {
        //color: $spring-wood;
    }

    .current-date {
        //color: $spring-wood;
    }

    .dropdown-wrapper {
        .button {
            //color: $spring-wood;
        }
    }

    .searchbox-icon,
    .searchbox-submit {
        //color: $spring-wood;
        background-color: transparent;

        &:hover {
            //color: $maize;
        }
    }

    #sticky-header .searchbox-icon,
    #sticky-header .searchbox-submit {
        background-color: transparent;
    }

    .menu-panel {
        ul {
            li {
                border-bottom: 0 !important;

                &.active {
                    position: relative;

                    &:before {
                        //background-color: $sushi;
                        z-index: 1;
                    }
                }
            }
        }
    }

    header {
        //background-color: $sushi;
        //color: $spring-wood;

        .main-header-before {
            //background-color: $sushi;
        }

        #area-to-stick {
            //background-color: $sushi;
        }
    }

    .backgroundColorHighlight {
        //background-color: $sushi;
    }
}

// slick carousel loading ---- START ------
.slick-loading-area {
    height: 400px;
    overflow: hidden;
    background: $gray url(../img/ajax-loader.gif) no-repeat center center;

    > div {
        opacity: 0;
    }

    &.slick-initialized {
        height: auto;
        overflow: visible;
        background: none;

        > div {
            opacity: 1;
        }
    }

    .slick-list {
        transition: height 250ms ease-in-out;
    }
}

// slick carousel loading ---- END ------

// limelight-player override styles ---- START ------

.limelight-player {
    width: 100% !important;
    padding-bottom: 56.34%;
    height: 0 !important;
    margin-bottom: 1.5rem;
    overflow: hidden;
    //padding-top: 1.5625rem;
}

.limelight-player .vjs-limelight-big-play {
    font-size: 12em !important;
}

// limelight-player override styles ---- END ------

.form-item-date-filter-value-date .description {
    display: none;
}

/* -------- Final say ---------------- Start ------------------------*/

.large-fluid-img {
    @include breakpoint(large) {
        img {
            width: 100%;
        }
    }
}

.uppercase {
    text-transform: uppercase !important;
}

.no-border {
    border: 0;
}

.padding-horizontal-for-small {
    @include breakpoint(small only) {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}

.with-padding {
    padding: 1rem;
}

.has-padding {
    padding: 2rem 1.5rem;
}

.vertical-padd {
    padding: 1rem 0;
}

.ft-sz-15 {
    font-size: rem-calc(15);
}

.ft-sz-18 {
    font-size: rem-calc(18);
}

.ft-sz-28 {
    font-size: rem-calc(28);
    @include breakpoint(small only) {
        font-size: rem-calc(20);
    }
}

@include breakpoint(large) {
    .row .large-column.row.row,
    .row .row.row.large-columns {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

@media only screen and (max-width: 30em) {
    .xsmall-full-width > li,
    .xsmall-full-width.columns {
        list-style: outside none none;
        width: 100%;
    }
}

.top-padding-1 {
    padding-top: 1rem;
}

.top-padding-2 {
    padding-top: 2rem;
}

.see-more-link {
    a {
        font-family: $font-secondary;
        font-size: 1rem;
        color: $primary-color;

        &:hover {
            color: $dark-gray;
        }
    }
}

.gray-font-color {
    color: $gray;
    a {
        color: inherit;
        &:hover {
            color: $primary-color;
        }
    }
}

.primary-font-color {
    color: $primary-color;
}

.emperor-font-color {
    color: $emperor;
    @at-root {
        h3#{&},
        h4#{&},
        h5#{&},
        h6#{&} {
            a {
                color: inherit;

                &:hover {
                    color: $primary-color;
                }
            }
        }
    }
}

.limed-spruce-font-color {
    color: $limed-spruce;
    @at-root {
        h5#{&},
        h6#{&} {
            a {
                color: inherit;

                &:hover {
                    color: $primary-color;
                }
            }
        }
    }
}

.icomoon-icon {
}

.border-gray-around {
    border: 1px solid $light-gray;
}

.thick-border-gray-around {
    border: 2px solid $light-gray;
}

.thicker-border-gray-around {
    border: 4px solid $light-gray;
}

.gray-border-bottom {
    border-bottom: 1px solid rgba(#b3b3b3, 0.3);
}

.gray-border-top {
    border-top: 1px solid rgba(#b3b3b3, 0.3);
    padding-top: 0.5rem;
}

.texture-pink-bg {
    background: transparent url("../img/texture-pink.jpg") repeat center center;

    h2,
    p,
    a,
    div {
        color: $white;
    }
}

.black-bg {
    background-color: $black;
}

.white-bg {
    background-color: $white;
}

.white-smoke-bg {
    background-color: $white-smoke;
    color: $black;
}

.white-smoke-opacity-bg {
    background-color: rgba($white-smoke, 0.6);
    color: $black;
}

.block-custom-bg {
    background-color: $spring-wood;
    color: $black;
}

.highlight-textbox {
    font-size: rem-calc(18);
    font-style: italic;
    color: $emperor;
    background: $spring-wood;
    margin-bottom: 2rem;
    padding: 2.25rem 3.5rem;
    position: relative;
}

.gradient-bg {
    position: relative;
    background: linear-gradient(90deg, rgba(247, 247, 247, 0.1) 33%, rgba(255, 255, 255, 0.6) 0);

    &:after {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 783px;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -1;
        background: transparent url("../img/texture2.png") no-repeat bottom center;
    }
}

/*.texture-bg {
  background-image: url("../img/texture2.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
}*/

.gray-bg {
    background-color: $white-smoke;
    color: $secondary-color;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: inherit;

        a {
            color: inherit;

            &:hover {
                color: $primary-color;
            }
        }
    }
}

.dark-gray-bg {
    background-color: #252525;
    color: $white;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: inherit;

        a {
            color: inherit;

            &:hover {
                color: $primary-color;
            }
        }
    }
}
.pager--infinite-scroll li a {
    width: auto;
}
.button.hollow,
.pager--infinite-scroll li a {
    border-width: 1px;
}

label {
    //font-family: $font-secondary;
    span.required {
        color: red;
        font-weight: bold;
    }
}

.last-no-margin {
    @include breakpoint(medium) {
        article:last-child {
            margin-bottom: 0 !important;

            h4,
            h5,
            h6 {
                margin-bottom: 0;
            }
        }
    }
}

.maxWidth-medium,
.maxWidth-small,
.maxWidth-xsmall {
    float: none;
    margin-left: auto;
    margin-right: auto;
}

.maxWidth-medium {
    max-width: 800px;
}

.maxWidth-small {
    max-width: 670px;
}

.maxWidth-xsmall {
    max-width: 360px;
}

.grid-collapse {
    @include breakpoint(large) {
        @include grid-column-collapse;
    }
}

.small-grid-collapse {
    @include breakpoint(small only) {
        @include grid-column-collapse;
        .article-item-info,
        .article-item-overbox-inner {
            padding: 0 1.25rem;
        }
    }
    @media screen and #{breakpoint(390)} and #{breakpoint(medium down)} {
        .grid-items {
            .article-item-info,
            .article-item-overbox-inner {
                padding: 0;
            }
        }
    }

    .padding-horizontal-for-small {
        .block-title.style-v3 span,
        .section-title.style-v3 span,
        .page-title.style-v3 span {
            padding: 0;
        }

        .article-item-overbox-inner {
            padding: 0;
        }
    }
}

.centered-column {
    @include grid-column-position(center);
}

.newsletter-area {
    background-color: $spring-wood;
    padding: 2rem;
    margin-bottom: 1.75rem;
    @include breakpoint(large) {
        margin-bottom: 0;
    }

    footer & {
        .input-field {
            box-shadow: inset 1px 1px 8px -2px rgba($black, 1);
            font-family: $font-primary;
            font-size: rem-calc(14);
            color: #757575;
            border-width: 0;
            border-radius: 5px;
            margin-bottom: 0.5rem;
            background-color: $white;
            height: 40px;
        }

        input[type="submit"] {
            border: 0;
            font-family: $font-secondary-bold;
            font-size: rem-calc(14);
            color: $white;
            padding: 0.85rem 1rem;
            background-color: $limed-spruce;
            border-radius: 5px;
            text-align: center;
            text-transform: uppercase;

            &:hover {
                background-color: $primary-color;
            }
        }
    }
}

.newsletter-intro {
    text-align: center;
    margin-bottom: 1rem;

    h5 {
        color: $secondary-color;
        font-family: $font-secondary-bold;
        font-size: 1rem;
        text-transform: uppercase;
    }

    h6 {
        color: $black;
        font-family: $font-secondary;
        font-size: rem-calc(14);
    }
}

.mc_embed_signup div#mce-responses {
    float: left;
    top: -1.4em;
    padding: 0em 0.5em 0em 0.5em;
    overflow: hidden;
    width: 90%;
    margin: 0 5%;
    clear: both;
}

.mc_embed_signup div.response {
    margin: 1em 0;
    padding: 1em 0.5em 0.5em 0;
    font-weight: bold;
    float: left;
    top: -1.5em;
    z-index: 1;
    width: 80%;
}

.mc_embed_signup #mce-error-response {
    display: none;
}

.mc_embed_signup #mce-success-response {
    color: #529214;
    display: none;
}

.mc_embed_signup label.error {
    display: block;
    float: none;
    width: auto;
    margin-left: 1.05em;
    text-align: left;
    padding: 0.5em 0;
}

.haj2019-img img {
    @include breakpoint(medium only) {
        width: 48px;
    }
    @include breakpoint(small only) {
        width: 48px;
    }
}

.haj2019-img-slogan img {
    @include breakpoint(medium only) {
        width: 167px;
    }
    @include breakpoint(small only) {
        width: 167px;
    }
}

/* Drupal admin styles ----- START -----*/

.admin-menu {
    ul.tabs.primary {
        list-style: none;

        li {
            display: inline-block;
            margin: 0 5px;
            padding: 5px 15px;

            &:hover,
            &.active {
                background: $primary-color;

                a {
                    color: white;
                }
            }
        }
    }
}

.element-invisible {
    display: none;
}

#user-image {
    width: 103px;
    height: 103px;
}

.first-letter-style-v2 h1 {
    font-size: 4rem;
    letter-spacing: -0.04em;
}
.first-letter-style-v2 h1::first-letter {
    color: #1c8487;
}

/* Drupal admin styles ----- END -----*/

/* ------- Socials Btns Styles ------- START -------- */

.socials-area {
    header &,
    #sticky-header & {
        display: inline-block;
        z-index: 1;
    }

    .canvas-panel & {
        padding: 1.25rem 0;
    }
}

.social-btn {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: #898f93;
    font-size: 1rem;
    margin: 0 0 0 0.75rem;
    line-height: 1;

    i {
        display: inline-block;
        vertical-align: top;
    }

    &:hover {
        color: $black;
        transition: color 0.25s linear;
    }

    footer & {
        margin: 0 0.85rem 0 0;
        font-size: 1.5rem;
    }

    .canvas-panel & {
        margin: 0 2rem 0 0;
        font-size: 1.25rem;
        &:last-child {
            margin: 0;
        }
    }

    .block-wrapper & {
        margin: 0 0.85rem;
        font-size: 2rem;
    }

    .events-list & {
        margin: 0 0.85rem 0 0;
        font-size: 1.125rem;
    }
}

.socials-block {
    padding: 2rem 0;
    text-align: center;

    a {
        margin: 0 0.25rem;
        font-size: 1.25rem;
        padding: 0.75rem;
        //width: 35px !important;
        //height: 35px;
        background: $white;
        //line-height: 39px !important;
        &:hover,
        &:active,
        &:focus {
            background-color: $nevada !important;
            color: $white;
        }

        i {
            vertical-align: middle;
        }
    }
}

.envelope-btn {
    color: $envelope;
}

.rss-btn {
    color: $rss;
}

.youtube-btn {
    color: $youtube;
}

.gplus-btn {
    color: $gplus;
}

.twitter-btn {
    color: $twitter;
}

.twitter-btn-hover {
    &:hover {
        color: $twitter !important;
    }
}
.linkedin-btn-hover {
    &:hover {
        color: $twitter !important;
    }
}

.facebook-btn {
    color: $facebook;
}

.facebook-btn-hover {
    &:hover {
        color: $facebook !important;
    }
}

.youtube-btn-hover {
    &:hover {
        color: $youtube !important;
    }
}

.whatsapp-btn {
    color: $whatsapp;
}

.whatsapp-btn-hover {
    &:hover {
        color: $whatsapp !important;
    }
}

.instagram-btn {
    color: $instagram;
}

.instagram-btn-hover {
    &:hover {
        color: $instagram !important;
    }
}

.snapchat-btn {
    color: $snapchat;
    text-shadow: 0 0 2px $medium-gray;
}

.telegram-btn {
    color: $telegram;
}

.linkedin-btn {
    color: $linkedin;
}

.pinterest-btn {
    color: $pinterest;
}

.socials-btns-big {
    margin-bottom: 1.5rem;

    .social-btn {
        color: $white;
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 1.75rem;
        margin: 0 10px;
        transition: all 0.25s ease 0s;

        &:hover {
            background-color: $primary-color;
        }
    }
}

.envelope-btn-bg {
    background-color: $envelope !important;
    color: $white;
}

.rss-btn-bg {
    background-color: $rss !important;
    color: $white;
}

.youtube-btn-bg {
    background-color: $youtube !important;
    color: $white;
}

.gplus-btn-bg {
    background-color: $gplus !important;
    color: $white;
}

.twitter-btn-bg {
    background-color: $twitter !important;
    color: $white;
}

.facebook-btn-bg {
    background-color: $facebook !important;
    color: $white;
}

.instagram-btn-bg {
    background-color: $instagram !important;
    color: $white;
}

.snapchat-btn-bg {
    background-color: $snapchat !important;
    color: $white;
}

.telegram-btn-bg {
    background-color: $telegram;
    color: $white;
}

.linkedin-btn-bg {
    background-color: $linkedin;
    color: $white;
}

.pinterest-btn-bg {
    background-color: $pinterest;
    color: $white;
}

.whatsapp-btn-bg {
    background-color: $whatsapp;
    color: $white;
}

.button-width-187 a {
    width: 187px;
}

/* ------- Socials Btns Styles ------- END -------- */

/* -------- Final say ---------------- End ------------------------*/
