/*
html {
  scroll-behavior: smooth;
}
*/
.invisible { visibility:hidden;}

.clearFloat { display:block; clear:both; height:1px; width:1px; overflow:hidden;}
.mb-30 { margin-bottom: 30px }

/*
div.logo div.fourtyFive { float:left; width:150px; }
div.logo div.fourtyFive img {width:100%; height:auto;}
*/

div.logoFourtyFive { width:100%; padding:0 0 10px 0;}
div.fourtyFive img {width:100%; height:auto; }

/* BEGIN 2 column LAYOUT */

.row .col {
  float: left;
  width: 50%;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.row .col.col1 { width:60%;}
.row .col.col2 {  width:40%; }


.row .col .sidebar-wrap { float:right;}
/* END 2 COLUMN LAYOUT */



div.mainContainer45 { width:100%; }
div.mainContainer45 div.headline1 { text-align: center;}
div.mainContainer45 div.headline1 img { margin: 0 auto; width:100%; }
div.mainContainer45 div.headline2 { width:100%; padding:16px 0; border-top:1px solid #1d1d1c; border-bottom:1px solid #1d1d1c; }
div.mainContainer45 div.headline2 h2 {padding:0; margin:0 100px; font-size:38px; text-align:center; font-weight: bolder;}
div.mainContainer45 div.interActiveContainer { padding:65px 0 32px 0;}

div.mainContainer45 p.highlight { margin:35px 0 70px 0;}


div.mainContainer45 div.headlineArticle { width:100%; padding:15px 0; /*border-top:1px solid #1d1d1c; border-bottom:1px solid #1d1d1c;*/ }
div.mainContainer45 div.headlineArticle img {  /*display:inline-block; width:100px; height:auto;*/ width:100%; }
div.mainContainer45 div.headlineArticle h2 {  display:inline-block; padding:0; margin:0; font-size:38px; text-align:center; font-weight: bolder; vertical-align: middle;}
div.mainContainer45 div.headlineArticle:after {
  content: "";
  display: table;
  clear: both;
}


div.mainContainer45 div.btns-area { position:relative; padding:50px 0 25px 0;}

div.mainContainer45 div.btns-area .button { width:30%; font-weight:bolder; }
div.mainContainer45 div.btns-area .btn1  { float:left }
div.mainContainer45 div.btns-area .btn2 { float:left; margin-left:5%; }
div.mainContainer45 div.btns-area .btn3  { float:right }

div.mainContainer45 div.articleItems {}
div.mainContainer45 div.articleItems div div span.numero { float:left; font-family:"playfair display"; font-weight:bolder;  font-size:42px; color:#6e4270; padding:0; margin:0; line-height:14px; margin:4px 4px 4px 0; letter-spacing: -2px; }
div.mainContainer45 div.articleItems div div p.titleinfo {font-family:"playfair display"; line-height: 17px; font-size: 16px;}

div.mainContainer45 div.articleItems div div div.article-item-title p  { margin-bottom: 0; margin-left: 4px; }

div.mainContainer45 div.articleItems .cell.selected .article-item {   -webkit-animation-name: bounceIn; animation-name: bounceIn; animation-duration: 3s; animation-delay: 1s; border:1px solid #ccc; border-radius: 5px; }

div.mainContainer45 div.articleItems div div div.article-item-meta {  }
div.mainContainer45 div.articleItems div div div.article-item-meta span { margin-left: 4px; }

/* BEGIN CIRCULAR MARKER */
div.mainContainer45 div.interActiveContainer { position:relative; padding:0; margin:0;}
div.mainContainer45 div.interActiveContainer a.circleMarker {
    position: absolute;
    height: 50px;
    width: 50px;
    /*background-color: #fff;
    border-radius: 50%;*/
    text-align:center;
    vertical-align: middle;
    font-family: "Isento-Bold";
    font-size: 30px;
    font-weight:bolder;
    padding:0;
    margin:0;
    scroll-behavior: smooth;
    /*animation: scaleIn 2s infinite cubic-bezier(.36, .11, .89, .32);*/
  }






.circleMarker .anim { position:absolute; display:block; top:0; left:0; width:50px; height:50px; border-radius: 50%; background-color: #fff; animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);}
.circleMarker .animbase { position:absolute; display:block; top:0; left:0; width:50px; height:50px; border-radius: 50%; background-color: #fff; }
.circleMarker .lbl { position:relative; display:block; width:100%; margin:auto; top:50%;  -ms-transform: translateY(-50%); transform: translateY(-50%); letter-spacing: -0.09em; padding:0; text-align:center; z-index: 9;  }

.circleMarker:nth-child(odd) .anim { animation-delay: 1s; }
.circleMarker:nth-child(odd) .anim { animation-delay: 2s; }

.circleMarker:hover { color:#000; }
.circleMarker:hover .animbase { background-color:#dcebce; }
.circleMarker:hover anim { background-color:#dcebce;}
/*.circleMarker:hover .lbl {  -webkit-animation-name: tada; animation-name: tada; animation-duration: 3s; }*/


/*
.circleMarker.item1 .anim { animation-delay: 1s; }
.circleMarker.item2 .anim { animation-delay: 2s; }
.circleMarker.item3 .anim { animation-delay: 3s; }
.circleMarker.item4 .anim { animation-delay: 4s; }
.circleMarker.item5 .anim { animation-delay: 5s; }
.circleMarker.item6 .anim { animation-delay: 6s; }
.circleMarker.item7 .anim { animation-delay: 7s; }
.circleMarker.item8 .anim { animation-delay: 8s; }
.circleMarker.item9 .anim { animation-delay: 9s; }
.circleMarker.item10 .anim { animation-delay: 10s; }
.circleMarker.item11 .anim { animation-delay: 11s; }
.circleMarker.item12 .anim { animation-delay: 12s; }
.circleMarker.item13 .anim { animation-delay: 13s; }
.circleMarker.item14 .anim { animation-delay: 14s; }
.circleMarker.item15 .anim { animation-delay: 15s; }
.circleMarker.item16 .anim { animation-delay: 16s; }
.circleMarker.item17 .anim { animation-delay: 17s; }
.circleMarker.item18 .anim { animation-delay: 18s; }
.circleMarker.item19 .anim { animation-delay: 19s; }
.circleMarker.item20 .anim { animation-delay: 20s; }
.circleMarker.item21 .anim { animation-delay: 21s; }
.circleMarker.item22 .anim { animation-delay: 22s; }
*/

.circleMarker.item0 { display:none;}
/*.circleMarker.item1 { left:6.48%; top:2.95%; }
.circleMarker.item2 { left:7.31%; top:16.98%;}
.circleMarker.item3 { left:20.46%; top:16.86%; }
.circleMarker.item4 { left:29.35%; top:16.98%; }
.circleMarker.item5 { left:34.17%; top:1.77%; }
.circleMarker.item6 { left:39.26%; top:12.74%; }
.circleMarker.item7 { left:42.96%; top:22.41%; }
.circleMarker.item8 { left:49.63%; top:10.02%; }
.circleMarker.item9 { left:56.52%; top:10.99%; }
.circleMarker.item10 { left:61.57%; top:23.35%; }
.circleMarker.item11 { left:78.24%; top:22.29%; }
.circleMarker.item12 { left:83.52%; top:30.31%; }
.circleMarker.item13 { left:86.76%; top:11.44%; }
.circleMarker.item14 { left:91.11%; top:33.19%; }
.circleMarker.item15 { left:9.54%; top:30.31%; }
.circleMarker.item16 { left:27%; top:41.44%; }
.circleMarker.item17 { left:20.37%; top:30.66%; }
.circleMarker.item18 { left:32.11%; top:24.41%; }
.circleMarker.item19 { left:38.46%; top:33.2%; }
.circleMarker.item20 { left:48.61%; top:40.57%; }
.circleMarker.item21 { left:51.3%; top:49.17%; }
.circleMarker.item22 { left:62.22%; top:44.93%; }
.circleMarker.item23 { left:93.98%; top:50%; }
.circleMarker.item24 { left:0.56%; top:41.98%; }
.circleMarker.item25 { left:10.28%; top:54.48%; }
.circleMarker.item26 { left:17.31%; top:51.3%; }
.circleMarker.item27 { left:36.96%; top:42.17%; }
.circleMarker.item28 { left:41.94%; top:48.70%; }
.circleMarker.item29 { left:51.57%; top:57.78%; }
.circleMarker.item30 { left:57.78%; top:54.13%; }
.circleMarker.item31 { left:66.94%; top:64.62%; }
.circleMarker.item32 { left:76.67%; top:69.22%; }
.circleMarker.item33 { left:89.16%; top:65.98%; }
.circleMarker.item34 { left:4.91%; top:60%; }
.circleMarker.item35 { left:18.70%; top:60.26%; }
.circleMarker.item36 { left:31.29%; top:60.85%; }
.circleMarker.item37 { left:43.06%; top:63.2%; }
.circleMarker.item38 { left:4.54%; top:71.81%; }
.circleMarker.item39 { left:21.3%; top:73.58%; }
.circleMarker.item40 { left:29.07%; top:87.73%; }
.circleMarker.item41 { left:32.68%; top:78.12%; }
.circleMarker.item42 { left:49.9%; top:74.17%; }
.circleMarker.item43 { left:52.59%; top:81.49%; }
.circleMarker.item44 { left:63.33%; top:89.15%; }
.circleMarker.item45 { left:67.13%; top:74.17%; }*/
.circleMarker.item1 { left:48.61%; top:40.57%; }
.circleMarker.item2 { left:32.68%; top:78.12%; }
.circleMarker.item3 { left:36.96%; top:42.17%; }
.circleMarker.item4 { left:17.31%; top:51.3%; }
.circleMarker.item5 { left:10.28%; top:54.48%; }
.circleMarker.item6 { left:76.67%; top:69.22%; }
.circleMarker.item7 { left:21.3%; top:73.58%; }
.circleMarker.item8 { left:49.63%; top:10.02%; }
.circleMarker.item9 { left:18.70%; top:60.26%; }
.circleMarker.item10 { left:27%; top:41.44%; }
.circleMarker.item11 { left:78.24%; top:22.29%; }
.circleMarker.item12 { left:34.17%; top:1.77%; }
.circleMarker.item13 { left:91.11%; top:33.19%; }
.circleMarker.item14 { left:0.56%; top:41.98%; }
.circleMarker.item15 { left:67.13%; top:74.17%; }
.circleMarker.item16 { left:52.59%; top:81.49%; }
.circleMarker.item17 {left:89.16%; top:65.98%;  }
.circleMarker.item18 { left:42.96%; top:22.41%; }
.circleMarker.item19 { left:20.46%; top:16.86%; }
.circleMarker.item20 { left:57.78%; top:54.13%; }
.circleMarker.item21 { left:41.94%; top:48.70%; }
.circleMarker.item22 { left:7.31%; top:16.98%;}
.circleMarker.item23 { left:6.48%; top:2.95%; }
.circleMarker.item24 { left:29.35%; top:16.98%; }
.circleMarker.item25 { left:4.91%; top:60%; }
.circleMarker.item26 { left:31.29%; top:60.85%; }
.circleMarker.item27 { left:29.07%; top:87.73%; }
.circleMarker.item28 { left:51.3%; top:49.17%; }
.circleMarker.item29 { left:65.27%; top:10.2%; }
.circleMarker.item30 { left:62.22%; top:44.93%; }
.circleMarker.item31 { left:66.94%; top:64.62%; }
.circleMarker.item32 { left:86.76%; top:11.44%; }
.circleMarker.item33 { left:49.9%; top:74.17%; }
.circleMarker.item34 { left:43.06%; top:63.2%; }
.circleMarker.item35 { left:56.52%; top:10.99%; }
.circleMarker.item36 { left:9.54%; top:30.31%; }
.circleMarker.item37 { left:38.46%; top:33.2%; }
.circleMarker.item38 { left:93.98%; top:50%; }
.circleMarker.item39 { left:63.33%; top:89.15%; }
.circleMarker.item40 { left:39.26%; top:12.74%; }
.circleMarker.item41 { left:4.54%; top:71.81%; }
.circleMarker.item42 { left:61.57%; top:23.35%; }
.circleMarker.item43 { left:51.57%; top:57.78%; }
.circleMarker.item44 { left:20.37%; top:30.66%; }
.circleMarker.item45 { left:32.11%; top:24.41%; }


div.mainContainer45 div.interActiveContainer div.info {
    position:absolute;
    top:0;
    left:0;
    z-index:10;
    min-width:100px;
    /*max-width:600px;*/
    /*transform:translate(-50%, -100%);*/
    padding:2px 4px;
    color:#000;
    background-color:#dcebce;;
    font-weight:normal;
    font-size:14px;
    border-radius:8px;
    box-sizing:border-box;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden;
}

div.mainContainer45 div.interActiveContainer div.info.showme { visibility:visible; -webkit-animation-name: zoomIn; animation-name: zoomIn; animation-duration: 2s;  }

div.mainContainer45 div.interActiveContainer div.info p { padding:0; margin:0; text-align:center; color:#827F7F; font-size:16px;  }
div.mainContainer45 div.interActiveContainer div.info h4 { padding:0; margin:0; text-align:center; font-size:20px; }


/* BEGIN MODAL */
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100vh; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 650px;
  max-height:90vh;

}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
/* END MODAL */


/* BEGIN ANIMATIONS */
@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .7;
  }
  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

@-webkit-keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}
@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/* END ANIMATIONS */





@media only screen and (max-width: 1000px) {
    div.mainContainer45 div.interActiveContainer a.circleMarker, .circleMarker .anim, .circleMarker .animbase {
       height: 32px;
       width: 32px;
       font-size: 22px;
    }


}

@media only screen and (max-width: 700px) {
    div.mainContainer45 div.interActiveContainer a.circleMarker, .circleMarker .anim, .circleMarker .animbase {
       height: 30px;
       width: 30px;
       font-size: 18px;
    }

    div.mainContainer45 div.articleItems { padding-top: 10px;}

    div.mainContainer45 div.headline2 { margin: 10px 0;}
    div.mainContainer45 div.headline2 h2 { margin:0; font-size:18px;  }
    div.mainContainer45 p.highlight { margin: 10px 4px; }

    div.mainContainer45 div.interActiveContainer div.info { /*max-width:200px;*/}
    div.mainContainer45 div.interActiveContainer div.info h4 { font-size:14px;}
    div.mainContainer45 div.interActiveContainer div.info p {font-size:12px; }

    div.mainContainer45 div.headlineArticle h2 { font-size:30px; }
    div.mainContainer45 div.headlineArticle img { /*width:80px; height:auto;*/}

     .row .col.col1, .row .col.col2 { clear:both; width:100%; }

}

@media only screen and (max-width: 600px) {

    div.mainContainer45 div.interActiveContainer a.circleMarker, .circleMarker .anim, .circleMarker .animbase {
       height: 25px;
       width: 25px;
       font-size: 14px;

    }


    div.mainContainer45 div.articleItems { padding-top: 10px;}

    div.mainContainer45 div.headline2 { margin: 10px 0; padding:10px 0;}
    div.mainContainer45 div.headline2 h2 { margin:0; font-size:16px;  }

    div.mainContainer45 p.highlight { margin: 10px 4px; }

    div.mainContainer45 div.btns-area { padding:0 0 10px 0;}
    div.mainContainer45 div.btns-area .button { clear:both; width:100%; margin:4px 0 0 0; }

    div.mainContainer45 div.interActiveContainer div.info { /*max-width:200px;*/}
    div.mainContainer45 div.interActiveContainer div.info h4 { font-size:12px;}
    div.mainContainer45 div.interActiveContainer div.info p {font-size:10px; }

    div.mainContainer45 div.headlineArticle h2 { font-size:28px; }
    div.mainContainer45 div.headlineArticle img { /*width:80px; height:auto;*/}

    .row .col.col1, .row .col.col2 { clear:both; width:100%; }
}


/* END CIRCULAR MARKER */