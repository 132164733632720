.jon-pike { margin-bottom:20px;}
.jon-pike .jon-pike-details { margin-top:30px;  }
.jon-pike .jon-pike-details .photo-detail { background-color:#ced4c6; }
.jon-pike .jon-pike-details .photo-detail img {width:24%; float:left; margin-top: -10px;}
.jon-pike .jon-pike-details .photo-detail p { font-size:18px;  padding: 10px 10px; }
.jon-pike .jon-pike-details .photo-detail p span { font-weight:bold; width:100%; font-size: 22px; display: block; margin-bottom: 5px; }
.jon-pike .jon-pike-details .photo-detail::after { content:''; clear:both; display:block; height:1px; }


.jon-pike .jon-pike-details:nth-of-type(2n+1) img { float:right;}

@media only screen and (max-width: 900px) {
     .jon-pike .jon-pike-details .photo-detail img { width:40%; }

}

@media only screen and (max-width: 600px) {
    .jon-pike .jon-pike-details .photo-detail img { width:60%;}
    .jon-pike .jon-pike-details .photo-detail p {  font-size:16px; padding: 5px 5px; }
}