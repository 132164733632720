$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;

$icon-whatsapp-square: "\e92c";
$icon-whatsapp1: "\e92a";
$icon-youtube: "\e922";
$icon-calendar-minus: "\e91e";
$icon-calendar-plus: "\e91f";
$icon-calendar: "\e920";
$icon-whatsapp2: "\e92b";
$icon-mail: "\e928";
$icon-facebook: "\e929";
$icon-linkedin: "\e926";
$icon-quotes-right: "\e924";
$icon-quotes-left: "\e925";
$icon-youtube2: "\e923";
$icon-copy: "\e921";
$icon-audio: "\e91c";
$icon-play-arrow: "\e91a";
$icon-soundwave: "\e91d";
$icon-pause: "\e919";
$icon-facebook2: "\e901";
$icon-twitter2: "\e902";
$icon-facebook1: "\e900";
$icon-twitter: "\e92d";
$icon-whatsapp: "\e904";
$icon-rss: "\e905";
$icon-instagram: "\e906";
$icon-envelope: "\e907";
$icon-printer: "\e908";
$icon-user: "\e909";
$icon-user-small: "\e90a";
$icon-graphic: "\e90b";
$icon-views: "\e90c";
$icon-comment: "\e90d";
$icon-bookmark: "\e90e";
$icon-camera: "\e90f";
$icon-photos: "\e90f";
$icon-play: "\e910";
$icon-video: "\e910";
$icon-plus: "\e911";
$icon-close: "\e912";
$icon-hamburger: "\e913";
$icon-search2: "\e914";
$icon-search: "\e915";
$icon-arrow-right: "\e916";
$icon-arrow-left: "\e917";
$icon-arrow-down: "\e918";
$icon-arrow-up: "\e91b";
$icon-arrow-down1: "\e927";